import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import { format } from 'date-fns';
import { minBy } from 'lodash';
import { Link } from 'react-router-dom';
import ProductCardBlock from '../shared/ProductCard';

export default function NextShipmentCardBlock() {
  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const nextShipment = minBy(subscriptions, (sub) => new Date(sub.nextRecurrenceOn));

  const multipleSubscriptions = subscriptions.length > 1;

  if (!nextShipment) {
    return <></>;
  }

  return (
    <ProductCardBlock
      hugText='upcoming'
      title='Next Shipment'
      subtitle={`Processes ${format(new Date(nextShipment.nextRecurrenceOn), 'MMM d')}`}
      products={nextShipment.products}
    >
      <Link
        to={
          multipleSubscriptions
            ? '/subscriptions'
            : '/subscriptions/' + nextShipment.stripeSubscriptionId
        }
        className='secondary-border-button mt-3'
      >
        Manage your subscription{multipleSubscriptions && 's'}
      </Link>
    </ProductCardBlock>
  );
}
