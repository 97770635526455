import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'reducers/alloy_reducer';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Form } from 'rsuite';

import { register } from 'actions/auth/register_actions';

import arrowRight from 'assets/svg/core/arrows/arrow-right-white.svg';
import checkedBox from 'assets/svg/core/icons/checked-box-icon.svg';
import uncheckedBox from 'assets/svg/core/icons/unchecked-box-icon.svg';

import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import Loader from 'components/core/Loader';
import { EmailField } from 'components/core/fields/EmailField';
import { PasswordField } from 'components/core/fields/PasswordField';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';
import { showErrorNotification } from 'components/core/Notification';
import { buildCheckoutExperienceLoginURL } from 'lib/core/url';

import { ExperienceComponentModel } from 'models/alloy/experience';

type RegisterForm = {
  email: string;
  password: string;
  marketingEmailConsent: boolean;
  checksSucceeded: boolean;
};

export default function Register({ onNext, onBack }: ExperienceComponentModel) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);

  const INIT_REGISTER_FORM: RegisterForm = {
    email: '',
    password: '',
    marketingEmailConsent: false,
    checksSucceeded: false,
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [registerForm, setRegisterForm] = useState<RegisterForm>(INIT_REGISTER_FORM);

  const dispatchRegister = bindActionCreators(register, dispatch);

  useEffect(() => {
    if (isAuthenticated && onBack) {
      onBack();
    }
  }, []);

  // Submit the login and if needed to change password, submit the new password changed
  const onSubmit = async () => {
    window.scrollTo(0, 0);

    try {
      const { email, password } = registerForm;

      setIsLoading(true);

      // TODO - AWS call, diff. error handling needed
      await dispatchRegister(email, password);

      onNext();
    } catch (error) {
      const errorMessage = (error as Error).message;

      if (
        errorMessage &&
        (errorMessage.includes('An account with the given email already exists.') ||
          errorMessage.includes('Customer already exists with email'))
      ) {
        navigate(buildCheckoutExperienceLoginURL(location));
        showErrorNotification('An account with the given email already exists, please login.');
      } else {
        setIsLoading(false);
        showErrorNotification(errorMessage);
      }
    }
  };

  return (
    <Layout title='Register - Alloy' desc='' noBars>
      <TopBannerWithProgress
        onBack={isLoading ? undefined : onBack}
        percentComplete={getPercentComplete(location)}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Form>
          <CheckoutExperienceSection>
            <h1 className='content-title'>Create a secure account</h1>
            <p className='content-text'>
              Create an account to keep your information secure and message with your doctor. With
              your account, you can message your doctor directly.
            </p>

            <p className='content-text'>
              Already a member?{' '}
              <Link to={buildCheckoutExperienceLoginURL(location)} className='content-link'>
                Log in to continue
              </Link>
            </p>

            <EmailField
              label='Email'
              name='email'
              value={registerForm.email}
              placeholder='Enter your email address'
              onChange={(value) => setRegisterForm({ ...registerForm, email: value })}
            />

            <PasswordField
              label='Password'
              name='password'
              value={registerForm.password}
              placeholder='Set password'
              onChange={(checksSucceeded, password) =>
                setRegisterForm({ ...registerForm, checksSucceeded, password })
              }
            />

            <div className='content-consent-wrapper'>
              <div>
                <button
                  className='consent-checkbox-btn'
                  type='button'
                  onClick={() =>
                    setRegisterForm({
                      ...registerForm,
                      marketingEmailConsent: !registerForm.marketingEmailConsent,
                    })
                  }
                >
                  <img
                    src={registerForm.marketingEmailConsent ? checkedBox : uncheckedBox}
                    alt='check box'
                    className='checkbox-icon'
                  />
                </button>
              </div>

              <p className='consent-text'>
                By signing up for Alloy emails, I agree to the{' '}
                <a
                  href={process.env.REACT_APP_MARKETING_URL + '/terms-of-use'}
                  target='_blank'
                  className='consent-link'
                >
                  Terms of Use
                </a>{' '}
                and{' '}
                <a
                  href={process.env.REACT_APP_MARKETING_URL + '/privacy-policy'}
                  target='_blank'
                  className='consent-link'
                >
                  Privacy Policy
                </a>
                . I consent to Alloy sending me Email and Text (SMS) messages that contain my
                protected health information (PHI) to keep me informed about my health consultation,
                prescriptions, & medications I receive through Alloy.
              </p>
            </div>

            <button
              className='primary-button full-width-button'
              type='submit'
              disabled={
                registerForm.email === '' ||
                registerForm.password === '' ||
                !registerForm.checksSucceeded ||
                !registerForm.marketingEmailConsent
              }
              onClick={onSubmit}
            >
              Send verification code
              <img src={arrowRight} alt='arrow right' className='btn-arrow' />
            </button>
          </CheckoutExperienceSection>
        </Form>
      )}
    </Layout>
  );
}
