import { Input, InputGroup } from 'rsuite';

interface Props {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  classes?: string;
  rows?: number;
  isFollowUp?: boolean;
}

export const TextAreaField = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  classes = 'field-input',
  rows = 4,
  isFollowUp = false,
}: Props) => (
  <div className='input-field-wrapper'>
    {isFollowUp ? (
      <InputGroup inside>
        <InputGroup.Addon>{label}</InputGroup.Addon>
        <Input
          name={name}
          as='textarea'
          rows={rows}
          placeholder={placeholder}
          className={classes}
          defaultValue={value}
          onChange={onChange}
          data-private='lipsum'
          data-sentry-mask
        />
      </InputGroup>
    ) : (
      <>
        {label && <p className='field-label'>{label}</p>}

        <Input
          name={name}
          as='textarea'
          rows={rows}
          placeholder={placeholder}
          className={classes}
          defaultValue={value}
          onChange={onChange}
          data-private='lipsum'
          data-sentry-mask
        />
      </>
    )}
  </div>
);
