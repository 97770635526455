import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Form } from 'rsuite';

import arrowRight from 'assets/svg/core/arrows/arrow-right-white.svg';

import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import Loader from 'components/core/Loader';
import { showErrorNotification, showSuccessNotification } from 'components/core/Notification';
import { CodeField } from 'components/core/fields/CodeField';
import { EmailField } from 'components/core/fields/EmailField';
import PasswordFieldsGroup from 'components/core/fields/PasswordFieldsGroup';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import cleanNonNumberValues from 'lib/checkout-experience/authentication/cleanNonNumberValues';
import { requestForgotPasswordCode, updatePasswordWithCode } from 'lib/core/awsAuth';

type ResetForm = {
  email: string;
  code: string;
  password: string;
  confirmedChecksSucceeded: boolean;
};

type LocationState = {
  email?: string;
};

export default function ResetRequired() {
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as LocationState | null;

  const INIT_FORGOT_FORM: ResetForm = {
    email: state && state.email ? state.email : '',
    code: '',
    password: '',
    confirmedChecksSucceeded: false,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [resetForm, setResetForm] = useState<ResetForm>(INIT_FORGOT_FORM);
  const [sentCode, setSentCode] = useState(false);

  useEffect(() => {
    onSendVerificationCode();
  }, []);

  const onSendVerificationCode = async () => {
    try {
      setIsLoading(true);

      if (resetForm.email !== '') {
        await requestForgotPasswordCode(resetForm.email);

        showSuccessNotification(
          "Verification code sent, check your email (don't forget your spam folders)"
        );

        setSentCode(true);
      }

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      showErrorNotification(error.message);
    }
  };

  const onChangePassword = async () => {
    const { password: newPassword, code } = resetForm;

    try {
      setIsLoading(true);

      await updatePasswordWithCode(resetForm.email, code, newPassword);

      showSuccessNotification('Successfully changed password, please login!');

      navigate({ pathname: '/login', search: location.search });

      setSentCode(false);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showErrorNotification(error.message);
    }
  };

  return (
    <Layout title='Reset Password - Alloy' desc='Reset your password for your Alloy account' noBars>
      <TopBannerWithProgress />

      {isLoading ? (
        <Loader />
      ) : (
        <Form>
          <CheckoutExperienceSection>
            <h1 className='content-title'>Reset Password</h1>
            {sentCode && (
              <p className='content-text'>
                We recently updated our account authentication.
                <br />
                It makes things more secure on our end, but it also means you&apos;ll need to update
                your password.
                <br />
                We&apos;ve sent you a verification code by email to <b>{resetForm.email}</b>
                <br />
                Enter it below and then create your new password.
              </p>
            )}

            <p className='content-text'>
              Back to{' '}
              <Link to={{ pathname: '/login', search: location.search }} className='content-link'>
                Get started
              </Link>
            </p>

            {(resetForm.email === '' || !sentCode) && (
              <EmailField
                label='Email'
                name='email'
                value={resetForm.email}
                placeholder='Enter your email address'
                onChange={(value) => setResetForm({ ...resetForm, email: value })}
              />
            )}

            <CodeField
              name='code'
              label='Code'
              placeholder='Enter code here'
              value={resetForm.code}
              onChange={(value) => {
                setResetForm({
                  ...resetForm,
                  code: cleanNonNumberValues(value, 6),
                });
              }}
            />

            <PasswordFieldsGroup
              useConfirm
              onChange={(confirmedChecksSucceeded, password) =>
                setResetForm({ ...resetForm, confirmedChecksSucceeded, password })
              }
              passwordReset
            />

            {(sentCode || (!sentCode && resetForm.email !== '')) && (
              <div className='content-resend-code-wrapper'>
                <p className='resend-code-title'>Didn't receive a code?</p>
                <p className='resend-code-text'>
                  We sent the verification code to {resetForm.email}. Be sure to check your
                  promotions and spam folders in case the email is redirected!
                </p>

                <button
                  type='button'
                  onClick={onSendVerificationCode}
                  className='primary-link-button'
                >
                  Resend code
                </button>
              </div>
            )}

            <button
              className='primary-button full-width-button'
              type='submit'
              disabled={
                !sentCode ||
                resetForm.email === '' ||
                resetForm.code === '' ||
                !resetForm.confirmedChecksSucceeded
              }
              onClick={onChangePassword}
            >
              Change Password
              <img src={arrowRight} alt='arrow right' className='btn-arrow' />
            </button>
          </CheckoutExperienceSection>
        </Form>
      )}
    </Layout>
  );
}
