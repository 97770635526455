import { EmailUs } from 'components/core/Buttons';

export default function HelpBlock() {
  return (
    <div className='profile-help-block'>
      <p className='help-title'>Need Help?</p>
      <p className='help-text'>
        For questions about your subscription, email <EmailUs btnClass='primary-link-button' />
      </p>
    </div>
  );
}
