import {
  useGetAllOrders,
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';

import ApproveCardBlock from 'components/dashboard/home/cards/ApproveCard';
import DoctorReviewCardBlock from 'components/dashboard/home/cards/DoctorReviewCard';
import EmptyCardBlock from 'components/dashboard/home/cards/EmptyCard';
import NoActiveSubsCardBlock from 'components/dashboard/home/cards/NoActiveSubsCard';
import ExpiringCardBlock from 'components/dashboard/home/cards/ExpiringCard';
import NextShipmentCardBlock from 'components/dashboard/home/cards/NextShipmentCard';

import { getAccountStatus } from 'lib/core/customer/getAccountStatus';

import { useAppSelector } from 'reducers/alloy_reducer';
import SingleSupplyCardBlock from '../cards/SingleSupplyCard';
import RecentOrderCardBlock from '../cards/RecentOrderCard';

export default function CardStatusWrapper() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { data: orders = [] } = useGetAllOrders();
  const { data: treatmentPlan } = useGetTreatmentPlan();

  const accountStatus = getAccountStatus(customer.status, subscriptions, orders, treatmentPlan);

  switch (accountStatus) {
    case 'NO_PURCHASE':
      return <EmptyCardBlock />;

    case 'AWAITING_DOCTOR':
    case 'AWAITING_CX_OR_QUEUE':
      return <DoctorReviewCardBlock />;

    case 'AWAITING_CUSTOMER':
      return <ApproveCardBlock />;

    case 'SINGLE_SUPPLY':
      return <SingleSupplyCardBlock />;

    case 'PRESCRIPTIONS_EXPIRING':
      return <ExpiringCardBlock />;

    case 'RECENT_ORDER':
      return <RecentOrderCardBlock />;

    case 'INACTIVE':
      return <NoActiveSubsCardBlock />;

    default:
      return <NextShipmentCardBlock />;
  }
}
