import { Skeleton } from '@mui/material';
import { useGetAllOrders, useGetOrderCarrier } from 'client/dist/generated/alloy';
import { getTrackingLink } from 'common/dist/shipping/tracking';
import { displayFriendlyOrderStatus } from 'lib/dashboard/account/displayFriendlyOrderStatus';
import { maxBy } from 'lodash';
import { Link } from 'react-router-dom';
import ProductCardBlock from '../shared/ProductCard';
import { sendMessageToSentry } from 'lib/tracking/sentry';
import { EmailUs } from 'components/core/Buttons';

export default function RecentOrderCardBlock() {
  const { data: orders = [] } = useGetAllOrders();

  const mostRecentOrder = maxBy(orders, (order) => !!order.createdAt && new Date(order.createdAt));

  const { data: orderCarrier, isLoading: isLoadingCarrier } = useGetOrderCarrier(
    mostRecentOrder?.id ?? ''
  );

  if (!mostRecentOrder) {
    return <></>;
  }

  const carrier = orderCarrier ? orderCarrier : mostRecentOrder.carrier;
  const trackingNumber = mostRecentOrder.trackingNumber;

  const products = mostRecentOrder.productFrequencies.map((pf) => ({
    product: pf,
  }));

  const displayStatus = displayFriendlyOrderStatus(mostRecentOrder);

  const getTitle = () => {
    switch (displayStatus) {
      case 'In Progress':
        return 'Processing';
      case 'Shipped':
        return 'Shipped';
      case 'Delivered':
        return 'Delivered';
      default: {
        sendMessageToSentry(`Display order status unresolved via: ${displayStatus}`);
        return '';
      }
    }
  };

  const getDescription = () => {
    switch (displayStatus) {
      case 'In Progress':
        return "We're working on your order and we'll notify you once it has shipped!";
      case 'Shipped':
        return 'Get ready for relief! Your order has shipped. You can find tracking information by viewing your order history in the account tab.';
      case 'Delivered':
        return (
          <>
            Good news! Your most recent order has delivered. Reach out to your doctor with any
            questions about your prescriptions, and email <EmailUs btnClass='text-underline' /> if
            there are any issues with the delivery.
          </>
        );
      default: {
        sendMessageToSentry(`Display order status unresolved via: ${displayStatus}`);
        return '';
      }
    }
  };

  return (
    <ProductCardBlock
      hugText={displayStatus}
      title={getTitle()}
      description={getDescription()}
      products={products}
      maxProducts={2}
    >
      {isLoadingCarrier ? (
        <Skeleton variant='text' width={125} />
      ) : carrier && trackingNumber && displayStatus === 'Shipped' ? (
        <a
          className='secondary-border-button mt-3'
          href={getTrackingLink(carrier, trackingNumber)}
          target='_blank'
          rel='noreferrer'
        >
          Track order
        </a>
      ) : (
        <Link to='/account/order-history' className='secondary-border-button mt-3'>
          View order
        </Link>
      )}
    </ProductCardBlock>
  );
}
