import { useAppSelector } from 'reducers/alloy_reducer';

import ViewableProduct from 'components/checkout-experience/products/ViewableProduct';
import SectionWrapper from 'components/core/layout/SectionWrapper';
import HeaderBlock from '../core/HeaderBlock';
import ReferralBlock from '../core/ReferralBlock';
import TopBlogBlock from '../../shared/blocks/TopBlogBlock';
import InfoSection from '../../shared/sections/Info';
import TestimonialsSection from '../../shared/sections/Testimonials';

import DashboardContainer from 'containers/dashboard/DashboardContainer';

interface Props {
  onViewTreatmentPlan: () => void;
}

export default function Confirmation({ onViewTreatmentPlan }: Props) {
  const customer = useAppSelector((state) => state.alloy.customer!!);
  const cart = useAppSelector((state) => state.treatmentPlan.treatmentPlanCart);

  return (
    <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
      <HeaderBlock title='Your order is' fancyTitle='confirmed' backgroundType='secondary' />

      <SectionWrapper sectionClasses='dashboard-treatment-plan-section'>
        <div className='col-12 col-md-7'>
          {cart.products.map((gcp, index) => (
            <ViewableProduct key={index} groupedProduct={gcp} showDosage hideDetails />
          ))}

          <p className=''>
            Thank you for your order! You'll receive an order confirmation email shortly at{' '}
            {customer.email}.
          </p>

          <p className=''>
            Any questions about your order at all, email us at{' '}
            <a href='mailto:support@myalloy.com'>support@myalloy.com</a>. We're here for you!
          </p>

          <button className='primary-button' onClick={onViewTreatmentPlan}>
            View treatment plan
          </button>
        </div>

        <div className='col-12 col-md-5'>
          <ReferralBlock />
          <TopBlogBlock />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />
    </DashboardContainer>
  );
}
