import arrowLeft from 'assets/svg/core/arrows/arrow-left.svg';
import helpIcon from 'assets/svg/core/icons/help-icon.svg';
import closeIcon from 'assets/svg/btn-close.svg';

import Banner from 'components/core/Banner';
import { EmailUs } from 'components/core/Buttons';
import { useState } from 'react';
import { Modal } from 'rsuite';
import classNames from 'classnames';

interface Props {
  onBack?: () => void;
  percentComplete?: number;
}

export default function TopBannerWithProgress({ onBack, percentComplete = 0 }: Props) {
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  return (
    <div className='top-banner-container'>
      <section className='top-banner-section'>
        <div className='banner-left-content'>
          <button
            className={classNames('content-back-button', !onBack && 'hide-content')}
            onClick={onBack}
            disabled={!onBack}
          >
            <img src={arrowLeft} alt='Back icon' className='back-icon' />
            <span className='back-text'>Back</span>
          </button>
        </div>

        <a href={`${process.env.REACT_APP_MARKETING_URL}`} className='banner-center-button'>
          <div className='banner-icon' />
        </a>

        <button className='banner-help-button' onClick={() => setIsHelpOpen(true)}>
          <span className='help-text'>Help</span>
          <img src={helpIcon} alt='help icon' className='help-icon' />
        </button>
      </section>

      <div className='progress-bar-wrapper'>
        {percentComplete !== 0 && (
          <div className='progress-bar-inner' style={{ width: `${percentComplete}%` }} />
        )}
      </div>

      <Banner />

      <Modal
        open={isHelpOpen}
        size='sm'
        className='alloy-modal'
        onClose={() => setIsHelpOpen(false)}
      >
        <Modal.Body>
          <div className='alloy-modal-header'>
            <button onClick={() => setIsHelpOpen(false)} className='header-btn-close'>
              <img src={closeIcon} alt='close icon' className='close-icon' />
            </button>
          </div>

          <div className='alloy-modal-body'>
            <p className='modal-title'>
              Need help? Find answers{' '}
              <a
                className='text-underline'
                href='https://myalloy.zendesk.com/hc/en-us'
                target='_blank'
                rel='noreferrer'
              >
                here
              </a>
              .
            </p>
            <p className='modal-text help-desc'>
              You can also contact our support team by texting 888-660-1816 or emailing{' '}
              <EmailUs btnClass='anchor-link' />.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
