import { useGetTreatmentPlan } from 'client/dist/generated/alloy';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from '@reduxjs/toolkit';

import { updatePrescribingDoctor } from 'actions/treatment-plan/symptoms_actions';

import { getPrescribingDoctorByNpiId } from 'lib/shared/contentful';

import { useAppSelector } from 'reducers/alloy_reducer';

export default function DoctorBlock() {
  const dispatch = useDispatch();

  const { data: treatmentPlan } = useGetTreatmentPlan();

  const doctor = useAppSelector((state) => state.treatmentPlan.prescribingDoctor);

  const dispatchUpdatePrescribingDoctor = bindActionCreators(updatePrescribingDoctor, dispatch);

  useEffect(() => {
    retrieveDoctor();
  }, []);

  const retrieveDoctor = async () => {
    if (treatmentPlan && !doctor) {
      const prescriptions = treatmentPlan.prescriptionProducts
        .filter((pp) => pp.prescription)
        .map((pp) => pp.prescription!);

      if (prescriptions.length > 0) {
        const mostRecentPrescription = prescriptions[prescriptions.length - 1];

        const fetchedDoctor = await getPrescribingDoctorByNpiId(
          mostRecentPrescription.doctorNpiId ?? ''
        );

        if (fetchedDoctor) {
          dispatchUpdatePrescribingDoctor(fetchedDoctor);
        }
      }
    }
  };

  if (!doctor || treatmentPlan?.prescriptionProducts.length === 0) return <></>;

  return (
    <div className='doctor-block'>
      <div className='doctor-info-block'>
        <img
          src={doctor.fields.photo?.fields.file.url}
          alt={doctor.fields.name}
          className='doctor-photo'
        />

        <div className='doctor-content'>
          <p className='doctor-title'>Prescribed by</p>
          <p className='doctor-name'>{doctor.fields.name}</p>
          <p className='doctor-text'>{doctor.fields.degrees}</p>

          <Link to='/messages' className='primary-link-button d-flex d-md-none'>
            Message your doctor
          </Link>
        </div>
      </div>

      <div className='d-none d-md-block'>
        <Link to='/messages' className='btn-message'>
          Message your doctor
        </Link>
      </div>
    </div>
  );
}
