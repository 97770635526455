import { ReactNode, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Form } from 'rsuite';

import arrowRight from 'assets/svg/core/arrows/arrow-right-white.svg';

import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import Loader from 'components/core/Loader';
import { showErrorNotification, showSuccessNotification } from 'components/core/Notification';
import { CodeField } from 'components/core/fields/CodeField';
import { EmailField } from 'components/core/fields/EmailField';
import PasswordFieldsGroup from 'components/core/fields/PasswordFieldsGroup';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import cleanNonNumberValues from 'lib/checkout-experience/authentication/cleanNonNumberValues';
import {
  confirmSignUpWithCode,
  requestForgotPasswordCode,
  resendVerificationCode,
  updatePasswordWithCode,
} from 'lib/core/awsAuth';

type ForgotForm = {
  email: string;
  code: string;
  password: string;
  confirmedChecksSucceeded: boolean;
};

export default function Forgot() {
  const navigate = useNavigate();
  const location = useLocation();

  const INIT_FORGOT_FORM: ForgotForm = {
    email: '',
    code: '',
    password: '',
    confirmedChecksSucceeded: false,
  };

  const [isLoading, setLoading] = useState(false);
  const [forgotForm, setForgotForm] = useState<ForgotForm>(INIT_FORGOT_FORM);
  const [sentCode, setSentCode] = useState(false);
  const [unconfirmedUser, setUnconfirmedUser] = useState(false);

  const onSendVerificationCode = async () => {
    const { email } = forgotForm;

    try {
      setLoading(true);

      await requestForgotPasswordCode(email);

      setSentCode(true);
      setLoading(false);

      showSuccessNotification(
        "Verification code sent, check your email (don't forget your spam folders)"
      );
    } catch (error: any) {
      if (
        error.message &&
        error.message.includes(
          'Cannot reset password for the user as there is no registered/verified email or phone_number'
        )
      ) {
        setLoading(true);

        setUnconfirmedUser(true);
        setSentCode(true);
        showErrorNotification('Account verification required. Verification code resent.');

        await resendVerificationCode(email);

        setLoading(false);
      } else {
        setLoading(false);
        showErrorNotification(error.message);
      }
    }
  };

  const onConfirmAccount = async () => {
    try {
      const { email, code } = forgotForm;

      setLoading(true);

      await confirmSignUpWithCode(email, code);

      await requestForgotPasswordCode(email);

      setUnconfirmedUser(false);

      setForgotForm({ ...forgotForm, code: '' });
      setSentCode(true);
      setLoading(false);

      showSuccessNotification('Account successfully confirmed. Verification code resent');
    } catch (error: any) {
      setLoading(false);
      showErrorNotification(error.message);
    }
  };

  const onChangePassword = async () => {
    try {
      const { email, password: newPassword, code } = forgotForm;

      setLoading(true);

      await updatePasswordWithCode(email, code, newPassword);

      navigate({ pathname: '/login', search: location.search });

      setSentCode(false);
      setLoading(false);

      showSuccessNotification('Successfully changed password, please login!');
    } catch (error: any) {
      setLoading(false);
      showErrorNotification(error.message);
    }
  };

  /**
   * A code has been sent to the email
   */
  if (!sentCode) {
    return (
      <ForgotLayout sentCode={sentCode} isLoading={isLoading}>
        <EmailField
          label='Email'
          name='email'
          value={forgotForm.email}
          placeholder='Enter your email address'
          onChange={(value) => setForgotForm({ ...forgotForm, email: value })}
        />

        <button
          className='primary-button full-width-button'
          type='submit'
          disabled={forgotForm.email === ''}
          onClick={onSendVerificationCode}
        >
          Send Verification Code
          <img src={arrowRight} alt='arrow right' className='btn-arrow' />
        </button>
      </ForgotLayout>
    );
  } else if (unconfirmedUser) {
    /**
     * A code has been sent to the email, but the user is not confirmed in cognito
     */
    return (
      <ForgotLayout sentCode={sentCode} isLoading={isLoading}>
        <p className='content-text'>
          Your account requires verification. We sent a new verification code to {forgotForm.email}
        </p>
        <p className='content-text'>Enter the code below to verify your account.</p>

        <CodeField
          name='code'
          label='Code'
          placeholder='Enter code here'
          value={forgotForm.code}
          onChange={(value) => {
            setForgotForm({
              ...forgotForm,
              code: cleanNonNumberValues(value, 6),
            });
          }}
        />

        <button
          className='primary-button full-width-button'
          type='submit'
          disabled={forgotForm.email === '' || forgotForm.code === ''}
          onClick={onConfirmAccount}
        >
          Confirm account
          <img src={arrowRight} alt='arrow right' className='btn-arrow' />
        </button>
      </ForgotLayout>
    );
  } else {
    /**
     * A code has been sent to the email and the user can reset their password
     */
    return (
      <ForgotLayout sentCode={sentCode} isLoading={isLoading}>
        <p className='content-text'>We sent a new verification code to {forgotForm.email}</p>
        <p className='content-text'>Enter the code below along with your new password.</p>

        <CodeField
          name='code'
          label='Code'
          placeholder='Enter code here'
          value={forgotForm.code}
          onChange={(value) => {
            setForgotForm({
              ...forgotForm,
              code: cleanNonNumberValues(value, 6),
            });
          }}
        />

        <PasswordFieldsGroup
          useConfirm
          onChange={(confirmedChecksSucceeded, password) =>
            setForgotForm({ ...forgotForm, confirmedChecksSucceeded, password })
          }
          passwordReset
        />

        <div className='content-resend-code-wrapper'>
          <p className='resend-code-title'>Didn't receive a code?</p>
          <p className='resend-code-text'>
            We sent the verification code to {forgotForm.email}. Be sure to check your promotions
            and spam folders in case the email is redirected!
          </p>

          <button type='button' onClick={onSendVerificationCode} className='primary-link-button'>
            Resend code
          </button>
        </div>

        <button
          className='primary-button full-width-button'
          type='submit'
          disabled={
            forgotForm.email === '' ||
            forgotForm.code === '' ||
            !forgotForm.confirmedChecksSucceeded
          }
          onClick={onChangePassword}
        >
          Change password
          <img src={arrowRight} alt='arrow right' className='btn-arrow' />
        </button>
      </ForgotLayout>
    );
  }
}

/**
 * ForgotLayout allows to pass in the children components for all the different states above for password reset.
 * This cleans up a lot and allows to not rewrite the same code over and over
 */
const ForgotLayout = ({
  children,
  sentCode,
  isLoading,
}: {
  children: ReactNode;
  sentCode: boolean;
  isLoading: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout
      title='Forgot Password - Alloy'
      desc='Reset your password for your Alloy account'
      noBars
    >
      <TopBannerWithProgress onBack={!sentCode ? () => navigate(-1) : undefined} />

      {isLoading ? (
        <Loader />
      ) : (
        <Form>
          <CheckoutExperienceSection>
            <h1 className='content-title'>Forgot password</h1>

            {!sentCode && (
              <p className='content-text'>
                Back to{' '}
                <Link to={{ pathname: '/login', search: location.search }} className='content-link'>
                  Login
                </Link>
              </p>
            )}

            {children}
          </CheckoutExperienceSection>
        </Form>
      )}
    </Layout>
  );
};
