import { useAppSelector } from 'reducers/alloy_reducer';
import ProductRegistry from 'client/dist/product/productRegistry';

export default function BlurbWrapper() {
  const { treatmentPlanCart: cart } = useAppSelector((state) => state.treatmentPlan);

  // TODO: Update for if we ever have more than 2 products later on (maybe mht somehow [probaly not tho])
  const productNames = cart.products
    .flatMap((gcp) =>
      gcp.alloyProduct.parent.map((pf) => ProductRegistry.get().getProductFullName(pf))
    )
    .join(' and ');

  return (
    <div className='ce-header-wrapper'>
      <p className='header-blurb'>
        {productNames}{' '}
        {cart.shipNow ? 'will ship immediately.' : 'will arrive with your next shipment.'}
      </p>
    </div>
  );
}
