import classNames from 'classnames';
import TitleTextWrapper from 'components/dashboard/shared/wrappers/TitleTextWrapper';

import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'reducers/alloy_reducer';

type AccountPage = 'your profile' | 'order history' | 'referrals';

interface Props {
  children: ReactNode;
  title: string;
  currentPage: AccountPage;
}

const NavigationTabs = [
  {
    title: 'Your Profile',
    link: '/account/profile',
  },
  {
    title: 'Order History',
    link: '/account/order-history',
  },
  {
    title: 'Referrals',
    link: '/account/referrals',
  },
];

const AccountNav = ({ currentPage }: { currentPage: AccountPage }) => {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const hideReferrals = !customer.referralStripePromotionCodeId;

  const FilteredNavigationTabs = NavigationTabs.filter((action) =>
    hideReferrals ? action.link !== '/account/referrals' : true
  );

  return (
    <div className='row'>
      <div className='col-12'>
        <ul className='account-nav'>
          {FilteredNavigationTabs.map((tab, index) => (
            <li
              className={classNames(
                'account-nav-item',
                tab.title.toLowerCase() === currentPage && 'selected'
              )}
              key={index}
            >
              <Link to={tab.link} className='item-link'>
                {tab.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default function AccountContainer({ children, title, currentPage }: Props) {
  return (
    <section className='dashboard-section'>
      <div className='container'>
        <TitleTextWrapper title={title} />
        <AccountNav currentPage={currentPage} />

        {children}
      </div>
    </section>
  );
}
