/**
 * @deprecated
 *
 * Old payload to keep things nice and easy with the FE.
 * We won't have some of these fields for old orders. We will make do
 */
import { ApiOrder } from 'client/dist/generated/alloy';

export function displayFriendlyOrderStatus(order: ApiOrder) {
  if (order.isConsult) {
    return 'Paid';
  }
  switch (order.internalStatus) {
    case 'SHIPPED':
      return 'Shipped';
    case 'DELIVERED':
      return 'Delivered';
    case 'ERROR':
    case 'MANUAL_INTERVENTION':
    case 'RESEND':
    case 'REQUEUE':
    case 'CANCEL_REQUESTED':
    case 'CANCELLED':
    case 'SENT_TO_FULFILLMENT':
    case 'PAID':
    default:
      return 'In Progress';
  }
}
