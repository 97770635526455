import { ClipboardEventHandler } from 'react';
import { Input } from 'rsuite';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  value: string;
  message?: string;
  classes?: string;
  onChange: (value: string) => void;
}

export const CodeField = ({
  name,
  label,
  placeholder,
  maxLength = 6,
  value,
  classes = 'field-input',
  onChange,
}: Props) => (
  <div className='input-field-wrapper'>
    {label && <p className='field-label'>{label}</p>}

    <Input
      name={name}
      placeholder={placeholder}
      className={classes}
      inputMode='decimal'
      value={value}
      maxLength={maxLength}
      onChange={onChange}
    />
  </div>
);
