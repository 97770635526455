import classNames from 'classnames';
import { Option } from 'models/components/shared/options-select-list';

interface Props {
  options: Option[];
  selectedId: string;
  onSelect: (id: string) => void;
}

export default function OptionsSelectList({ options, selectedId, onSelect }: Props) {
  return (
    <div className='options-select-wrapper'>
      {options.map((option, index) => (
        <div
          key={index}
          className={classNames('content-option', selectedId === option.id && 'selected')}
          onClick={() => onSelect(option.id)}
        >
          <div className='option-outer-circle'></div>

          <div className='option-content'>
            <p className='option-title'>{option.title}</p>

            {!!option.text && (
              <p className={classNames('option-text', option.highlightText && 'highlight-text')}>
                {option.text}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
