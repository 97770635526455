import classNames from 'classnames';

interface Props {
  unbundledPrice: number;
  bundledPrice: number;
  canBeDiscounted: boolean;
  isParentSelected: boolean;
  discountedParentName: string;
}

export default function ProductPricingBlock({
  unbundledPrice,
  bundledPrice,
  canBeDiscounted,
  isParentSelected,
  discountedParentName,
}: Props) {
  const differenceInPrice = unbundledPrice - bundledPrice;

  return (
    <>
      {canBeDiscounted && (
        <p className='header-blurb'>
          ${Number(differenceInPrice.toFixed(2))} off with purchase of {discountedParentName}
        </p>
      )}

      <p className='header-text'>
        {canBeDiscounted && isParentSelected && (
          <span className='text-discounted-price'>${bundledPrice.toFixed(2)} </span>
        )}
        <span
          className={classNames(
            'text-price',
            canBeDiscounted && isParentSelected ? 'price-strikethrough' : ''
          )}
        >
          ${unbundledPrice.toFixed(2)}
        </span>{' '}
        (3-month supply)
      </p>
    </>
  );
}
