import { Skeleton } from '@mui/material';
import { updateTPCart } from 'actions/treatment-plan/cart_actions';
import chevronDown from 'assets/svg/core/chevron-down.svg';
import {
  useGetAllSubscriptionsForCustomer,
  useGetNonCustomerUpsellProducts,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { ExperienceCategory } from 'common/dist/models/experience';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import BundleViewableProduct from 'components/checkout-experience/products/BundleViewableProduct';
import ViewableProduct from 'components/checkout-experience/products/ViewableProduct';
import ReviewDrawer from 'components/dashboard/treatment-plan/drawers/ReviewDrawer';
import { formatExperienceURL } from 'lib/core/url';
import { getNonPurchasedProducts } from 'lib/dashboard/treatmentPlan';
import { translateCategory } from 'lib/shared/category';
import { isSynbiotic } from 'lib/shared/contentful';
import {
  getAddonRequestProducts,
  getNewUpsellContentfulProducts,
  getDeepProductsFromGroupedProducts,
  getProductToBeBundledWith,
  isGroupedProductInProductsList,
} from 'lib/shared/product';
import { getUpsellGroupedContentfulProducts } from 'lib/shared/product/contentful';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';
import { uniq } from 'lodash';
import { NewUpsell } from 'models/alloy/new-upsell';
import { UpsellBundledCategories } from 'models/alloy/upsell';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { bindActionCreators } from '@reduxjs/toolkit';

interface CategoryGroupedProducts {
  notPurchased: GroupedContentfulProduct[];
  upsell: GroupedContentfulProduct[];
}

const INIT_CATEGORY_GROUPED_PRODUCTS: CategoryGroupedProducts = {
  notPurchased: [],
  upsell: [],
};

// moved this code into a reusable component
// TODO: there is much to clean up in here ESPECIALLY the way we fetch upsell products, more to come later!!
export default function RoundOutRoutineBlock() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isReviewOpen, setIsReviewOpen] = useState(false);

  // Grouped products used to display content
  const [isLoadingGrouped, setIsLoadingGrouped] = useState<boolean>(true);
  const [categoryGroupedProducts, setCategoryGroupedProducts] = useState<CategoryGroupedProducts>(
    INIT_CATEGORY_GROUPED_PRODUCTS
  );
  // Has only not purchased round out your routine products
  const [roundOutRoutineProducts, setRoundOutRoutineProducts] = useState<NewUpsell[]>([]);
  // Has all round out your routine products with discount bundles if available
  const [roundOutRoutineActiveSubProducts, setRoundOutRoutineActiveSubProducts] = useState<
    GroupedContentfulProduct[][]
  >([]);

  /**
   * Data loading for treatment plan, subscriptions, and upsell products
   */
  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGetTreatmentPlan();

  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();
  const { data: upsellProducts = [], isLoading: isLoadingUpsellProducts } =
    useGetNonCustomerUpsellProducts();

  const { activeSubs, pausedSubs } = getSubscriptionsWithStatus(subscriptions);

  const activeSubscriptions = [...activeSubs, ...pausedSubs];

  const isLoading =
    isLoadingGrouped || isLoadingTreatmentPlan || isLoadingSubscriptions || isLoadingUpsellProducts;

  // Filter 1: One time products that are in our prescription products
  // Filter 2: One time products by product id that are not in our subscriptions ie (pill [one time] is not in sub [recurring])
  const oneTimePurchasedPrescriptionProducts = treatmentPlan!.prescriptionProducts
    .filter((p) => p.product.recurrenceType === 'ONE_TIME' && p.prescription?.fillsRemaining === 0)
    .filter(
      (p) =>
        !activeSubscriptions
          .flatMap((sub) => sub.products.map((pfr) => pfr.product.productId))
          .includes(p.product.productId)
    );

  const nonSubscriptionProducts = getNonPurchasedProducts(
    activeSubscriptions,
    oneTimePurchasedPrescriptionProducts,
    treatmentPlan!.prescriptionProducts,
    treatmentPlan!.pendingCharges.flatMap((pc) => pc.products)
  );

  const dispatchUpdateCart = bindActionCreators(updateTPCart, dispatch);

  useEffect(() => {
    retrieveGroupedAndContentfulProducts();
  }, [JSON.stringify(treatmentPlan), isLoadingSubscriptions, isLoadingUpsellProducts]);

  /**
   * using the subscription products and upsell products, we need to fetch the contentful and grouped products.
   * this will allow us to use these products for display grouped products as well as handle passing pf
   * to BE when needed
   *
   * purchased products are split into active subscription and invoice only products (one time)
   * not purchased products are products prescribed but not purchased
   * upsell products are products that the customer can request
   */
  const retrieveGroupedAndContentfulProducts = async () => {
    if (
      treatmentPlan?.pendingCharges.length !== 0 ||
      activeSubscriptions.length !== 0 ||
      upsellProducts.length !== 0
    ) {
      setIsLoadingGrouped(true);

      // leaving for now cuz of the upsell bit BUT TODO: this needs some major cleaning into its own sections
      // + upsell should just come from BE so we don't have to do any magic here...
      const activeSubscriptionsProducts = activeSubscriptions.flatMap((sub) =>
        sub.products.map((p) => p.product)
      );

      const oneTimePurchasedProducts = oneTimePurchasedPrescriptionProducts.map(
        (nsp) => nsp.product
      );

      const purchasedProducts = [...activeSubscriptionsProducts, ...oneTimePurchasedProducts];

      const notPurchasedProducts = nonSubscriptionProducts
        .filter((pp) => !pp.renewal?.hasExpired)
        .map((pp) => pp.product);

      // TODO: for future mikhail, would love to adjust the upsell/round out code a lot more dynamically by just using the two arrays
      // below since we have the data we don't need to do anything fancy for bundles and whatnot

      // since we know hey you have there prescriptions BUT have NOT purchased and then you have these upsells that DONT HAVE any prescriptions
      // lets render these as so in each specified custom category, we shouldn't be doing all the jumps to understand which product to show
      // and filter if the BE already gives that
      const notPurchased = (
        await ProductRegistry.get().getRecurringProductsForV2(notPurchasedProducts)
      ).flat();
      const upsell = await getUpsellGroupedContentfulProducts(upsellProducts);

      // get the split upsells, so we don't bundle anything, but still get the bundled price
      const upsellsCategoryContentfulProduct = await getNewUpsellContentfulProducts(
        purchasedProducts,
        notPurchasedProducts,
        upsellProducts
      );

      const upsellActiveSubProducts = await ProductRegistry.get().getRecurringProductsForV2([
        ...purchasedProducts,
        ...getDeepProductsFromGroupedProducts(
          upsellsCategoryContentfulProduct.flatMap((up) => up.products)
        ),
      ]);

      setCategoryGroupedProducts({
        notPurchased,
        upsell,
      });

      setRoundOutRoutineProducts(upsellsCategoryContentfulProduct);
      setRoundOutRoutineActiveSubProducts(upsellActiveSubProducts);

      setIsLoadingGrouped(false);
    }
  };

  const toggleList = (id: string) => {
    const listElement = document.getElementById(id);

    if (listElement) {
      if (listElement.classList.contains('active')) {
        listElement.classList.remove('active');
      } else {
        listElement.classList.add('active');
      }
    }
  };

  const onAddToPlan = (product: GroupedContentfulProduct) => {
    dispatchUpdateCart({
      products: [product],
    });

    setIsReviewOpen(true);
  };

  const shouldAddToPlan = (gcp: GroupedContentfulProduct) => {
    const allNotPurchasedProducts = getDeepProductsFromGroupedProducts(
      categoryGroupedProducts.notPurchased
    );

    return (
      isSynbiotic(gcp.contentfulProduct!) ||
      isGroupedProductInProductsList(allNotPurchasedProducts, gcp)
    );
  };

  const onAddToPlanOrRequest = (product: GroupedContentfulProduct) => {
    if (shouldAddToPlan(product)) {
      onAddToPlan(product);
    } else {
      const requestingProducts = getAddonRequestProducts(
        product,
        roundOutRoutineActiveSubProducts,
        activeSubscriptions,
        nonSubscriptionProducts
      );

      const productIds = getDeepProductsFromGroupedProducts(requestingProducts).map(
        (pf) => pf.productId
      );

      const categories: ExperienceCategory[] = requestingProducts.flatMap(
        (gcp) => gcp.contentfulProduct.fields.categories
      );

      // as we do anywhere, just send the customer to the basic url and from there, the request experience
      // will handle where to place them and check and restrictions
      const experienceUrl = formatExperienceURL('/request-experience', location, uniq(categories), [
        ['productIds[]', uniq(productIds).join(',')],
      ]);

      navigate(experienceUrl);
    }
  };

  if (isLoading) {
    return (
      <>
        <Skeleton variant='rectangular' height={65} className='mb-3' />
        <Skeleton variant='rectangular' height={400} className='mb-3' />
      </>
    );
  }

  if (roundOutRoutineProducts.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className='round-out-routine-block'>
        <div className='tp-products-list-wrapper closable active' id='non-active-products-list'>
          <div className='tp-header'>
            <p className='tp-subtitle'>
              Round out your <span>routine</span>
            </p>

            <button
              className='tp-chevron-btn'
              onClick={() => {
                toggleList('non-active-products-list');
              }}
            >
              <img src={chevronDown} alt='chevron-down' />
            </button>
          </div>

          <div className='products-list'>
            {roundOutRoutineProducts.map(({ category, products }, j) => (
              <div key={j}>
                {products.length !== 0 && (
                  <div className='category-banner'>
                    <span>{translateCategory(category as UpsellBundledCategories)}</span>
                  </div>
                )}

                {products.map((gcp, index) => {
                  const parent = getProductToBeBundledWith(gcp, roundOutRoutineActiveSubProducts);

                  return !!parent ? (
                    <BundleViewableProduct
                      key={index}
                      groupedProduct={gcp}
                      isParentSelected
                      parent={parent}
                    >
                      <button
                        className='product-action-btn'
                        onClick={() => onAddToPlanOrRequest(gcp)}
                      >
                        {category === 'skin-health' && products.length === 2
                          ? 'Get prescription skincare'
                          : shouldAddToPlan(gcp)
                          ? 'Add to plan'
                          : 'Request from doctor'}
                      </button>
                    </BundleViewableProduct>
                  ) : (
                    <ViewableProduct key={index} groupedProduct={gcp} showDosage>
                      <button
                        className='product-action-btn'
                        onClick={() => onAddToPlanOrRequest(gcp)}
                      >
                        {category === 'skin-health' && products.length === 2
                          ? 'Get prescription skincare'
                          : shouldAddToPlan(gcp)
                          ? 'Add to plan'
                          : 'Request from doctor'}
                      </button>
                    </ViewableProduct>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/*
       * Review drawer handles opening a drawer for products that user is eligible to purchase (prescribed but not bought and active) + cart.
       * We also add synbiotic (if it exists in upsell as a add on since there is no intake) to the list of products that they can buy
       */}

      <ReviewDrawer
        open={isReviewOpen}
        setOpen={setIsReviewOpen}
        notPurchasedEligibleProducts={roundOutRoutineProducts
          .map((nu) => nu.products)
          .flat()
          .filter((p) => shouldAddToPlan(p))}
        activeSubProducts={roundOutRoutineActiveSubProducts}
      />
    </>
  );
}
