import { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Modal } from 'rsuite';

import closeIcon from 'assets/svg/btn-close.svg';

const videoConstraints = {
  width: 400,
  height: 300,
  facingMode: 'user',
};

interface Props {
  open: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onNext: (photo: string) => void;
}

export default function PhotoModal({ open, setIsOpen, onNext }: Props) {
  const [hasAccess, setHasAccess] = useState(true);

  const [photo, setPhoto] = useState<string>('');
  const webcamRef = useRef<any>(null);

  const onCapture = useCallback(() => {
    if (webcamRef) {
      const photoSrc = webcamRef.current.getScreenshot();
      setPhoto(photoSrc);
    }
  }, []);

  return (
    <Modal open={open} size='sm' className='alloy-modal' onClose={() => setIsOpen(false)}>
      <Modal.Body>
        <div className='alloy-modal-header'>
          <button onClick={() => setIsOpen(false)} className='header-btn-close'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>
        </div>

        <div className='alloy-modal-body'>
          <p className='modal-title'>Take a photo of yourself</p>

          <div className='modal-photo-wrapper'>
            {photo == '' && hasAccess ? (
              <Webcam
                className='bg-light-blue'
                audio={false}
                width={400}
                height={300}
                ref={webcamRef}
                screenshotFormat='image/jpeg'
                videoConstraints={videoConstraints}
                screenshotQuality={1}
                mirrored
                onUserMediaError={(error) => {
                  if (error) {
                    setHasAccess(false);
                  }
                }}
              />
            ) : (
              <>
                {photo !== '' ? (
                  <img src={photo} />
                ) : (
                  <p className='modal-text'>
                    You have not authorized your webcam to take a photo on Alloy. Authorize your
                    webcam for {window.location.host} in browser settings.
                  </p>
                )}
              </>
            )}
          </div>

          <div className='modal-btn-wrapper'>
            {hasAccess && (
              <>
                {photo !== '' ? (
                  <button onClick={() => setPhoto('')} className='btn-cancel'>
                    Retake Photo
                  </button>
                ) : (
                  <button className='btn-confirm' onClick={onCapture}>
                    Take photo
                  </button>
                )}

                {photo !== '' && (
                  <button className='btn-confirm' onClick={() => onNext(photo)}>
                    Next
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
