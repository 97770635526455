import { Skeleton } from '@mui/material';
import ReviewCarousel from 'components/core/ReviewCarousel';
import SectionWrapper from 'components/core/layout/SectionWrapper';
import formatReviewCards from 'lib/core/formatReviewCards';
import { getReviews } from 'lib/shared/contentful';
import { ContentfulReview } from 'models/contentful/reviews';
import { useEffect, useState } from 'react';

export default function ReviewsSection() {
  const [reviews, setReviews] = useState<ContentfulReview[] | undefined>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getTrustpilotReviews();
  }, []);

  const getTrustpilotReviews = async () => {
    const reviews = await getReviews('home');

    setReviews(reviews);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <SectionWrapper sectionClasses='dashboard-treatment-plan-section'>
        <div className='col-12'>
          <Skeleton variant='rectangular' height={300} />
        </div>
      </SectionWrapper>
    );
  }

  const formattedReviews = formatReviewCards(reviews ?? []);

  return <ReviewCarousel title='What Alloy customers are saying' reviewCards={formattedReviews} />;
}
