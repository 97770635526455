import { Loader as RSuiteLoader } from 'rsuite';

type Size = 'lg' | 'md' | 'sm' | 'xs';

interface Props {
  size?: Size;
}

export default function Loader({ size = 'lg' }: Props) {
  return (
    <div className={`loader-section ${size}`}>
      <RSuiteLoader center size={size} />
    </div>
  );
}
