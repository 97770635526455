import { Checkout, Customer, patchCustomer } from 'client/dist/generated/alloy';
import { ExperienceCategory } from 'common/dist/models/experience';
import { getCartTotals } from 'lib/shared/cart';
import { getReadablePrice } from 'lib/shared/product';
import { sendExceptionToSentry, sendMessageToSentry } from 'lib/tracking/sentry';

import { AlloyCartWithTerms } from 'models/alloy/cart';

type FreshpaintEvent =
  | 'REGISTRATION_COMPLETE'
  | 'CHECKOUT_CONFIRMATION'
  | 'REQUEST_CONFIRMATION'
  | 'CHECKOUT_CONFIRMATION2';

function getFreshpaintCategory(checkoutCategory: ExperienceCategory) {
  switch (checkoutCategory) {
    case 'mht':
      return 1;
    case 'sexual-health':
      return 2;
    case 'skin-health':
      return 3;
    case 'vaginal-health':
      return 4;
    case 'gut-health':
      return 5;
    case 'hair-health':
      return 6;
    default:
      throw Error(`Checkout category ${checkoutCategory} invalid or doesn't exist.`);
  }
}

function waitForThenDo(funct: () => void, trigger: () => boolean): void {
  let retries = 0;
  const interval = setInterval(() => {
    if (retries > 100) {
      clearInterval(interval);
      sendMessageToSentry('Exhausted retries trying to communicate with the freshpaint instance.');
      return;
    }
    if (!trigger()) {
      retries++;
      return;
    } else {
      funct();
      clearInterval(interval);
    }
  }, 100);
}

const identifyFreshpaint = (customer: Customer) =>
  waitForThenDo(
    () =>
      window.freshpaint.identify(customer.patientId, {
        email: customer.email,
      }),
    () => window.freshpaint?.identify
  );

const trackFreshpaint = (event: FreshpaintEvent, body?: Record<string, unknown>) => {
  window.freshpaint.track(event, body);
};

const trackCheckoutConfirmationFreshpaint = (
  customer: Customer,
  cart: AlloyCartWithTerms,
  checkout: Checkout,
  checkoutCategories: ExperienceCategory[],
  experienceType: 'checkout' | 'request'
) => {
  const { subtotal, discount, referralCreditDiscount } = getCartTotals(cart);
  const revenue = parseFloat((subtotal - discount - referralCreditDiscount).toFixed(2));
  const couponData =
    cart.promotionCode.id !== ''
      ? {
          'Coupon Code': cart.promotionCode.id !== '' ? cart.promotionCode.name : undefined,
          'Coupon Discount':
            cart.promotionCode.id !== '' ? cart.promotionCode.discountAmount : undefined,
        }
      : {};

  const eventName: FreshpaintEvent =
    experienceType === 'request' ? 'REQUEST_CONFIRMATION' : 'CHECKOUT_CONFIRMATION';
  trackFreshpaint(eventName, {
    'First Name': customer.firstName,
    'Last Name': customer.lastName,
    Email: customer.email,
    'Order ID': checkout.id,
    Items: cart.products.flatMap((gcp) =>
      gcp.alloyProduct.parent.map((pf) => ({
        id: pf.name,
        name: pf.name,
        price: getReadablePrice(pf.priceInCents),
      }))
    ),
    Revenue: revenue,
    'Flow ID': checkoutCategories.map((c) => `${getFreshpaintCategory(c)}`).join(','),
    ...couponData,
  });
  //NOTE: temporary during anonymization of FB event, see shortcut story 19290
  if ((experienceType = 'checkout')) {
    trackFreshpaint('CHECKOUT_CONFIRMATION2', {
      'First Name': customer.firstName,
      'Last Name': customer.lastName,
      Email: customer.email,
      'Order ID': checkout.id,
      Items: cart.products.flatMap((gcp) =>
        gcp.alloyProduct.parent.map((pf) => ({
          id: pf.name,
          name: pf.name,
          price: getReadablePrice(pf.priceInCents),
        }))
      ),
      Revenue: revenue,
      'Flow ID': checkoutCategories.map((c) => `${getFreshpaintCategory(c)}`).join(','),
      ...couponData,
    });
  }
};

const correlateFreshpaintToCustomer = () =>
  waitForThenDo(
    async () => {
      const freshpaintId = window.freshpaint.get_property('$device_id');
      try {
        await patchCustomer({ freshpaintId });
      } catch (e: any) {
        sendExceptionToSentry(e);
      }
    },
    () => window.freshpaint?.get_property
  );

/**
 * Reference: https://documentation.freshpaint.io/faqs/what-data-does-freshpaint-collect/autotrack#a-dvertising-click-parameters
 *
 * If anything goes wrong, we swallow the exception
 *
 * @returns clickId, UtmTerm and UtmSource from freshpaint
 */
const getFromFreshpaintCookie = () => {
  try {
    const cookieProps = window.freshpaint?.cookie?.props?.__user_props;
    const freshpaintClickId =
      window.freshpaint?.cookie?.props?.$gclid ??
      window.freshpaint?.cookie?.props?.$fbclid ??
      window.freshpaint?.cookie?.props?.$msclkid ??
      window.freshpaint?.cookie?.props?.$twclid ??
      window.freshpaint?.cookie?.props?.$ttclid;
    const freshpaintUtmTerm = cookieProps.utm_term;
    const freshpaintUtmSource = cookieProps.utm_source;
    const freshpaintUtmCampaign = cookieProps.utm_campaign;
    const freshpaintUtmMedium = cookieProps.utm_medium;
    const freshpaintUtmContent = cookieProps.utm_content;
    return {
      freshpaintClickId: freshpaintClickId,
      freshpaintUtmTerm: freshpaintUtmTerm,
      freshpaintUtmSource: freshpaintUtmSource,
      freshpaintUtmCampaign: freshpaintUtmCampaign,
      freshpaintUtmMedium: freshpaintUtmMedium,
      freshpaintUtmContent: freshpaintUtmContent,
    };
  } catch (e) {
    console.error('Could not fetch freshpaint details');
    console.error(e);
    return {};
  }
};

export {
  identifyFreshpaint,
  trackFreshpaint,
  trackCheckoutConfirmationFreshpaint,
  correlateFreshpaintToCustomer,
  getFromFreshpaintCookie,
};
