import {
  pauseSubscription,
  unbundleProductFromSubscription,
  useGetAllSubscriptionsForCustomer,
} from 'client/dist/generated/alloy';
import { useState } from 'react';
import { Form, Loader, Modal, Radio, RadioGroup } from 'rsuite';

import closeIcon from 'assets/svg/btn-close.svg';

import { showSuccessNotification } from 'components/core/Notification';
import { TextAreaField } from 'components/core/fields/TextAreaField';
import { useSubscriptionContext } from 'context/dashboard/subscriptions/manage';
import { getUnixTime } from 'date-fns';
import { ManageLevel } from 'models/components/shared/manage-type';
import { getUnbundledDeepProductIdsFrom } from 'lib/shared/subscriptions/product-filter';
import { useNavigate } from 'react-router-dom';

interface Props {
  selectedDeepProductIds: number[];
  manageLevel: ManageLevel;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const optionsText: string[] = [
  "I'm not feeling better",
  "My treatments aren't covered by insurance",
  'A doctor told me not to take it',
  'I want to try a different product',
  "It's too expensive",
  "I've had a poor experience with Alloy",
  "I don't want a subscription",
  'Other',
];

export default function PauseModal({ selectedDeepProductIds, manageLevel, open, setOpen }: Props) {
  const navigate = useNavigate();

  const { subscription } = useSubscriptionContext();
  const { mutate } = useGetAllSubscriptionsForCustomer();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [optionSelected, setOptionSelected] = useState<number | undefined>();
  const [reasonPausing, setReasonPausing] = useState<string>('');

  const onUpdateSelection = (text: string, index: number) => {
    setOptionSelected(index);
    setReasonPausing(index === optionsText.length - 1 ? '' : text);
  };

  const onConfirm = async () => {
    setIsLoading(true);

    if (manageLevel === 'PRODUCT') {
      const startTimestamp = getUnixTime(new Date(subscription.nextRecurrenceOn));

      const deepProductIds = await getUnbundledDeepProductIdsFrom(
        subscription,
        selectedDeepProductIds
      );

      await unbundleProductFromSubscription({
        stripeSubscriptionId: subscription.stripeSubscriptionId,
        productFrequencyIds: deepProductIds,
        type: 'PAUSE',
        reasonPausing,
        startTimestamp,
      });
    } else {
      await pauseSubscription(subscription.stripeSubscriptionId, { reason: reasonPausing });
    }

    await mutate();

    if (manageLevel === 'PRODUCT') {
      showSuccessNotification('Your product has been paused');
    } else {
      showSuccessNotification('Your subscription has been paused');
    }

    navigate('/subscriptions', { replace: true });
  };

  return (
    <Modal
      open={open}
      size='sm'
      className='subscription-modal'
      onClose={() => {
        if (!isLoading) setOpen(false);
      }}
    >
      <Modal.Body>
        <div className='subscription-modal-header'>
          <button onClick={() => setOpen(false)} className='header-btn-close' disabled={isLoading}>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>
        </div>

        <div className='subscription-modal-body'>
          <p className='modal-title'>Are you sure you'd like to pause?</p>
          <p className='modal-text'>Please select your reason for pausing.</p>

          <div className='modal-btn-wrapper'>
            {isLoading ? (
              <Loader size='md' />
            ) : (
              <>
                <div className='modal-select-options-list'>
                  <Form>
                    <RadioGroup name='pause-reason-options' value={optionSelected}>
                      {optionsText.map((text, index) => (
                        <Radio
                          key={index}
                          value={index}
                          className='option-radio'
                          onChange={(value) => onUpdateSelection(text, value as number)}
                        >
                          {text}
                        </Radio>
                      ))}
                    </RadioGroup>

                    {optionSelected === optionsText.length - 1 && (
                      <TextAreaField
                        name='other-reason'
                        rows={3}
                        onChange={(value) => setReasonPausing(value as string)}
                        placeholder='Additional comments'
                      />
                    )}
                  </Form>
                </div>

                <button className='btn-confirm' onClick={onConfirm} disabled={reasonPausing === ''}>
                  Submit request
                </button>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
