import {
  DeepProduct,
  RecurrenceType,
  translateFrequency,
} from 'common/dist/products/productFrequency';
import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { sumBy } from 'lodash';

/**
 * the FE mainly uses this to get the right product price, this allows for the cart/product cards to display the right bundled/unbundled price when needed.
 * mainly used for bundling since we have the other prices correctly
 *
 * @param productId number
 * @param recurrenceType one time or recurring
 * @param isBundled boolean
 * @returns number
 */
export const getProductPrice = async (
  product: DeepProduct,
  recurrenceType: RecurrenceType = 'RECURRING',
  isBundled: boolean = false
): Promise<number> => {
  const { productId, dosageId, formFactorId } = product;
  return (
    await ProductRegistry.get().getProductFrequency(
      productId,
      translateFrequency(recurrenceType),
      dosageId,
      formFactorId,
      isBundled
    )
  ).priceInCents;
};

export const getSubtotalInCentsFrom = (products: GroupedContentfulProduct[][]) =>
  sumBy(
    products.flatMap((gcpList) =>
      gcpList.flatMap((gcp) => [...gcp.alloyProduct.parent, ...(gcp.alloyProduct.child || [])])
    ),
    (pf) => pf.priceInCents
  );
