import { InputNumber } from 'rsuite';

export interface Props {
  name: string;
  value?: number;
  placeholder?: string;
  onChange: (value: string | number) => void;
}

export const NumberField = ({ name, placeholder, value, onChange }: Props) => (
  <InputNumber
    name={name}
    value={value}
    placeholder={placeholder}
    className='info-input-number'
    inputMode='decimal'
    size='lg'
    min={0}
    max={1000}
    onChange={onChange}
  />
);
