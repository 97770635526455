import { FC } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { ApiOrder, ApiOrderPdfInvoiceLinesItem, Customer } from 'client/dist/generated/alloy';
import { format } from 'date-fns';
import { convertCentsToDollars } from 'lib/shared/convert';
import _ from 'lodash';

interface Props {
  orderNumber: string;
  order: ApiOrder;
  customer: Customer;
}

type PdfInvoiceLineItemDisplay = Omit<ApiOrderPdfInvoiceLinesItem, 'unitPriceInCents'> & {
  formattedUnitPrice: string;
  formattedExtendedPrice: string;
};

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    margin: 10,
    padding: 20,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerLeft: {
    width: '50%',
    fontSize: 20,
  },
  headerRight: {
    width: '50%',
  },
  headerLogo: {
    width: 75,
    marginLeft: 'auto',
    paddingRight: 10,
  },
  orderNumberAndDate: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  orderNumberAndDateSpacing: {
    marginLeft: 40,
  },
  fontEmphasized: {
    fontSize: 14,
  },
  fontNormalSpaced: {
    fontSize: 12,
    marginTop: 5,
  },
  addressInfo: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row',
  },
  addressInfoLeft: {
    width: '50%',
  },
  addressInfoRight: {
    width: '50%',
  },
  amountSummary: {
    marginTopFirstPage: 30,
    marginTopOtherPages: 195,
    fontSize: 20,
    marginBottom: 30,
  },
  table: {
    width: '100%',
    fontSize: 10,
    paddingRight: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8,
  },
  tableHeader: {
    borderTop: 'none',
  },
  description: {
    width: '60%',
  },
  unitPrice: {
    width: '15%',
    textAlign: 'right',
  },
  qty: {
    width: '10%',
    textAlign: 'right',
  },
  extendedPrice: {
    width: '15%',
    textAlign: 'right',
  },
  pharmacyInfoAndLineItemsSummary: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  },
  pharmacyInfoSpacing: {
    marginTop: 20,
  },
  pharmacyInfoAndLineItemsSummaryLeft: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
  },
  pharmacyInfoAndLineItemsSummaryRight: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
    paddingRight: 20,
  },
  lineItemNpiAndNdc: {
    fontSize: 8,
    marginTop: 5,
    marginLeft: 5,
  },
  lineItemSummaryDescription: {
    width: '70%',
  },
  lineItemSummaryAmount: {
    width: '30%',
    textAlign: 'right',
  },
  amountPaidDescription: {
    width: '70%',
    fontSize: 14,
  },
  amountPaidAmount: {
    width: '30%',
    textAlign: 'right',
    fontSize: 14,
  },
  footer: {
    marginTop: 'auto',
    paddingRight: 20,
  },
  footerLine: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    fontSize: 8,
  },
  footerSummaryInfo: {
    marginTop: 4,
  },
  footerPageInfo: {
    marginLeft: 'auto',
    marginTop: 4,
    alignSelf: 'center',
  },
});

// Create Document Component
export const PdfInvoice: FC<Props> = ({ orderNumber, order, customer }) => {
  const longDate = format(order.createdAt!, 'MMMM d, yyyy');
  const amountSummary = `$${convertCentsToDollars(order?.totalInCents)} USD on ${longDate}`;

  const lineItems =
    order.pdfInvoiceLines?.map((l) => ({
      description: l.description,
      npi: l.npi,
      ndc: l.ndc,
      qty: l.qty,
      formattedUnitPrice: convertCentsToDollars(l.unitPriceInCents),
      formattedExtendedPrice: convertCentsToDollars(l.unitPriceInCents * l.qty),
    })) ?? [];

  const maxLineItemsPerPage = 4;
  const paginatedLineItems = _.chunk(lineItems, maxLineItemsPerPage);

  return (
    <Document>
      {paginatedLineItems.map((page, zeroBasedPageNumber) => (
        <Page size='A4' style={styles.page} key={zeroBasedPageNumber}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Text>Invoice</Text>
            </View>
            <View style={styles.headerRight}>
              <Image style={styles.headerLogo} src='/assets/logo.png'></Image>
            </View>
          </View>
          <View style={styles.orderNumberAndDate}>
            <View>
              <Text style={styles.fontEmphasized}>Order Number</Text>
              <Text style={styles.fontNormalSpaced}>Date</Text>
            </View>
            <View style={styles.orderNumberAndDateSpacing}>
              <Text style={styles.fontEmphasized}>{orderNumber}</Text>
              <Text style={styles.fontNormalSpaced}>{longDate}</Text>
            </View>
          </View>
          {zeroBasedPageNumber === 0 && (
            <View style={styles.addressInfo}>
              <View style={styles.addressInfoLeft}>
                <Text style={styles.fontEmphasized}>Alloy Health Inc.</Text>
                <Text style={styles.fontNormalSpaced}>31 Bond Street</Text>
                <Text style={styles.fontNormalSpaced}>6th Floor</Text>
                <Text style={styles.fontNormalSpaced}>New York, New York 10012</Text>
                <Text style={styles.fontNormalSpaced}>United States</Text>
                <Text style={styles.fontNormalSpaced}>support@myalloy.com</Text>
              </View>
              <View style={styles.addressInfoRight}>
                <Text style={styles.fontEmphasized}>Shipping address</Text>
                <Text style={styles.fontNormalSpaced}>
                  {order?.shippingAddress?.firstName} {order?.shippingAddress?.lastName}
                </Text>
                <Text style={styles.fontNormalSpaced}>{order?.shippingAddress?.line1}</Text>
                <Text style={styles.fontNormalSpaced}>{order?.shippingAddress?.line2}</Text>
                <Text style={styles.fontNormalSpaced}>
                  {order?.shippingAddress?.city}, {order?.shippingAddress?.state}{' '}
                  {order?.shippingAddress?.zip}
                </Text>
                <Text style={styles.fontNormalSpaced}>
                  {order?.shippingAddress?.country ?? 'United States'}
                </Text>
                <Text style={styles.fontNormalSpaced}>{customer.email}</Text>
              </View>
            </View>
          )}
          <View>
            <Text
              style={{
                ...styles.amountSummary,

                // Account for addresses only showing on first
                // page. Still want the whitespace even if the
                // addresses are not showing. Otherwise it looks
                // really weird IMO.
                marginTop:
                  zeroBasedPageNumber > 0
                    ? styles.amountSummary.marginTopOtherPages
                    : styles.amountSummary.marginTopFirstPage,
              }}
            >
              {amountSummary}
            </Text>
          </View>
          <View>
            <View style={styles.table}>
              <View style={[styles.row, styles.tableHeader]}>
                <Text style={styles.description}>Description</Text>
                <Text style={styles.unitPrice}>Unit Price</Text>
                <Text style={styles.qty}>Qty</Text>
                <Text style={styles.extendedPrice}>Amount</Text>
              </View>
              {page.map((item, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <View style={styles.description}>
                    <Text>{item.description}</Text>
                    {item.npi?.length > 0 && (
                      <Text style={styles.lineItemNpiAndNdc}>NPI: {item.npi}</Text>
                    )}
                    {item.ndc?.length > 0 && (
                      <Text style={styles.lineItemNpiAndNdc}>NDC: {item.ndc}</Text>
                    )}
                  </View>
                  <Text style={styles.unitPrice}>${item.formattedUnitPrice}</Text>
                  <Text style={styles.qty}>{item.qty}</Text>
                  <Text style={styles.extendedPrice}>${item.formattedExtendedPrice}</Text>
                </View>
              ))}
            </View>
          </View>
          {zeroBasedPageNumber === paginatedLineItems.length - 1 && (
            <View style={styles.pharmacyInfoAndLineItemsSummary}>
              <View style={styles.pharmacyInfoAndLineItemsSummaryLeft}>
                <View>
                  <Text style={styles.fontEmphasized}>Pharmacy Address:</Text>
                  <Text style={styles.fontNormalSpaced}>Curexa</Text>
                  <Text style={styles.fontNormalSpaced}>3007 Ocean Heights Ave</Text>
                  <Text style={styles.fontNormalSpaced}>Egg Harbor Township, NJ, 08234</Text>
                </View>
                <View style={styles.pharmacyInfoSpacing}>
                  <Text style={styles.fontEmphasized}>Pharmacy Number:</Text>
                  <Text style={styles.fontNormalSpaced}>(855) 927-0390</Text>
                </View>
                <View style={styles.pharmacyInfoSpacing}>
                  <Text style={styles.fontEmphasized}>Tax ID:</Text>
                  <Text style={styles.fontNormalSpaced}>852662697</Text>
                </View>
              </View>
              <View style={styles.pharmacyInfoAndLineItemsSummaryRight}>
                <View style={styles.row} wrap={false}>
                  <Text style={styles.lineItemSummaryDescription}>Sub Total</Text>
                  <Text style={styles.lineItemSummaryAmount}>
                    ${convertCentsToDollars(order.subtotalInCents)}
                  </Text>
                </View>
                {order.discount &&
                  order.discount.map((discount, i) => (
                    <View key={i} style={styles.row} wrap={false}>
                      <Text style={styles.lineItemSummaryDescription}>
                        {`Discount (${discount.name})`}
                      </Text>
                      <Text style={styles.lineItemSummaryAmount}>
                        - ${convertCentsToDollars(discount.amount)}
                      </Text>
                    </View>
                  ))}
                <View style={styles.row} wrap={false}>
                  <Text style={styles.lineItemSummaryDescription}>Taxes</Text>
                  <Text style={styles.lineItemSummaryAmount}>
                    ${convertCentsToDollars(order.taxInCents!)}
                  </Text>
                </View>
                <View style={styles.row} wrap={false}>
                  <Text style={styles.lineItemSummaryDescription}>Shipping</Text>
                  <Text style={styles.lineItemSummaryAmount}>
                    ${convertCentsToDollars(order.shippingMethod?.priceInCents ?? 0)}
                  </Text>
                </View>
                <View style={styles.row} wrap={false}>
                  <Text style={styles.amountPaidDescription}>Amount Paid</Text>
                  <Text style={styles.amountPaidAmount}>
                    ${convertCentsToDollars(order.totalInCents!)}
                  </Text>
                </View>
              </View>
            </View>
          )}
          <View style={styles.footer}>
            <View style={styles.footerLine}>
              <View style={styles.footerSummaryInfo}>
                <Text>{`${orderNumber} - ${amountSummary}`}</Text>
              </View>
              <View style={styles.footerPageInfo}>
                <Text>
                  Page {zeroBasedPageNumber + 1} of {paginatedLineItems.length}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};
