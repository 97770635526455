import { MaskField } from './MaskField';

export interface Props {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  classes?: string;
  onChange: (value: string) => void;
}

export const DateField = ({
  name,
  label,
  placeholder,
  value,
  classes = 'field-input',
  onChange,
}: Props) => (
  <MaskField
    name={name}
    label={label}
    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    placeholder={placeholder}
    value={value}
    classes={classes}
    onChange={onChange}
  />
);
