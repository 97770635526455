import { useEffect } from 'react';
import {
  useGetAllUnreadNotifications,
  markNotificationsAsReadByEventType,
  EventType,
} from 'client/dist/generated/alloy';

// TODO: think of a better strategy around just calling this at a dashboard level ONCE (that way we don't call it in every page)
// and then we can just pass event type and it handles it automatically
const useMarkNotificationsAsRead = (eventTypes: EventType[]) => {
  const { data: notifications = [], mutate: mutateNotifications } = useGetAllUnreadNotifications();

  const handleMarkNotificationsAsRead = async () => {
    if (notifications.filter((n) => eventTypes.includes(n.eventType)).length !== 0) {
      await markNotificationsAsReadByEventType({ eventTypes });
      await mutateNotifications();
    }
  };

  useEffect(() => {
    handleMarkNotificationsAsRead();
  }, []);

  return { handleMarkNotificationsAsRead };
};

export default useMarkNotificationsAsRead;
