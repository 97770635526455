import Stripe from 'stripe';
import promoCodeClient from './promoCodeClient';

export const getPromotionCodeForCart = async (
  promotionCode: string,
  subtotal: number,
  productIds: Stripe.Product['id'][]
) => {
  const validPromotionCode = await promoCodeClient.checkValidity(promotionCode, {
    stripeProductIds: productIds,
  });

  let discountAmount = 0;

  if (validPromotionCode.coupon.amount_off) {
    discountAmount = validPromotionCode.coupon.amount_off / 100;
  } else if (validPromotionCode.coupon.percent_off) {
    discountAmount = parseFloat(
      (subtotal * (validPromotionCode.coupon.percent_off / 100)).toFixed(2)
    );
  }

  return {
    id: validPromotionCode.id,
    name: validPromotionCode.code,
    discountAmount,
  };
};
