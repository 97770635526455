import { ExperienceCategory } from 'common/dist/models/experience';
import { sub } from 'date-fns';
import { isEqual } from 'lodash';
import { LocalPreCustomer } from 'reducers/experience_reducer';

/**
 * check whether the local submission exists based on specific criteria matching categories
 * and also being within the last 2 weeks
 *
 * @param categories ExperienceCategory[]
 * @param localPreCustomer LocalPreCustomer
 * @returns boolean
 */
export const localSubmissionExists = (
  categories: ExperienceCategory[],
  localPreCustomer: LocalPreCustomer
): boolean => {
  const twoWeeksBeforeNow = sub(new Date(), { weeks: 2 });

  const submissionExists =
    isEqual((localPreCustomer.previousCategoriesForSubmission ?? []).sort(), categories.sort()) &&
    !!localPreCustomer.recentSubmissionDate &&
    twoWeeksBeforeNow <= new Date(localPreCustomer.recentSubmissionDate);

  return submissionExists;
};
