import alarm from 'assets/svg/dashboard/home/alarm-icon.svg';
import { useGetAllUnreadNotifications } from 'client/dist/generated/alloy';

export default function NotificationNavLink() {
  const { data: notifications = [] } = useGetAllUnreadNotifications();
  const isUnread = notifications.length > 0;
  return (
    <div className='notification-link'>
      <img src={alarm} alt='notifications' className='notification' />
      {isUnread && <span className='notification-dot' />}
    </div>
  );
}
