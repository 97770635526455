import { useState } from 'react';
import { Drawer, Form, Loader } from 'rsuite';

import closeIcon from 'assets/svg/btn-close.svg';

import { EmailUs } from 'components/core/Buttons';
import { TextField } from 'components/core/fields/TextField';

import PasswordFieldsGroup from 'components/core/fields/PasswordFieldsGroup';

import { showSuccessNotification, showErrorNotification } from 'components/core/Notification';

import { updatePassword } from 'lib/core/awsAuth';
import { sendExceptionToSentry } from 'lib/tracking/sentry';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

type PasswordForm = {
  currentPassword: string;
  newPassword: string;
  checksSucceeded: boolean;
};

const INIT_PASSWORD_FORM: PasswordForm = {
  currentPassword: '',
  newPassword: '',
  checksSucceeded: false,
};

export default function PasswordDrawer({ open, setOpen }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const [passwordForm, setPasswordForm] = useState<PasswordForm>(INIT_PASSWORD_FORM);

  const onSave = async () => {
    try {
      setLoading(true);

      const { currentPassword, newPassword } = passwordForm;

      await updatePassword(currentPassword, newPassword);

      setPasswordForm(INIT_PASSWORD_FORM);

      showSuccessNotification('Successfully updated your password');

      setLoading(false);
      setOpen(false);
    } catch (error) {
      showErrorNotification((error as Error).message);

      sendExceptionToSentry(error as Error);
      setLoading(false);
    }
  };

  const onClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  return (
    <Drawer open={open} size='sm' className='alloy-drawer password-drawer' onClose={onClose}>
      <Drawer.Body>
        <div className='drawer-header'>
          <p className='header-title'>Change Password</p>

          <button onClick={onClose} className='header-btn-close'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>
        </div>

        {loading ? (
          <Loader center size='lg' />
        ) : (
          <>
            <div className='drawer-body'>
              <Form>
                <div className='password-form-wrapper'>
                  <TextField
                    name='currentPassword'
                    type='password'
                    label='Current Password'
                    value={passwordForm.currentPassword}
                    onChange={(value) =>
                      setPasswordForm({ ...passwordForm, currentPassword: value })
                    }
                  />

                  <PasswordFieldsGroup
                    useConfirm
                    onChange={(checksSucceeded, newPassword) =>
                      setPasswordForm({
                        ...passwordForm,
                        checksSucceeded,
                        newPassword,
                      })
                    }
                    passwordReset
                    hideVisibleAndPlaceholder
                  />
                </div>
              </Form>

              <div className='profile-help-block'>
                <p className='help-title'>Need Help?</p>
                <p className='help-text'>
                  To make changes to your email contact us.{' '}
                  <EmailUs btnClass='primary-link-button' />
                </p>
              </div>
            </div>

            <div className='drawer-footer'>
              <button
                onClick={onSave}
                className='btn-save'
                disabled={
                  !passwordForm.currentPassword.trim() ||
                  !passwordForm.newPassword.trim() ||
                  !passwordForm.checksSucceeded
                }
              >
                Save
              </button>
            </div>
          </>
        )}
      </Drawer.Body>
    </Drawer>
  );
}
