var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Axios from "axios";
import { Auth } from "aws-amplify";
const getAccessToken = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const session = yield Auth.currentSession();
        return {
            accessToken: session.getAccessToken().getJwtToken(),
            identityToken: session.getIdToken().getJwtToken(),
        };
    }
    catch (e) {
        return undefined;
    }
});
export const AXIOS_INSTANCE = Axios.create({
    baseURL: process.env.REACT_APP_PAYMENT_API_HOST || process.env.PAYMENT_API_HOST,
});
AXIOS_INSTANCE.interceptors.request.use((config) => __awaiter(void 0, void 0, void 0, function* () {
    const tok = yield getAccessToken();
    if (!!tok) {
        config.headers["Authorization"] = JSON.stringify(tok);
    }
    return config;
}));
// add a second `options` argument here if you want to pass extra options to each generated query
export const customInstance = (config) => {
    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE(Object.assign(Object.assign({}, config), { cancelToken: source.token })).then((r) => {
        if (r === undefined || r === null) {
            throw new Error("Network error");
        }
        return r.data;
    });
    // @ts-ignore
    promise.cancel = () => {
        source.cancel("Query was cancelled");
    };
    return promise;
};
