import getIntakesByCategory from 'common/dist/intake/getIntakesByCategory';
import { ExperienceCategory } from 'common/dist/models/experience';
import { Question } from 'common/dist/models/questionnaire';
import { SharedLocation } from 'common/dist/models/shared/location';
import { IntakeCategory } from 'common/dist/questionnaire/retrieveQuestionnairesByCategory';
import { alloyFlows } from 'data/checkout-experience/flows';
import { shouldSkipCategorySelect } from 'lib/core/url';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { ExperienceFlow } from 'models/alloy/experience';
import { ExperienceValidationKey } from './validation';
import { store } from 'store';
import { RootState } from 'reducers';
import { PREFERENCE_KNOWN, PREFERENCE_UNKNOWN } from 'models/components/experience/preferences';

export const retrieveFlowFromUrl = (location: SharedLocation): ExperienceFlow => {
  const retrievedCategories: ExperienceCategory[] = retrieveCategoriesFromUrl(location);

  return getBuiltFlowFromCategories(retrievedCategories);
};

/**
 * this function builds a 'flow' based on categories selected:
 * - TODO: questionnaire will include a combo of all categories this is
 * - steps are the pages for the flow, we need to get the flow that has the most amount steps
 * - products are all the flows products from categories combined for cart
 */
export const getBuiltFlowFromCategories = (categories: ExperienceCategory[]): ExperienceFlow => {
  const state = store.getState() as RootState;
  const { productPreferences } = state.experience.localPreCustomer;
  const { showKnownPreferencesTesting } = state.abTesting;

  let questions: Question[] | undefined;

  // Filter categories (keys) to get all of the ones from the param 'categories'
  let filteredFlowCategories = Object.keys(alloyFlows).filter((key) =>
    categories.includes(key as ExperienceCategory)
  );

  // If no categories found by categories passed, default to mht
  if (filteredFlowCategories.length === 0) {
    filteredFlowCategories = ['mht'];
  }

  // Find the best category in terms of the one having the most steps compared to our filtered categories
  // If a customer wants mht and skin-health categories then we need to use mht steps since it would be
  // our default as it has the most steps out of the two categories
  const bestCategory = filteredFlowCategories.reduce((categoryA, categoryB) => {
    return alloyFlows[categoryA].steps.length > alloyFlows[categoryB].steps.length
      ? categoryA
      : categoryB;
  });

  if (!categories.every((c) => c === 'gut-health')) {
    questions = getIntakesByCategory(categories as IntakeCategory[]);
  }

  // MARK: For any tests || filtering of steps, handle them below here after steps init

  // The steps the customer has to go through based on selection
  let steps = alloyFlows[bestCategory].steps;

  // Handle filtering out relief type / category select if present in url
  const skipCategorySelect = shouldSkipCategorySelect(window.location);

  if (skipCategorySelect) {
    steps = steps.filter((s) => s.validationKey !== ExperienceValidationKey.reliefType);
  }

  // "Known Preferences" CRO Test | If the user is in the control group, remove the 'known-preferences' and
  // 'treatment-interests' steps from the flow, since they are not needed
  if (!showKnownPreferencesTesting) {
    steps = steps.filter((s) => s.path !== 'known-preferences' && s.path !== 'treatment-interests');
  }

  // "Known Preferences" CRO Test | If the user is in the test group and DOES know what they want,
  // remove the 'treatment-interests' step. Otherwise, skip the normal preferences steps.
  const knownSteps = steps.filter((s) => s.path !== 'treatment-interests');
  const unknownSteps = steps.filter(
    (s) => s.path !== 'add-on-preferences' && s.path !== 'mht-preferences'
  );

  if (showKnownPreferencesTesting) {
    steps = productPreferences === PREFERENCE_KNOWN ? knownSteps : unknownSteps;
  }

  // All the product ids to be shown in cart at checkout
  // If mht (consult) is included in the flows then we just need the dr consult product in cart otherwise get all the products in there!
  const productIds = filteredFlowCategories.includes('mht')
    ? alloyFlows['mht'].productIds
    : filteredFlowCategories.flatMap((category) => alloyFlows[category].productIds);

  return {
    intakeQuestions: questions,
    steps,
    productIds,
  };
};
