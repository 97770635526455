import {
  IntakeCategory,
  ProductFrequencyRenewal,
  SubscriptionWithRenewal,
  getAllSubscriptionsForCustomer,
  getRecentSubmission,
  runSuggestions,
  TreatmentPlan,
  PendingSwitch,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { ExperienceCategory } from 'common/dist/models/experience';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import _, { uniqBy } from 'lodash';
import { NewUpsell } from 'models/alloy/new-upsell';
import { store } from 'store';
import { getBundledUpdatedCategory } from '../category';
import { getIntakeCategories } from '../experience';
import { DeepProduct } from 'common/dist/products/productFrequency';
import { getSubscriptionsWithStatus } from '../subscriptions/status';
import { isProductInSwitchable } from './switch';

export type ProductCleanNameDosage = {
  cleanName: string;
  dosage?: string;
};

/**
 * TODO tests
 *
 * Get new upsell products grouped by category to show
 * depending on purchased products and not purchased products.
 *
 * @param purchasedProducts
 * @param notPurchasedProducts
 * @param upsellProducts
 * @returns an NewUpsell array, each one of them including the display name of the category,
 * the category union type string and the products for that category to show
 */
export const getNewUpsellContentfulProducts = async (
  purchasedProducts: DeepProduct[],
  notPurchasedProducts: DeepProduct[],
  upsellProducts: DeepProduct[]
): Promise<NewUpsell[]> => {
  const groupedAllUpsellProducts = await ProductRegistry.get().getNewUpsellContentfulProducts(
    purchasedProducts,
    notPurchasedProducts,
    upsellProducts
  );

  const groupedByCategory = _.groupBy(groupedAllUpsellProducts, ({ alloyProduct }) =>
    _.uniq([
      ...alloyProduct.parent.map((p) => p.category),
      ...(alloyProduct.child && alloyProduct.child.length
        ? alloyProduct.child.map((c) => c.category)
        : []),
    ]).toString()
  );

  const groupedCategoryContentfulProduct = Object.keys(groupedByCategory).map((key) => ({
    category: key,
    products: groupedByCategory[key],
  }));

  // get bundled category, so sexual and vaginal health get in the same bundled category
  const groupedBundledCategory = getBundledUpdatedCategory(groupedCategoryContentfulProduct);

  return groupedBundledCategory;
};

/**
 * get the price for all products combined, this is mainly used to confirm that pill+prog are correctly combined in terms of price
 * and then returned.
 *
 * @param groupedProduct GroupedContentfulProduct
 * @returns number
 */
export const getPriceForCombinedProducts = (groupedProduct: GroupedContentfulProduct): number => {
  const { alloyProduct } = groupedProduct;
  const allProducts = [...alloyProduct.parent, ...(alloyProduct.child ?? [])];

  return allProducts.reduce((a, p) => a + p.priceInCents, 0);
};

/**
 * Helper function for checking if active subscriptions exist,
 * and if so removing them from retrieval.
 *
 * TODO: convert to cache use
 *
 * @returns DeepProduct[] containing deep product for all active subscriptions
 */
export const retrieveActiveSubProducts = async (): Promise<DeepProduct[]> => {
  const { isAuthenticated } = store.getState().alloy;
  if (isAuthenticated) {
    const subscriptions = await getAllSubscriptionsForCustomer();
    const activeSubscriptions = getSubscriptionsWithStatus(subscriptions).activeSubs;
    const activeSubProducts = activeSubscriptions.flatMap((sub) =>
      sub.products.map((p) => p.product)
    );
    return activeSubProducts;
  }
  return [];
};

/**
 *
 * @param products DeepProduct[]
 * @param categories ExperienceCategory[]
 * @param submissionExists boolean
 * @returns GroupedContentfulProduct[]
 */
export const retrieveGroupedContentfulProductsFrom = async (
  products: DeepProduct[],
  categories: ExperienceCategory[],
  submissionExists: boolean
): Promise<GroupedContentfulProduct[][]> => {
  const { isAuthenticated } = store.getState().alloy;

  const intakeCategories = getIntakeCategories(categories);

  // In order to handle some products being filtered against in the skin cart at least at the moment,
  // we need to find submission and run suggestions and then get those qualified products to build cart
  let qualifiedProducts: DeepProduct[] = [];
  let disqualifiedProducts: DeepProduct[] = [];
  let bundableProducts: DeepProduct[] = [];

  if (isAuthenticated && !intakeCategories.includes('mht')) {
    if (submissionExists) {
      const recentSubmission = await getRecentSubmission({ categories: intakeCategories });

      if (recentSubmission) {
        const suggestions = await runSuggestions({ submissionId: recentSubmission.id!! });

        qualifiedProducts = suggestions.qualified.map((q) => q.product);
        disqualifiedProducts = suggestions.disqualified.map((dq) => dq.product);
      }

      for (const p of products) {
        if (await ProductRegistry.get().canBeBundled(p.productId)) {
          bundableProducts.push(p);
        }
      }
    }
  }

  // filter out the parent/child from original products and replace with our suggestions
  // ie m4 and tret lowest dosage filter out and put in m4 and tret highest dosage if qualified!
  const activeSubProducts = await retrieveActiveSubProducts();
  const productsToFetch = products
    .filter(
      (pf) =>
        !disqualifiedProducts.some((dp) => ProductRegistry.get().areProductsEquivalent([dp, pf])) &&
        !bundableProducts.some((bp) => ProductRegistry.get().areProductsEquivalent([bp, pf]))
    )
    .concat(qualifiedProducts)
    .filter(
      (pf) =>
        !activeSubProducts.some((asp) => ProductRegistry.get().areProductsEquivalent([asp, pf]))
    );

  const fetchedProducts = await ProductRegistry.get().getRecurringProductsForV2(productsToFetch);

  return fetchedProducts;
};

export const getReadablePrice = (priceInCents: number): number => {
  return priceInCents / 100;
};

/**
 * Retrieves all of the pf ids from a grouped contentful product and returns them flattened
 *
 * @param gcp GroupedContentfulProduct
 * @returns number[]
 */
export const getDeepProductIdsFrom = (gcp: GroupedContentfulProduct): number[] => {
  return [
    ...gcp.alloyProduct.parent.map((pf) => pf.id),
    ...(gcp.alloyProduct.child ?? []).map((pf) => pf.id),
  ];
};

/**
 * Given a list of GroupedContentfulProducts, it maps the list
 * and extract all the product frequencies from it,
 * returning them as an array.
 *
 * @param gcps GroupedContentfulProduct[]
 * @returns DeepProduct[]
 */
export const getDeepProductsFromGroupedProducts = (
  gcps: GroupedContentfulProduct[]
): DeepProduct[] => {
  return uniqBy(
    gcps.flatMap((gcp) => [...gcp.alloyProduct.parent, ...(gcp.alloyProduct.child ?? [])]),
    'id'
  );
};

/**
 * Given a specific product and a list of GroupedContentfulProducts,
 * it returns a product from the list where the specific product
 * can be bundled with
 *
 * @param bundledProduct GroupedContentfulProduct
 * @param products GroupedContentfulProduct[][]
 * @returns GroupedContentfulProduct
 */
export const getProductToBeBundledWith = (
  bundledProduct: GroupedContentfulProduct,
  products: GroupedContentfulProduct[][]
): GroupedContentfulProduct | undefined => {
  if (!bundledProduct.contentfulProduct.fields.bundledPrice) {
    return;
  }

  const bundledProducts =
    products.find((gcpList) =>
      gcpList.some(
        (gcp) => gcp.contentfulProduct.sys.id === bundledProduct.contentfulProduct.sys.id
      )
    ) || [];

  if (bundledProducts.length) {
    return bundledProducts.find(
      (gcp) =>
        gcp.contentfulProduct.sys.id !== bundledProduct.contentfulProduct.sys.id &&
        !gcp.contentfulProduct.fields.bundledPrice
    );
  }
};

/**
 * Given a list of GroupedContentfulProducts and a list of categories,
 * this function sorts every product in its own group according to the
 * order of categories in the categories list.
 *
 * @param products GroupedContentfulProduct[][]
 * @param categories IntakeCategory[]
 * @returns GroupedContentfulProduct
 */
export const sortGroupedProductsByCategories = (
  products: GroupedContentfulProduct[][],
  categories: IntakeCategory[]
): GroupedContentfulProduct[][] => {
  return products.map((gcpList) =>
    gcpList.sort((a, b) => {
      const aCategory = a.alloyProduct.parent[0].category;
      const bCategory = b.alloyProduct.parent[0].category;
      return (
        categories.findIndex((category) => category === aCategory) -
        categories.findIndex((category) => category === bCategory)
      );
    })
  );
};

/**
 * given a product, check if the same "parent" product is present
 * in the grouped contentful product.
 * for example: if I'm in the middle of a switch from estradiol patch -> pill,
 * this method identifies if estradiol is found on both products
 *
 * todo: add tests
 *
 * @param gcp
 * @param requested
 */
export const isSameParentProduct = (
  gcp: GroupedContentfulProduct,
  requested: GroupedContentfulProduct | undefined
): boolean => {
  return getDeepProductsFromGroupedProducts([gcp]).some((dp) =>
    [...(requested?.alloyProduct.parent ?? []), ...(requested?.alloyProduct.child ?? [])]
      .map((p) => p.productId)
      .includes(dp.productId)
  );
};

/**
 * Given a grouped contentful product (tretinoin for example),
 * we try to find the other matching bundle part in the subscription
 * products, if we find it, we return, otherwise just return undefined
 * (indicating that is not present)
 *
 * todo: add tests
 *
 * @param products
 * @param subProducts
 * @returns
 */
export const getBundledProductFrom = async (
  subProducts: DeepProduct[],
  product: GroupedContentfulProduct
): Promise<GroupedContentfulProduct | undefined> => {
  const [deepProduct] = getDeepProductsFromGroupedProducts([product]);

  const activeSubProducts = await ProductRegistry.get().getRecurringProductsForV2([
    deepProduct,
    ...subProducts,
  ]);

  const missingBundleProductPresent = getProductToBeBundledWith(product, activeSubProducts);

  return missingBundleProductPresent;
};

/**
 * given two grouped contentful products, this method make sure
 * that products pending switch are sorted first in the array
 *
 * @param a
 * @param b
 * @param switchableProducts
 * @param requestedProduct
 * @returns
 */
export const sortWithSwitchingFirst = (
  a: GroupedContentfulProduct,
  b: GroupedContentfulProduct,
  switchableProducts: DeepProduct[],
  requestedProduct: GroupedContentfulProduct | undefined
) => {
  // put products pending switch first and then the others
  const foundProductInSwitchableProductsForA = isProductInSwitchable(a, switchableProducts);
  const foundProductInSwitchProcessForA = isSameParentProduct(a, requestedProduct);

  const foundProductInSwitchableProductsForB = isProductInSwitchable(b, switchableProducts);
  const foundProductInSwitchProcessForB = isSameParentProduct(b, requestedProduct);

  const shouldShowSwitchFirstA =
    foundProductInSwitchableProductsForA && foundProductInSwitchProcessForA;
  const shouldShowSwitchFirstB =
    foundProductInSwitchableProductsForB && foundProductInSwitchProcessForB;

  if (shouldShowSwitchFirstA && !shouldShowSwitchFirstB) return -1;
  if (!shouldShowSwitchFirstA && shouldShowSwitchFirstB) return 1;

  return 0;
};

/**
 * given an active product, returns if it was found
 * a subscription, prescription, in the switchable, etc.
 *
 * this method returns the various states a product can be in
 * the treatment plan.
 *
 * @param gcp
 * @param activeSubscriptions
 * @param switchableProducts
 * @param requestedProduct
 * @param treatmentPlan
 * @param pendingSwitch
 * @returns
 */
export const getActiveProductStates = (
  gcp: GroupedContentfulProduct,
  activeSubscriptions: SubscriptionWithRenewal[],
  switchableProducts: DeepProduct[],
  requestedProduct: GroupedContentfulProduct | undefined,
  treatmentPlan: TreatmentPlan | undefined,
  pendingSwitch: PendingSwitch | undefined
) => {
  // since these are purchased products and they can either be in a subscription or one time,
  // we need to determine which ones they are
  const foundSubscription = activeSubscriptions.find((sub) =>
    gcp.alloyProduct.parent.some((dp) =>
      sub.products
        .map((p) => p.product)
        .find((i) => ProductRegistry.get().areProductsEquivalent([dp, i]))
    )
  );

  // product in the subscription, just used for renewal and prescription information
  const foundProductInSubscription = foundSubscription
    ? foundSubscription.products.find((p) =>
        gcp.alloyProduct.parent.find((i) =>
          ProductRegistry.get().areProductsEquivalent([p.product, i])
        )
      )
    : undefined;

  // for one time products, we just need the prescription product for renewal and prescription information
  const foundPrescriptionProduct = treatmentPlan!.prescriptionProducts.find((pp) =>
    gcp.alloyProduct.parent.map((pf) => pf.id).includes(pp.product.id)
  );

  const foundProductInSwitchableProducts = isProductInSwitchable(gcp, switchableProducts);
  const foundProductInSwitchProcess = isSameParentProduct(gcp, requestedProduct);

  // potential in switch in progress
  const foundSwitch =
    (pendingSwitch?.isSwitchFormFactor || pendingSwitch?.isSwitchDose) &&
    foundProductInSwitchableProducts &&
    foundProductInSwitchProcess;

  const states = {
    foundSubscription,
    foundProductInSubscription,
    foundPrescriptionProduct,
    foundProductInSwitchableProducts,
    foundSwitch,
  };

  return states;
};

/**
 * Given a product to be requested as addon, verify if any product from same bundle as it
 * is currently available to be requested to. (no subscriptions or prescriptions for all products from bundle)
 * If they are, then return all products from bundle to be added.
 * If not, only return current requesting product.
 *
 * @param product GroupedContentfulProduct
 * @param activeSubProducts GroupedContentfulProduct[][]
 * @param activeSubscriptions SubscriptionWithRenewal[]
 * @param nonSubscriptionProducts ProductFrequencyRenewal[]
 * @returns GroupedContentfulProduct[]
 */
export const getAddonRequestProducts = (
  product: GroupedContentfulProduct,
  activeSubProducts: GroupedContentfulProduct[][],
  activeSubscriptions: SubscriptionWithRenewal[],
  nonSubscriptionProducts: ProductFrequencyRenewal[]
) => {
  // moving this to handle either all products in a bundle if no product from bundle in any subs or prescriptions
  const allProductsFromBundle =
    activeSubProducts.find((gcpList) =>
      gcpList.some((gcp) => gcp.contentfulProduct.sys.id === product.contentfulProduct.sys.id)
    ) ?? [];
  const allProducts = getDeepProductsFromGroupedProducts(allProductsFromBundle);

  const noProductsOnSubsOrPrescriptions =
    activeSubscriptions.every((sub) =>
      sub.products.every(
        (pfr) => !allProducts.map((pf) => pf.productId).includes(pfr.product.productId)
      )
    ) &&
    nonSubscriptionProducts.every(
      (pfr) => !allProducts.map((pf) => pf.productId).includes(pfr.product.productId)
    );

  const requestingProducts = noProductsOnSubsOrPrescriptions ? allProductsFromBundle : [product];

  return requestingProducts;
};

export const filterProductsByCategories = (
  products: GroupedContentfulProduct[][],
  categories: ExperienceCategory[]
) => {
  return products.map((gcpList) =>
    gcpList.filter((gcp) =>
      gcp.contentfulProduct.fields.categories.some((c) => categories.includes(c))
    )
  );
};

/**
 * Given a list of deep products and a grouped contentful product,
 * this functions returns true if the gcp parent is equal to any
 * of the products in the list.
 *
 * This is useful for cases where we need to verify if a gcp is selected
 * in a component that lists products.
 *
 * @param products
 * @param gcp
 * @returns boolean
 */
export const isGroupedProductInProductsList = (
  products: DeepProduct[],
  gcp: GroupedContentfulProduct
): boolean => {
  return products.some((product) =>
    gcp.alloyProduct.parent.every((parent) =>
      ProductRegistry.get().areProductsEquivalent([parent, product])
    )
  );
};

/**
 * for when we have a switchable grouped product (currently only m4 and tretinoin)
 * we need to show them separately in the /treatment-plan and then only tretinoin
 * will show the option for switch dosage ("request a change")
 *
 */
export const getSeparatedGroupedContentfulProducts = async (
  deepProducts: DeepProduct[],
  switchableProducts: DeepProduct[]
): Promise<GroupedContentfulProduct[]> => {
  const groupedSwitchableBundleProducts: DeepProduct[] = [];
  const individualSwitchableBundleProducts: DeepProduct[] = [];

  const productsList = await Promise.all(
    deepProducts.map(async (dp) => {
      const inSwitchable = switchableProducts.map((sp) => sp.id).includes(dp.id);
      const isBundleProduct = await ProductRegistry.get().canBeBundled(dp.productId);

      return { dp, inSwitchable, isBundleProduct };
    })
  );

  productsList.forEach((plf) => {
    if (plf.inSwitchable && plf.isBundleProduct && plf.dp.category !== 'mht') {
      individualSwitchableBundleProducts.push(plf.dp);
    } else {
      groupedSwitchableBundleProducts.push(plf.dp);
    }
  });

  return [
    ...(
      await ProductRegistry.get().getRecurringProductsForV2(groupedSwitchableBundleProducts)
    ).flat(),
    ...(
      await Promise.all(
        individualSwitchableBundleProducts.map((dp) =>
          ProductRegistry.get().getRecurringProductsForV2([dp])
        )
      )
    ).flat(2),
  ];
};
