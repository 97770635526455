import { Dispatch } from '@reduxjs/toolkit';

import { REQUESTING_PRODUCTS } from 'actions/types';
import { getItem, setItem } from 'client/dist/localstorage';

const get = () => {
  return (dispatch: Dispatch) => {
    const requestingDeepProductIds = getItem('requestingDeepProductIds', true);

    dispatch({
      type: REQUESTING_PRODUCTS,
      payload: requestingDeepProductIds,
    });
  };
};

const update = (deepProductIds: number[]) => {
  return (dispatch: Dispatch) => {
    setItem('requestingDeepProductIds', deepProductIds, true);

    dispatch({
      type: REQUESTING_PRODUCTS,
      payload: deepProductIds,
    });
  };
};

export { get as getRequestingProducts, update as updateRequestingProducts };
