import DiscountBlock from '../submit/summary/DiscountBlock';
import TotalsBlock from '../submit/summary/TotalsBlock';

interface Props {
  onPlaceOrder: () => void;
}

export default function Summary({ onPlaceOrder }: Props) {
  return (
    <div className='tp-checkout-summary-wrapper'>
      <p className='checkout-summary-title'>Order summary</p>

      <DiscountBlock />
      <TotalsBlock onPlaceOrder={onPlaceOrder} showOrderButton />
    </div>
  );
}
