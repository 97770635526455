import { Amplify } from 'aws-amplify';
import { AXIOS_INSTANCE } from 'client/dist/authenticatingAxiosInstance';
import dates from 'lib/core/dates';
import error from 'lib/core/error';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import App from './App';
import './index.css';
import { store } from './store';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_SRP_AUTH',
  },
  Analytics: {
    disabled: true,
  },
});

// load axios interceptors to call dates handler on successful response and the error handler on rejection
AXIOS_INSTANCE.interceptors.response.use((res) => {
  const updatedData = dates.datesHandler(res?.data);
  return { ...res, data: updatedData };
}, error.errorHandler);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <Provider store={store}>
    <Zendesk
      zendeskKey='d936964e-abff-4d39-9d47-621e55036007'
      onLoaded={() => {
        ZendeskAPI('messenger', 'hide'); // messenger is what they call it ig :shrug:
      }}
    />

    <App />
  </Provider>
);
