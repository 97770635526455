import { useDispatch } from 'react-redux';

import { bindActionCreators } from '@reduxjs/toolkit';

import { logout } from 'actions/auth/logout_actions';

import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'reducers/alloy_reducer';

export default function AccountWrapper() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customer = useAppSelector((state) => state.alloy.customer!!);

  const dispatchLogout = bindActionCreators(logout, dispatch);

  const onLogout = async () => await dispatchLogout(navigate);

  return (
    <div className='ce-account-wrapper'>
      <div className='ce-block-header'>
        <p className='header-title'>Account</p>
      </div>

      <div className='ce-account-block'>
        <p className='account-text'>{customer.email}</p>

        <button className='primary-link-button' onClick={onLogout}>
          Log out
        </button>
      </div>
    </div>
  );
}
