import { ExperienceCategory } from 'common/dist/models/experience';

export const PREFERENCE_KNOWN = 'known';
export const PREFERENCE_UNKNOWN = 'unknown';

export type PreferenceKnowledgeType = 'known' | 'unknown';

export type PreferenceKnowledgeOption = {
  title: string;
  text: string;
  id: PreferenceKnowledgeType;
};

export type SelectableCategory = ExperienceCategory | 'none';
export type ProductPreference = PreferenceKnowledgeType | undefined;
