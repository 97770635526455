import { EventType, SerializableNotification } from 'client/dist/generated/alloy';
import { processNotifications } from '../notifications/transformNotifs';
import NotificationCardBlock from 'components/dashboard/home/shared/NotificationCard';
import clipBoardIcon from 'assets/svg/dashboard/home/clipboard-icon.svg';
import messageIcon from 'assets/svg/dashboard/home/message-icon.svg';
import { Link } from 'react-router-dom';
import { AccountStatus } from 'lib/core/customer/getAccountStatus';
import CardStatusWrapper from 'components/dashboard/home/wrappers/CardStatusWrapper';

const getHugText = (eventType: EventType) => {
  switch (eventType) {
    case 'TREATMENT_PLAN_UPDATED':
      return 'new treatments';
    case 'NEW_DOCTOR_MESSAGE':
      return 'new message';
    default:
      return '';
  }
};

const getIcon = (eventType: EventType) => {
  switch (eventType) {
    case 'TREATMENT_PLAN_UPDATED':
      return clipBoardIcon;
    case 'NEW_DOCTOR_MESSAGE':
      return messageIcon;
    default:
      return '';
  }
};

const getAction = (eventType: EventType) => {
  switch (eventType) {
    case 'TREATMENT_PLAN_UPDATED':
      return (
        <Link to='/treatment-plan' className='secondary-border-button mt-3'>
          View your treatment plan
        </Link>
      );
    case 'NEW_DOCTOR_MESSAGE':
      return (
        <Link to='/messages' className='primary-button mt-3'>
          View messages
        </Link>
      );
    default:
      return <></>;
  }
};

export const getBuiltNotificationCards = (
  notifications: SerializableNotification[],
  markRead: (e: EventType) => void
) => {
  const transformedNotifications = processNotifications(notifications, false);

  return transformedNotifications
    .sort((a, b) => a.eventType.localeCompare(b.eventType))
    .map(({ title, message, eventType }) => (
      <NotificationCardBlock
        hugText={getHugText(eventType)}
        isImportant
        title={title}
        description={message}
        icon={getIcon(eventType)}
        isClosable
        onClose={() => markRead(eventType)}
      >
        {getAction(eventType)}
      </NotificationCardBlock>
    ));
};

/**
 * currently we want to prioritize displaying the customer approval card over any notifications,
 * this allows to sort that, for future if we need to prioritize other sorting/prioritization then
 * we can look at expanding on this
 *
 * @param notificationCards JSX.Element[] (from above)
 * @param status AccountStatus
 * @returns JSX.Element[] (cards)
 */
export const sortCardsWithStatus = (notificationCards: JSX.Element[], status: AccountStatus) => {
  return status === 'AWAITING_CUSTOMER'
    ? [<CardStatusWrapper />].concat(...notificationCards)
    : notificationCards.concat(<CardStatusWrapper />);
};
