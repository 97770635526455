import classNames from 'classnames';
import { ReactNode } from 'react';
import { Drawer } from 'rsuite';

import closeIcon from 'assets/svg/btn-close.svg';
import logo from 'assets/svg/core/logos/logo.svg';

interface Props {
  title: string;
  drawerClass?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  disableClosing?: boolean;
  children: ReactNode;
}

export default function AlloyDrawer({
  title,
  drawerClass,
  open,
  setOpen,
  disableClosing = false,
  children,
}: Props) {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      size='sm'
      className={classNames('alloy-drawer', drawerClass)}
      onClose={!disableClosing ? onClose : undefined}
    >
      <Drawer.Body>
        <div className='drawer-header'>
          <p className='header-title'>{title}</p>

          <img className='header-logo' src={logo} alt='alloy logo' />

          <button onClick={onClose} className='header-btn-close'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>
        </div>

        {/* drawer-body and drawer-footer go here */}
        {children}
      </Drawer.Body>
    </Drawer>
  );
}
