import { Skeleton } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { getMdiWidget, useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateMdiWidget } from 'actions/core/customer_actions';

import Loader from 'components/core/Loader';
import { showErrorNotification } from 'components/core/Notification';
import EmptyState from 'components/dashboard/EmptyState';
import TitleTextWrapper from 'components/dashboard/shared/wrappers/TitleTextWrapper';

import DashboardContainer from 'containers/dashboard/DashboardContainer';

import { isOtcOnlyCustomer } from 'lib/core/customer/isOtcOnlyCustomer';
import { sendExceptionToSentry } from 'lib/tracking/sentry';

import useMarkNotificationsAsRead from 'hooks/shared/useMarkNotificationsAsRead';

import { useAppSelector } from 'reducers/alloy_reducer';

export default function Messages() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['NEW_DOCTOR_MESSAGE']);

  const dispatch = useDispatch();

  const [isLoadingWidget, setIsLoadingWidget] = useState(true);
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [isLoadingValidation, setIsLoadingValidation] = useState(true);
  const [isError, setIsError] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const customer = useAppSelector((state) => state.alloy.customer!);
  const mdiWidget = useAppSelector((state) => state.alloy.mdiWidget);

  const { data: subscriptions = [], isLoading: isLoadingSubs } =
    useGetAllSubscriptionsForCustomer();

  const dispatchUpdateMdiWidget = bindActionCreators(updateMdiWidget, dispatch);

  const isLoading = isLoadingSubs || isLoadingWidget;

  useEffect(() => {
    const fetchWidget = async () => {
      try {
        const widget = await getMdiWidget();

        dispatchUpdateMdiWidget(widget);
        setIsLoadingWidget(false);
      } catch (e) {
        dispatchUpdateMdiWidget({ status: 'PENDING' });
        setIsLoadingWidget(false);
      }
    };

    fetchWidget();
  }, []);

  useEffect(() => {
    if (isLoadingIframe) {
      return;
    }

    window.addEventListener('message', validateIframe);
    return () => window.removeEventListener('message', validateIframe);
  }, [isLoadingIframe]);

  const validateIframe = (e: MessageEvent) => {
    // if mdi is not the origin, we just return since we're just checking against them
    // OR
    // return early if there's no code in the payload (pre-deploy)
    if (!e.origin.includes('mdintegrations') || !mdiWidget.code) {
      return;
    }

    const event = e.data?.event;

    // lifecycle is...
    // 1. we ask api for code
    // 2. we wait for message to come in via window
    // 3. if we receive "otp_ready" we send the otp code
    // 4. we receive "otp_success" BUT don't take action yet - because that only varifies 2FA and not widget loading
    // 5. when we receive "load" we know the widget is ready (not just the code entry screen, which we'd rather suppress)
    if (event === 'otp_ready') {
      // post the otp message payload from here
      iframeRef.current?.contentWindow?.postMessage({ message: 'otp', data: mdiWidget.code }, '*');
      setIsError(false);
    }

    if (event === 'load') {
      setIsLoadingValidation(false);
    }

    // error occurred and need to display to customer
    if (event === 'otp_failed' || mdiWidget.code?.length !== 6) {
      setIsError(true);
      const errorMessage =
        'Doctor messaging is down temporarily. Please reach out to support@myalloy.com with any questions.';

      showErrorNotification(errorMessage);
      sendExceptionToSentry(new Error(errorMessage));

      setIsLoadingValidation(false);
    }
  };

  if (isLoading)
    return (
      <DashboardContainer title='Messages | Alloy' desc='' currentPage='messages'>
        <Loader />
      </DashboardContainer>
    );

  let content = <></>;

  if (isError) {
    content = <EmptyState type='VALIDATION_ERROR' />;
  } else if (mdiWidget.status === 'ACTIVE' && mdiWidget.url) {
    content = (
      <div className='row mt-2'>
        <div className='col-12'>
          <iframe
            allow='microphone'
            className={classNames('messages-iframe', isLoadingValidation && 'hide-iframe')}
            src={mdiWidget.url}
            title='mdi'
            ref={iframeRef}
            onLoad={() => setIsLoadingIframe(false)}
          />

          {isLoadingValidation && <Skeleton variant='rectangular' height={350} />}
        </div>
      </div>
    );
  } else if (
    customer.status === 'NEW' ||
    (mdiWidget && mdiWidget.status === 'INACTIVE') ||
    isOtcOnlyCustomer(subscriptions)
  ) {
    content = <EmptyState type='NEW_CONSULT' />;
  } else if (customer.status === 'PENDING') {
    content = <EmptyState type='PENDING' />;
  } else {
    content = <></>;
  }

  return (
    <DashboardContainer title='Messages | Alloy' desc='' currentPage='messages'>
      <section className='dashboard-section'>
        <div className='container'>
          <TitleTextWrapper
            title='Messages'
            description={
              <>
                If you have questions about your symptoms, medical history, or prescription, please
                message your doctor here. Your doctor will respond within 24-48 hours during normal
                business hours. For non-medical questions (shipping, billing, refills, etc.) please
                email{' '}
                <a href='mailto:support@myalloy.com' className='anchor-link'>
                  support@myalloy.com
                </a>
                .
              </>
            }
          />

          {content}
        </div>
      </section>
    </DashboardContainer>
  );
}
