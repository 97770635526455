import { ShippingSpeed } from 'common/dist/models/shipping';

export const shippingSpeeds: ShippingSpeed[] = [
  {
    id: 1,
    shippingSpeedType: 'NEXT_SHIPMENT',
    description: 'Ship with my next',
  },
  {
    id: 2,
    shippingSpeedType: 'SHIP_NOW',
    description: 'Ship now',
  },
];
