import { LOADING_TOTALS } from 'actions/types';
import { Dispatch } from '@reduxjs/toolkit';

const updateLoadingTotals = (isLoading: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: LOADING_TOTALS, payload: isLoading });
  };
};

export { updateLoadingTotals };
