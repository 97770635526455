import {
  useGetAllSubscriptionsForCustomer,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import { getSingleSupplyProducts } from 'lib/core/customer/getSingleSupplyProducts';
import { Link } from 'react-router-dom';
import ProductCardBlock from '../shared/ProductCard';

export default function SingleSupplyCardBlock() {
  const { data: treatmentPlan } = useGetTreatmentPlan();
  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const singleSupplyProducts = getSingleSupplyProducts(subscriptions, treatmentPlan);

  if (!singleSupplyProducts) {
    return <></>;
  }

  return (
    <ProductCardBlock
      hugText='expires soon'
      isImportant
      title='Your treatment expires soon'
      description='Next steps: Get an updated mammogram & message your doctor.'
      products={singleSupplyProducts}
      maxProducts={2}
    >
      <Link to='/messages' className='primary-button mt-3'>
        Message your doctor
      </Link>
    </ProductCardBlock>
  );
}
