import classNames from 'classnames';
import { MaskedInput } from 'rsuite';

export interface Props {
  name: string;
  label?: string;
  mask: any[];
  value?: string;
  placeholder?: string;
  classes?: string;
  onChange: (value: string) => void;
  isError?: boolean;
}

export const MaskField = ({
  name,
  label,
  mask,
  placeholder,
  value,
  classes = 'field-input',
  onChange,
  isError,
}: Props) => (
  <div className={classNames('input-field-wrapper', isError && 'input-field-error')}>
    {label && <label className='field-label'>{label}</label>}

    <MaskedInput
      name={name}
      mask={mask}
      placeholder={placeholder}
      className={classes}
      defaultValue={value}
      value={value}
      onChange={onChange}
    />

    {isError && <p className='field-required-label'>Required</p>}
  </div>
);
