import classNames from 'classnames';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { ReactNode, useEffect, useState } from 'react';
import ProductRegistry from 'client/dist/product/productRegistry';

import ProductInfoDrawer from '../../core/drawers/ProductInfoDrawer';

import { isSynbiotic } from 'lib/shared/contentful';
import { getProductPrice } from 'lib/shared/product/pricing';
import { first } from 'lodash';
import ProductNameDosage from './ProductNameDosage';
import ProductPricingBlock from './ProductPricingBlock';
import { getProductNameWithDosage } from 'lib/shared/product/name';

interface Props {
  groupedProduct: GroupedContentfulProduct;
  isHighlighted?: boolean;
  onEdit?: () => void;
  children?: ReactNode;
  hideProductInformation?: boolean;
  hideDetails?: boolean;
  showDosage?: boolean;
  isParentSelected?: boolean;
  parent?: GroupedContentfulProduct;
}

export default function BundleViewableProduct({
  groupedProduct,
  isHighlighted = false,
  onEdit,
  children,
  hideProductInformation = false,
  hideDetails = false,
  showDosage = false,
  isParentSelected = false,
  parent,
}: Props) {
  const { contentfulProduct } = groupedProduct;

  const productsCleanNameAndDosage = showDosage
    ? getProductNameWithDosage(groupedProduct)
    : [{ cleanName: contentfulProduct.fields.title }];

  const [unbundledPrice, setUnbundledPrice] = useState<number>(0);
  const [bundledPrice, setBundledPrice] = useState<number>(0);
  const [showProdInfo, setShowProdInfo] = useState<boolean>(false);

  const isProductSynbiotic = isSynbiotic(contentfulProduct);
  const parentName = ProductRegistry.get().getProductFullName(
    first(parent?.alloyProduct.parent ?? [])!
  );

  useEffect(() => {
    const getPricing = async () => {
      const product = first(groupedProduct.alloyProduct.parent);

      if (product) {
        const [unbundledPriceInCents, bundledPriceInCents] = await Promise.all([
          getProductPrice(product, product.recurrenceType),
          getProductPrice(product, product.recurrenceType, true),
        ]);

        setUnbundledPrice(unbundledPriceInCents / 100);
        setBundledPrice(bundledPriceInCents / 100);
      }
    };

    getPricing();
  }, []);

  const onOpenProdInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setShowProdInfo(true);
  };

  return (
    <>
      <div className={classNames('viewable-product', isHighlighted ? 'highlighted-border' : '')}>
        {!isProductSynbiotic && <span className='product-rx-tag'>Rx</span>}

        <div className={classNames('product-header', hideDetails ? 'no-margin' : '')}>
          <div className='header-content'>
            <img
              src={contentfulProduct.fields.photo.fields.file.url}
              alt={contentfulProduct.fields.photo.fields.title}
              className='header-img'
            />

            <div className='header-text-wrapper'>
              {productsCleanNameAndDosage.map(({ cleanName, dosage }, index) => (
                <ProductNameDosage key={index} cleanName={cleanName} dosage={dosage} />
              ))}

              <ProductPricingBlock
                unbundledPrice={unbundledPrice}
                bundledPrice={bundledPrice}
                canBeDiscounted={!!parent}
                isParentSelected={isParentSelected}
                discountedParentName={parentName}
              />

              {hideDetails && !hideProductInformation && (
                <button onClick={onOpenProdInfo} className='product-info-btn-link'>
                  Product information
                </button>
              )}
            </div>
          </div>

          {onEdit && (
            <div className='header-btn-wrapper'>
              <button className='header-btn' onClick={onEdit}>
                Edit
              </button>
            </div>
          )}
        </div>

        {!hideDetails && <p className='product-text'>{contentfulProduct.fields.detail}</p>}

        {!hideDetails && !hideProductInformation && (
          <button onClick={onOpenProdInfo} className='product-info-btn-link'>
            Product information
          </button>
        )}

        <div>{children}</div>
      </div>

      <ProductInfoDrawer
        open={showProdInfo}
        setOpen={setShowProdInfo}
        product={contentfulProduct}
      />
    </>
  );
}
