import { Question } from 'common/dist/models/questionnaire';
import { RelevantProduct } from 'models/alloy/experience';
import { QuestionnaireAnswers } from 'models/components/questionnaire';
import slugify from 'react-slugify';

/**
 * HELPER FUNTIONS
 */

export const findQuestion = (questions: Question[], slug: string): Question | undefined =>
  questions.find((question) => question.slug === slug);

/**
 * Get the follow up from all options and questions. for now we only use this for the `heard about us survey`
 * since we introduced inside the `options` the control of each one of them having the `followUp` optional field
 * which is a MultiLine question with a text field to be answered.
 *
 * @param questions
 * @param slug
 * @returns
 */
export const findOptionFollowUp = (questions: Question[], slug: string) =>
  questions
    .flatMap((q) => q.options?.filter((o) => !!o.followUp).map((o) => o.followUp))
    .find((f) => f?.slug === slug);

export const findQuestionByUserKey = (
  questions: Question[],
  userKey: string
): Question | undefined => questions.find((q) => q.userKey === userKey);

/**
 * Get the filtered questions that way we do not check against empty pages / uneccesary questions
 *
 * @param questions Question[] - unfiltered questions
 * @param answers answers - the answers we want to check against
 * @returns Question[] - the filtered questions
 */
export const getFilteredQuestions = (
  questions: Question[],
  answers: QuestionnaireAnswers
): Question[] =>
  questions.filter((question) => {
    // MARK: Check if the current question is using the or logic (which will allow for it to show)
    if (question.orLogic) {
      // MARK: Loop through the array of the or logic to check against ids to
      // see if they add up with the key to our answers. If the id is in our answers, then we can show the question!
      return Object.entries(question.orLogic).some(([question_key, value]) => {
        const answer: string | string[] | undefined = answers[question_key];

        if (answer) {
          if (Array.isArray(answer)) {
            return answer.some((answer) => value && value.includes(answer));
          } else {
            return value && value.includes(answer);
          }
        }
      });
    } else {
      return question.type !== 'Hidden';
    }
  });

/**
 * Get the filtered questions inside the pages that way we do not check against empty pages / uneccesary questions
 *
 * @param pages Question[][] - all the page sections with all questions unfiltered
 * @param answers answers - the answers we want to check against when filtering for next/prev sections
 * @returns Question[][] - the filtered questions within the pages
 */
export const getFilteredPages = (
  pages: Question[][],
  answers: QuestionnaireAnswers
): Question[][] => pages.map((questions: Question[]) => getFilteredQuestions(questions, answers));

/**
 * Group the questions by either the optional groupedSection field which can be used for slider questions or any other ones
 * or keep the questions grouped separately in their own section
 *
 * @param questions Question[] - array of questions
 * @returns dictionary with array of questions per key
 */
export const groupQuestionsBySection = (questions: Question[]): { [key: string]: Question[] } => {
  const groupedQuestions: { [key: string]: Question[] } = {};

  questions.forEach((question) => {
    const section = question.groupedSection ?? question.slug;

    if (!groupedQuestions[section]) {
      groupedQuestions[section] = [];
    }

    groupedQuestions[section].push(question);
  });

  return groupedQuestions;
};

/**
 * Get the pages built out for a list of questions, this is used within the questionnaire
 *
 * @param questions Question[]
 * @param relevantProducts RelevantProduct[]
 * @returns Question[][]
 */
export const getPages = (
  questions: Question[],
  relevantProducts: RelevantProduct[] = []
): Question[][] => {
  const filteredQuestions = questions.filter((q) => q.type !== 'Hidden');

  const relevantQuestions =
    relevantProducts.length > 0
      ? filteredQuestions.filter((q) =>
          relevantProducts
            .map((rp) => rp.category)
            .some((p) => q.relevantProductCategories?.includes(p))
        )
      : filteredQuestions;

  const groupedQuestions: { [key: string]: Question[] } =
    groupQuestionsBySection(relevantQuestions);

  return Object.entries(groupedQuestions).map(([_, question]) => question) as Question[][];
};

/**
 * Get slugs from all questions provided
 *
 * @param questions Question[]
 * @param relevantProducts RelevantProduct[]
 * @returns string[]
 */
export const getQuestionSlugs = (
  questions: Question[],
  relevantProducts: RelevantProduct[] = []
): string[] => {
  const pages = getPages(questions, relevantProducts);

  return pages.map((page) => slugify(page.map((q) => q.slug).join('-')));
};
