import { UpcomingInvoice } from 'client/dist/generated/alloy';
import { TotalsInCents } from 'models/alloy/invoice';

/**
 * allows for subscriptions to see totals a lot more easily, totals are gotten in cents
 *
 * @param invoice UpcomingInvoice
 * @returns Totals
 */
export const getTotalsByInvoice = (invoice: UpcomingInvoice): TotalsInCents => {
  const { subtotal, tax, shipping, total } = invoice;

  const discount = invoice.promotionCode;
  let discountTotal = 0;

  if (!!discount) {
    if (!!discount.coupon.amount_off) {
      discountTotal = discount.coupon.amount_off;
    } else if (!!discount.coupon.percent_off) {
      discountTotal = subtotal * (discount.coupon.percent_off / 100);
    }
  }

  return {
    subtotal: subtotal,
    tax: tax,
    shipping: shipping,
    discount: discountTotal,
    total: total,
  };
};
