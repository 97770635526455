import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';

import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';
import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';

import { ExperienceComponentModel } from 'models/alloy/experience';
import { knownPreferenceSelections } from 'data/checkout-experience/content/treatment-interests';
import { updateLocalPreCustomer } from 'actions/core/app_actions';
import { SelectFieldsGroup } from 'components/core/fields/SelectFieldsGroup';
import { Form } from 'rsuite';
import { fadeQuestions } from 'lib/shared/fadeAndScroll';
import { useAppSelector } from 'reducers/alloy_reducer';
import { ProductPreference } from 'models/components/experience/preferences';

export default function KnownPreferences({ onNext, onBack }: ExperienceComponentModel) {
  const dispatch = useDispatch();
  const location = useLocation();

  const dispatchUpdateLocalPreCustomer = bindActionCreators(updateLocalPreCustomer, dispatch);
  const {
    localPreCustomer: { productPreferences },
  } = useAppSelector((state) => state.experience);

  const [selectedId, setSelectedId] = useState<ProductPreference | undefined>(productPreferences);
  const selectionWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fadeQuestions('fade-in-wrapper', selectionWrapper);
  }, []);

  const onChange = (ids: string[]) => {
    if (!ids.length) {
      setSelectedId(undefined);
      dispatchUpdateLocalPreCustomer({
        productPreferences: undefined,
      });
      return;
    }
    const singleId: string = ids[ids.length - 1];
    setSelectedId(singleId as ProductPreference);
    fadeQuestions('fade-out-wrapper', selectionWrapper);

    dispatchUpdateLocalPreCustomer({
      productPreferences: singleId as ProductPreference,
    });

    setTimeout(() => onNext(), 400);
  };

  const onSubmit = () => {
    dispatchUpdateLocalPreCustomer({
      productPreferences: selectedId,
    });
    onNext();
  };

  return (
    <Layout title='Known Preferences - Alloy' desc='' noBars>
      <TopBannerWithProgress percentComplete={getPercentComplete(location)} onBack={onBack} />

      <div className='questionnaire-wrapper' ref={selectionWrapper}>
        <CheckoutExperienceSection>
          <h1 className='content-title'>
            Do you have any specific treatments you are interested in?
          </h1>
          <Form>
            <SelectFieldsGroup
              selectedIds={[selectedId] as string[]}
              onChange={onChange}
              options={knownPreferenceSelections}
              isSingleSelect
            />
          </Form>
        </CheckoutExperienceSection>
      </div>

      <BottomBar>
        <button
          className='primary-button'
          type='submit'
          disabled={selectedId === undefined}
          onClick={onSubmit}
        >
          Next
        </button>
      </BottomBar>
    </Layout>
  );
}
