interface Props {
  hugText: string;
  content: JSX.Element;
}

export default function StatsBlock({ hugText, content }: Props) {
  return (
    <div className='stats-block'>
      <p className='stats-hug-text'>{hugText}</p>
      <p className='stats-text'>{content}</p>
    </div>
  );
}
