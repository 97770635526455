import { RefObject } from 'react';

/**
 * Allows the ability to fade and scroll a div element, this is used in the elements below
 *
 * @param wrapper RefObject<HTMLDivElement>
 * @param fade 'fade-in-wrapper' | 'fade-out-wrapper',
 * @param shouldScroll boolean
 * @param shouldWait boolean
 */
export const fadeAndScrollWrapper = (
  wrapper: RefObject<HTMLDivElement>,
  fade: 'fade-in-wrapper' | 'fade-out-wrapper',
  shouldScroll: boolean = true,
  shouldWait: boolean = false
) => {
  const wrapperNode = wrapper.current;

  if (wrapperNode !== null) {
    const addFade = () => {
      wrapperNode.classList.remove('fade-in-wrapper');
      wrapperNode.classList.remove('fade-out-wrapper');

      wrapperNode.classList.add(fade);

      shouldScroll &&
        setTimeout(() => {
          scrollToWrapper(wrapperNode);
        }, 250);
    };

    shouldWait
      ? setTimeout(() => {
          addFade();
        }, 300)
      : addFade();
  }
};

export const scrollToWrapper = (wrapperNode: HTMLDivElement | null) => {
  if (wrapperNode !== null) {
    window.scrollTo({
      top: wrapperNode.getBoundingClientRect().top + window.scrollY - 50,
      left: 0,
      behavior: 'smooth',
    });
  }
};

export const fadeQuestions = (
  fade: 'fade-in-wrapper' | 'fade-out-wrapper',
  wrapperRef: RefObject<HTMLDivElement>
) => {
  const questionnaireWrapperNode = wrapperRef.current;

  if (fade === 'fade-in-wrapper') {
    questionnaireWrapperNode !== null &&
      questionnaireWrapperNode.classList.remove('fade-out-wrapper');
  }

  questionnaireWrapperNode !== null && questionnaireWrapperNode.classList.add(fade);
};
