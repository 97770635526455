import { ExperienceCategory } from 'common/dist/models/experience';
import { DeepProduct } from 'common/dist/products/productFrequency';
import { Dispatch } from '@reduxjs/toolkit';

import { ALLOY_CART } from 'actions/types';

import { retrieveGroupedContentfulProductsFrom } from 'lib/shared/product';

import { AlloyCartWithTerms } from 'models/alloy/cart';

import { RootState } from 'reducers';

const create = (
  products: DeepProduct[],
  categories: ExperienceCategory[],
  submissionExists: boolean
) => {
  return async (dispatch: Dispatch) => {
    const fetchedProducts = await retrieveGroupedContentfulProductsFrom(
      products,
      categories,
      submissionExists
    );

    const cart = {
      products: fetchedProducts.flat(),
      promotionCode: {
        id: '',
        name: '',
        discountAmount: 0,
      },
      taxAmountToCollect: 0,
      referralCreditBalance: 0,
      agreement: {
        isAgreedTerms: false,
        isOptedSmsMarketing: true,
      },
      shipNow: true,
    };

    dispatch({ type: ALLOY_CART, payload: cart });
  };
};

const update = (updatingCart: Partial<AlloyCartWithTerms>) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const updated = {
      ...getState().experience.alloyCart,
      ...updatingCart,
    };

    dispatch({ type: ALLOY_CART, payload: updated });
  };
};

const clear = () => {
  return (dispatch: Dispatch) => {
    const cart = {
      products: [],
      promotionCode: {
        id: '',
        name: '',
        discountAmount: 0,
      },
      taxAmountToCollect: 0,
      referralCreditBalance: 0,
      agreement: {
        isAgreedTerms: false,
        isOptedSmsMarketing: true,
      },
      shipNow: true,
    };

    dispatch({ type: ALLOY_CART, payload: cart });
  };
};

export { clear as clearCart, create as createCart, update as updateCart };
