import { StripePaymentMethod } from 'client/dist/generated/alloy';

import CardIcon from 'components/core/CardIcon';

import { formatExpirationDate } from 'lib/dashboard/stripe';

interface Props {
  paymentMethod: StripePaymentMethod;
  setRemovePaymentMethod: () => void;
}

export default function SelectedPaymentBlock({ paymentMethod, setRemovePaymentMethod }: Props) {
  return (
    <div className='ce-selected-payment-block'>
      <div className='payment-method-wrapper'>
        <p className='payment-method-text'>
          <CardIcon brand={paymentMethod.card!.brand} />
          {paymentMethod.card?.brand.charAt(0).toUpperCase()}
          {paymentMethod.card?.brand.slice(1)} ending in {paymentMethod.card?.last4}
        </p>

        <p className='payment-method-text'>
          Expiration date: {formatExpirationDate(paymentMethod.card!)}
        </p>
      </div>

      <button className='primary-link-button' onClick={setRemovePaymentMethod}>
        Change payment method
      </button>
    </div>
  );
}
