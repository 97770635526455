import { Customer } from 'client/dist/generated/alloy';
import posthog from 'posthog-js';

export function identifyPosthog(customer: Customer) {
  if (process.env.REACT_APP_NODE_ENV !== 'production') {
    posthog.identify(customer.patientId, { email: customer.email });
  } else {
    posthog.identify(customer.patientId);
  }
}

export function posthogLogout() {
  posthog.reset();
}

export function posthogLoaded() {
  return new Promise<boolean>((resolve, _) => {
    posthog.onFeatureFlags(() => {
      resolve(true);
    });
  });
}
