import { Skeleton } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { useGenerateSetupIntent, useGetPaymentMethods } from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';

import AddressDrawer from 'components/dashboard/account/drawers/AddressDrawer';
import PaymentDrawer from 'components/dashboard/account/drawers/PaymentDrawer';
import ProfileDrawer from 'components/dashboard/account/drawers/ProfileDrawer';
import ContentBlock from 'components/dashboard/account/profile/ContentBlock';
import HelpBlock from 'components/dashboard/account/profile/HelpBlock';

import AccountContainer from 'containers/dashboard/AccountContainer';
import DashboardContainer from 'containers/dashboard/DashboardContainer';

import { generateProfile } from 'lib/dashboard/account/profile';
import { stripePromise } from 'lib/shared/payment';

import { StripePaymentMethod } from 'models/alloy/stripe';

import useMarkNotificationsAsRead from 'hooks/shared/useMarkNotificationsAsRead';
import { useAppSelector } from 'reducers/alloy_reducer';

export default function Profile() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['FAILED_PAYMENT']);

  const customer = useAppSelector((state) => state.alloy.customer!!);

  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);
  const [isShippingOpen, setIsShippingOpen] = useState<boolean>(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState<boolean>(false);

  const { data: paymentMethods = [], isLoading: isLoadingPaymentMethods } = useGetPaymentMethods();
  const { data: setupIntent, isLoading: isLoadingSetupIntent } = useGenerateSetupIntent();

  const isLoading = isLoadingPaymentMethods || isLoadingSetupIntent;

  const onUpdate = (settingsId: string) => {
    switch (settingsId) {
      case 'information': {
        setIsProfileOpen(true);
        break;
      }

      case 'shippingAddress': {
        setIsShippingOpen(true);
        break;
      }

      case 'paymentMethod': {
        setIsPaymentOpen(true);
        break;
      }

      default: {
        break;
      }
    }
  };

  const profileDict = generateProfile(customer, paymentMethods as StripePaymentMethod[]);

  return (
    <DashboardContainer title='Profile | Alloy' desc='' currentPage='account'>
      <AccountContainer title='Your Account' currentPage='your profile'>
        {isLoading || !setupIntent ? (
          <>
            <Skeleton variant='rectangular' height={250} className='mb-3' />
            <Skeleton variant='rectangular' height={250} />
          </>
        ) : (
          <>
            <div className='row'>
              <div className='col-12'>
                {Object.keys(profileDict.profile).map((key, index) => (
                  <ContentBlock
                    title={profileDict.titles[index]}
                    onUpdate={() => onUpdate(key)}
                    profileRows={profileDict.profile[key]}
                    key={index}
                  />
                ))}

                <HelpBlock />
              </div>
            </div>

            {/* DRAWERS */}
            <ProfileDrawer open={isProfileOpen} setOpen={setIsProfileOpen} />

            <AddressDrawer open={isShippingOpen} setOpen={setIsShippingOpen} />

            <Elements stripe={stripePromise} options={{ clientSecret: setupIntent.client_secret }}>
              <PaymentDrawer open={isPaymentOpen} setOpen={setIsPaymentOpen} />
            </Elements>
          </>
        )}
      </AccountContainer>
    </DashboardContainer>
  );
}
