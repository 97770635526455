import SelectImageField from './SelectImageField';
import { ReliefTypeSelection } from 'data/checkout-experience/content/relief-type';

interface Props {
  selectedId?: string;
  onSelect: (id: string) => void;
  options: ReliefTypeSelection[];
}

/**
 * This is a checkbox group for the Relief Type step of the intake flow. It allows for easily switching the
 * styling of the relief type selection options to easily test for highest-converting UI
 */

// TODO: this should honestly not be needed really if we already have SelectFieldsGroup which should be utilized and adjusted,
// saving a todo to handle later in some cleanup
export const FieldsGroup = ({ selectedId, onSelect, options }: Props) => {
  return (
    <div className='input-field-wrapper'>
      <div className='field-image-checkbox-group'>
        {options.map((option, index) => (
          <SelectImageField
            option={option}
            onSelect={() => onSelect(option.id)}
            key={`relief-option-${index}`}
            selectedId={selectedId}
          />
        ))}
      </div>
    </div>
  );
};
