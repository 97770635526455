import classNames from 'classnames';
import { ShippingMethod, ShippingMethodType } from 'common/dist/models/shipping';
import { useState } from 'react';

import chevronDownIcon from 'assets/svg/core/chevron-down.svg';

import { convertCentsToDollars } from 'lib/shared/convert';
import { cleanShippingTitle } from 'lib/shared/shipping';

interface Props {
  previousType?: ShippingMethodType;
  shippingMethods: (ShippingMethod & { priceInCents: number })[];
  onSelect: (method: ShippingMethod) => void;
}

export default function ShippingBlock({
  previousType = 'STANDARD',
  shippingMethods = [],
  onSelect,
}: Props) {
  const [showShippingDetails, setShowShippingDetails] = useState<boolean>(false);

  const [selectedShippingType, setSelectedShippingType] =
    useState<ShippingMethodType>(previousType);

  const sortedShippingMethods = shippingMethods.sort((a, b) => a.priceInCents - b.priceInCents);

  const selectedShippingMethod = sortedShippingMethods.find(
    (sm) => sm.method === selectedShippingType
  );

  const handleSelect = (sm: ShippingMethod) => {
    setSelectedShippingType(sm.method);
    onSelect(sm);
  };

  return (
    <div className={classNames('manage-shipping-block', showShippingDetails && 'active-shipping')}>
      <div
        className='shipping-method-wrapper'
        onClick={() => setShowShippingDetails(!showShippingDetails)}
      >
        <p className='shipping-title'>Shipping Method</p>

        <p className='shipping-text'>
          <span className='shipping-shortened-text'>
            {selectedShippingMethod
              ? cleanShippingTitle(selectedShippingMethod)
              : 'Standard Shipping (3-5 business days)'}
          </span>

          <img src={chevronDownIcon} alt='chevron-down' />
        </p>
      </div>

      <div className='shipping-options-wrapper'>
        {sortedShippingMethods.map((sm, index) => (
          <div
            key={index}
            className={classNames(
              'shipping-option',
              sm.method === selectedShippingType && 'selected'
            )}
            onClick={() => handleSelect(sm)}
          >
            <div className='option-content'>
              <p className='option-title'>{cleanShippingTitle(sm)}</p>
              <p className={classNames('option-price', sm.method === 'STANDARD' && 'free')}>
                {sm.method === 'STANDARD' ? 'FREE' : `$${convertCentsToDollars(sm.priceInCents)}`}
              </p>
            </div>

            <div className='option-outer-circle'></div>
          </div>
        ))}
      </div>
    </div>
  );
}
