import { PRESCRIBING_DOCTOR, SYMPTOMS } from 'actions/types';
import { ContentfulPrescribingDoctor } from 'models/contentful/doctor';
import { ContentfulSymptom } from 'models/contentful/symptom';
import { Dispatch } from '@reduxjs/toolkit';

const updateSymptoms = (symptoms: ContentfulSymptom[]) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SYMPTOMS, payload: symptoms });
  };
};

const updatePrescribingDoctor = (doctor: ContentfulPrescribingDoctor) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: PRESCRIBING_DOCTOR, payload: doctor });
  };
};

export { updatePrescribingDoctor, updateSymptoms };
