import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import BundleViewableProduct from 'components/checkout-experience/products/BundleViewableProduct';
import ViewableProduct from 'components/checkout-experience/products/ViewableProduct';

interface Props {
  products: GroupedContentfulProduct[][];
}

export default function ProductListBlock({ products }: Props) {
  return (
    <div className='manage-product-list-block'>
      {products.map((gcpList, listIndex) =>
        gcpList.map((gcp, index) => {
          const isBundled = gcpList.length > 1 && !!gcp.contentfulProduct.fields.bundledPrice;
          const parent = gcpList[gcpList.length - index - 1];

          return isBundled ? (
            <BundleViewableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              hideProductInformation
              hideDetails
              showDosage
              isParentSelected={!!parent}
              parent={parent}
            />
          ) : (
            <ViewableProduct
              key={`${listIndex}-${index}`}
              groupedProduct={gcp}
              hideProductInformation
              hideDetails
              showDosage
            />
          );
        })
      )}
    </div>
  );
}
