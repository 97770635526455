import Cookies from "js-cookie";
/**
 * Get top level domain. For example,
 *
 * secure.myalloy.com resolves to myalloy.com
 * www.myalloy.com resolves to myalloy.com
 * localhost resolves to localhost
 */
export function getTopLevelDomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    // Handle special cases like localhost or IP addresses
    if (parts.length === 1 || (parts.length === 2 && parts[1] === "localhost")) {
        return hostname;
    }
    // Handle standard domain names
    return parts.slice(-2).join(".");
}
/**
 * Set a cookie at the top level domain. Ensures cookie access between sites that share the same tld (marketing and dashboard).
 *
 * Consider whether you need cross site compatibility when using this - update this function
 * to allow for subdomain specificity if you don't. Principle of least privilege.
 *
 * Uses js-cookie to make things a bit easier, which url encodes/decodes automatically
 */
export const setUniversalCookie = (identifier, cookieVal) => {
    Cookies.set(identifier, cookieVal, {
        domain: getTopLevelDomain(),
        expires: 5,
        secure: true,
        sameSite: "strict",
    });
};
export const getUniversalCookie = (identifier) => {
    return Cookies.get(identifier);
};
