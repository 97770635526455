import { DeepProduct } from 'client/dist/generated/alloy';
import { ProductFrequencyRenewal, SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { first, sortBy } from 'lodash';

/**
 * For the instance of our upsell products or prescribed but not purchased, we need to understand what products
 * the customer has not purchase (both subscription and one time) as well as know which products (if any)
 * the customer is potentially able to approve on (that way we do not say ie: m4 in pending charge + m4 in upsell)
 *
 * @param subscriptions SubscriptionWithRenewal[]
 * @param purchasedOneTimePrescriptionProducts ProductFrequencyRenewal[]
 * @param prescriptionProducts ProductFrequencyRenewal[]
 * @param customerApprovalPendingChargesProducts DeepProduct[]
 * @returns ProductFrequencyRenewal[]
 */
export const getNonPurchasedProducts = (
  subscriptions: SubscriptionWithRenewal[],
  purchasedOneTimePrescriptionProducts: ProductFrequencyRenewal[],
  prescriptionProducts: ProductFrequencyRenewal[],
  customerApprovalPendingChargesProducts: DeepProduct[]
): ProductFrequencyRenewal[] => {
  const subscriptionProducts = subscriptions.flatMap((s) => s.products);

  return prescriptionProducts
    .filter((pp) => !subscriptionProducts.map((sp) => sp.product.id).includes(pp.product.id))
    .filter(
      (pp) => !purchasedOneTimePrescriptionProducts.map((p) => p.product.id).includes(pp.product.id)
    )
    .filter(
      (pp) =>
        !customerApprovalPendingChargesProducts
          .map((p) => p.productId)
          .includes(pp.product.productId)
    )
    .filter((pp) => pp.product.category !== 'mht'); // filter out any mht products from round out routine
};

/**
 * Sorts an array of ContentfulProduct objects based on their form factor type.
 * The sorting order is determined by the SORT_MHT array: ['pill', 'spray', 'patch', 'gel'].
 * Products with form factor types not in this array will be placed at the end.
 *
 * @param {GroupedContentfulProduct[]} groupedProducts
 * @returns {GroupedContentfulProduct[]} A new array of GroupedContentfulProduct objects sorted by form factor type.
 *
 */
export const sortMhtProducts = (groupedProducts: GroupedContentfulProduct[]) => {
  const SORT_MHT = ['pill', 'spray', 'patch', 'gel'];

  return sortBy(groupedProducts, (gcp) => {
    return SORT_MHT.indexOf(getFormFactorType(gcp).toLowerCase());
  });
};

/**
 * Retrieves the form factor type of the first product in a GroupedContentfulProduct.
 *
 * @param {GroupedContentfulProduct} gcp - The GroupedContentfulProduct object.
 * @returns {string} The lowercase form factor type of the first product.
 */

export const getFormFactorType = (gcp: GroupedContentfulProduct) => {
  const initialProduct = first(gcp.alloyProduct.parent)!;
  return initialProduct.formFactorType.toLowerCase();
};

/**
 * Since our grouped product should always be mht if it exists in a parent we can just check if one of the parents has mht
 * and that will give us all grouped mht products
 *
 * @param groupedProducts GroupedContentfulProduct[]
 * @returns GroupedContentfulProduct[]
 */
export const filterMhtProductsFrom = (
  groupedProducts: GroupedContentfulProduct[]
): GroupedContentfulProduct[] => {
  return groupedProducts.filter((gcp) =>
    gcp.alloyProduct.parent.some((pf) => pf.category === 'mht')
  );
};

/**
 * Give us all non mht products in an array of grouped products
 *
 * @param groupedProducts GroupedContentfulProduct[][]
 * @returns GroupedContentfulProduct[][]
 */
export const filterNonMhtProductsFrom = (groupedProducts: GroupedContentfulProduct[][]) => {
  return groupedProducts.filter((gcpList) =>
    gcpList.some((gcp) => gcp.alloyProduct.parent.every((pf) => pf.category !== 'mht'))
  );
};
