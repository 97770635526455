import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  colClass?: string;
  children: ReactNode;
}

export default function CheckoutExperienceSection({ colClass = 'col-md-6', children }: Props) {
  return (
    <section className='checkout-experience-section'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className={classNames('col-12', colClass)}>
            <div className='checkout-experience-content-wrapper'>{children}</div>
          </div>
        </div>
      </div>
    </section>
  );
}
