import { NewUpsell } from 'models/alloy/new-upsell';
import { UpsellBundledCategories } from 'models/alloy/upsell';

/**
 * bundle sexual and vaginal health into a single category
 * and sort it to show in a custom order in the list
 *
 * @param newUpsell
 * @returns
 */
export const getBundledUpdatedCategory = (newUpsell: NewUpsell[]) => {
  const newUpsellProducts = [...newUpsell];
  const sexualHealth = newUpsellProducts.find((i) => i.category === 'sexual-health');
  const vaginalHealth = newUpsellProducts.find((i) => i.category === 'vaginal-health');

  const newGroupedCategory = {
    category: 'sexual-vaginal-health',
    products: [
      ...(sexualHealth ? sexualHealth.products : []),
      ...(vaginalHealth ? vaginalHealth.products : []),
    ],
  };

  newUpsellProducts.push(newGroupedCategory);

  return newUpsellProducts
    .filter((gccp) => gccp.category !== 'sexual-health' && gccp.category !== 'vaginal-health')
    .sort((a, b) => {
      const customOrder = ['skin-health', 'sexual-vaginal-health', 'gut-health'];
      return customOrder.indexOf(a.category) - customOrder.indexOf(b.category);
    });
};

export const translateCategory = (category: UpsellBundledCategories) => {
  switch (category) {
    case 'gut-health':
      return 'Gut Health';
    case 'sexual-vaginal-health':
      return 'Sexual & Vaginal Health';
    case 'skin-health':
      return 'Skincare';
    case 'hair-health':
      return 'Hair Health';
    default:
      break;
  }
};
