import { useGetTreatmentPlan } from 'client/dist/generated/alloy';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { updateSymptoms } from 'actions/treatment-plan/symptoms_actions';

import SectionWrapper from 'components/core/layout/SectionWrapper';

import { getSymptoms } from 'lib/shared/contentful';

import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  title: string;
  fancyTitle: string;
  showActive?: boolean;
  backgroundType?: 'primary' | 'secondary';
}

export default function HeaderBlock({
  title,
  fancyTitle,
  showActive = false,
  backgroundType = 'primary',
}: Props) {
  const dispatch = useDispatch();

  const [showAllSymptoms, setShowAllSymptoms] = useState<boolean>(false);

  const symptoms = useAppSelector((state) => state.treatmentPlan.customerSymptoms);

  const customer = useAppSelector((state) => state.alloy.customer!!);

  const { data: treatmentPlan } = useGetTreatmentPlan();

  const dispatchUpdateSymptoms = bindActionCreators(updateSymptoms, dispatch);

  useEffect(() => {
    retrieveContentfulSymptoms();
  }, []);

  const retrieveContentfulSymptoms = async () => {
    if (treatmentPlan && treatmentPlan.symptoms.length !== 0 && symptoms.length === 0) {
      const retrieved = await getSymptoms(treatmentPlan.symptoms);

      dispatchUpdateSymptoms(retrieved);
    }
  };

  const onShowSymptoms = () => {
    setShowAllSymptoms(!showAllSymptoms);
  };

  const filteredSymptoms = showAllSymptoms ? symptoms : symptoms.slice(0, 3);

  return (
    <SectionWrapper sectionClasses={'dashboard-treatment-plan-header-section ' + backgroundType}>
      <div className='col-12 col-md-7'>
        <h1 className='header-title'>
          {title}
          <br />
          <span>{fancyTitle}</span>
        </h1>

        {customer.status === 'ACTIVE' && showActive && (
          <p className='header-text'>Member since {format(new Date('12/12/2020'), 'MMM, yyyy')}</p>
        )}
      </div>

      {customer.status !== 'NEW' && symptoms.length !== 0 && (
        <div className='col-12 col-md-5'>
          <div className='header-symptoms-block'>
            <p className='symptoms-title'>Symptom relief from</p>

            <ul className='symptoms-list'>
              {filteredSymptoms.map((symptom, index) => (
                <li className='symptom-item' key={index}>
                  <img
                    src={symptom.fields.icon.fields.file.url}
                    alt={symptom.fields.name}
                    className='symptom-icon'
                  />
                  <span className='symptom-text'>{symptom.fields.name}</span>
                </li>
              ))}
            </ul>

            {symptoms.length > 3 && (
              <button className='symptoms-link' onClick={onShowSymptoms}>
                View {showAllSymptoms ? 'less' : 'more'}
              </button>
            )}
          </div>
        </div>
      )}
    </SectionWrapper>
  );
}
