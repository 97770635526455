import classNames from 'classnames';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { first } from 'lodash';
import { useEffect, useState } from 'react';

import checkedBox from 'assets/svg/core/icons/checked-box-icon.svg';
import uncheckedBox from 'assets/svg/core/icons/unchecked-box-icon.svg';

import ProductInfoDrawer from '../../core/drawers/ProductInfoDrawer';
import ProductNameDosage from './ProductNameDosage';
import ProductPricingBlock from './ProductPricingBlock';

import { isSynbiotic } from 'lib/shared/contentful';
import { getProductPrice } from 'lib/shared/product/pricing';
import { getProductNameWithDosage } from 'lib/shared/product/name';
import ProductRegistry from 'client/dist/product/productRegistry';

interface Props {
  groupedProduct: GroupedContentfulProduct;
  isSelected: boolean;
  onSelect: (gcp: GroupedContentfulProduct) => void;
  hideProductInformation?: boolean;
  hideDetails?: boolean;
  showDosage?: boolean;
  isParentSelected?: boolean;
  parent?: GroupedContentfulProduct;
}

// This component is just about universal, eventually would need to throw in a discounted price and title for parent bundleded product
export default function BundleSelectableProduct({
  groupedProduct,
  isSelected,
  onSelect,
  hideProductInformation = false,
  hideDetails = false,
  showDosage = false,
  isParentSelected = false,
  parent,
}: Props) {
  const { contentfulProduct } = groupedProduct;

  const productsCleanNameAndDosage = showDosage
    ? getProductNameWithDosage(groupedProduct)
    : [{ cleanName: contentfulProduct.fields.title }];

  const [unbundledPrice, setUnbundledPrice] = useState<number>(0);
  const [bundledPrice, setBundledPrice] = useState<number>(0);

  const [showProdInfo, setShowProdInfo] = useState<boolean>(false);

  const isProductSynbiotic = isSynbiotic(contentfulProduct);
  const parentName = ProductRegistry.get().getProductFullName(
    first(parent?.alloyProduct.parent ?? [])!
  );

  useEffect(() => {
    const getPricing = async () => {
      const product = first(groupedProduct.alloyProduct.parent)!;

      if (product) {
        const [unbundledPriceInCents, bundledPriceInCents] = await Promise.all([
          getProductPrice(product, 'RECURRING'),
          getProductPrice(product, 'RECURRING', true),
        ]);

        setUnbundledPrice(unbundledPriceInCents / 100);
        setBundledPrice(bundledPriceInCents / 100);
      }
    };

    getPricing();
  }, []);

  const onSelectProduct = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onSelect(groupedProduct);
  };

  const onOpenProdInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setShowProdInfo(true);
  };

  return (
    <>
      <div
        className={classNames('selectable-product', isSelected && 'selected')}
        onClick={onSelectProduct}
      >
        {!isProductSynbiotic && <span className='product-rx-tag'>Rx</span>}

        <div className={classNames('product-header')}>
          <div className='header-content'>
            <img
              src={contentfulProduct.fields.photo.fields.file.url}
              alt={contentfulProduct.fields.photo.fields.title}
              className='header-img'
            />

            <div className='header-text-wrapper'>
              {productsCleanNameAndDosage.map(({ cleanName, dosage }, index) => (
                <ProductNameDosage key={index} cleanName={cleanName} dosage={dosage} />
              ))}

              <ProductPricingBlock
                unbundledPrice={unbundledPrice}
                bundledPrice={bundledPrice}
                canBeDiscounted={!!parent}
                isParentSelected={isParentSelected}
                discountedParentName={parentName}
              />

              {hideDetails && !hideProductInformation && (
                <button onClick={onOpenProdInfo} className='product-info-btn-link'>
                  Product information
                </button>
              )}
            </div>
          </div>

          <img
            src={isSelected ? checkedBox : uncheckedBox}
            alt='selectable box'
            className='header-box-btn'
          />
        </div>

        {!hideDetails && contentfulProduct.fields.detailsList && (
          <ul className='product-details-list'>
            {(contentfulProduct.fields.detailsList ?? []).map((text, index) => (
              <li className='list-text' key={index}>
                {text}
              </li>
            ))}
          </ul>
        )}

        {!hideDetails && !hideProductInformation && (
          <button onClick={onOpenProdInfo} className='product-info-btn-link'>
            Product information
          </button>
        )}
      </div>

      <ProductInfoDrawer
        open={showProdInfo}
        setOpen={setShowProdInfo}
        product={contentfulProduct}
      />
    </>
  );
}
