import { useAppSelector } from 'reducers/alloy_reducer';

import SectionWrapper from 'components/core/layout/SectionWrapper';
import EmptyStateBlock from '../core/EmptyPlanBlock';
import HeaderBlock from '../core/HeaderBlock';
import InfoSection from '../../shared/sections/Info';
import TestimonialsSection from '../../shared/sections/Testimonials';

import DashboardContainer from 'containers/dashboard/DashboardContainer';

export default function EmptyPlan() {
  const customer = useAppSelector((state) => state.alloy.customer!!);

  return (
    <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
      <HeaderBlock
        title={
          customer.status === 'NEW'
            ? `Create a treatment plan${customer.firstName ? ' for' : ''}`
            : 'Treatment plan for'
        }
        fancyTitle={customer.firstName ?? ''}
      />

      <SectionWrapper
        sectionClasses='dashboard-treatment-plan-section extra-bottom-padding'
        rowClasses='justify-content-center'
      >
        <div className='col-12 col-md-7'>
          <EmptyStateBlock />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />
    </DashboardContainer>
  );
}
