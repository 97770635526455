import { useGenerateSetupIntent, useGetPaymentMethods } from 'client/dist/generated/alloy';
import { useState } from 'react';

import { Skeleton } from '@mui/material';

import ProductsList from 'components/dashboard/shared/products/ProductsList';
import { useSubscriptionContext } from 'context/dashboard/subscriptions/manage';
import { ManageLevel, ManageType } from 'models/components/shared/manage-type';
import HeaderInfoCardBlock from '../cards/HeaderInfoCard';
import OrderSummaryCardBlock from '../cards/OrderSummaryCard';
import ManageDrawer from '../drawers/ManageDrawer';
import PauseModal from '../modals/PauseModal';

export default function ManageWrapper() {
  const { subscription, isLoading } = useSubscriptionContext();

  const [isManageOpen, setIsManageOpen] = useState(false);
  const [isPauseOpen, setIsPauseOpen] = useState(false);
  const [selectedDeepProductIds, setSelectedDeepProductIds] = useState<number[]>([]);
  const [manageType, setManageType] = useState<ManageType>('EDIT');
  const [manageLevel, setManageLevel] = useState<ManageLevel>('SUBSCRIPTION');

  const { isLoading: isLoadingIntent } = useGenerateSetupIntent();
  const { isLoading: isPaymentMethodsLoading } = useGetPaymentMethods();

  const isLoadingData = isLoading || isLoadingIntent || isPaymentMethodsLoading;

  const canEditProduct = process.env.REACT_APP_SHOW_V3_SUBSCRIPTIONS_BUNDLING === 'true';

  const onEditProduct = (deepProductIds: number[]) => {
    setSelectedDeepProductIds(deepProductIds);
    setManageType('EDIT');
    setManageLevel('PRODUCT');

    setIsManageOpen(true);
  };

  const onRescheduleSub = () => {
    setSelectedDeepProductIds(subscription?.products.map((pfr) => pfr.product.id) ?? []);
    setManageType('RESCHEDULE');
    setManageLevel('SUBSCRIPTION');

    setIsManageOpen(true);
  };

  const onShipNowSub = () => {
    setSelectedDeepProductIds(subscription?.products.map((pfr) => pfr.product.id) ?? []);
    setManageType('SHIP_NOW');
    setManageLevel('SUBSCRIPTION');

    setIsManageOpen(true);
  };

  const onPause = () => {
    setIsManageOpen(false);
    setIsPauseOpen(true);
  };

  if (isLoadingData) {
    return (
      <>
        <Skeleton variant='rectangular' height={200} className='mb-3' />
        <Skeleton variant='rectangular' height={200} />
      </>
    );
  }

  if (!!subscription) {
    return (
      <>
        <div className='row'>
          <div className='col-12'>
            <div className='subscription-container'>
              <div className='subscription-content-wrapper'>
                <HeaderInfoCardBlock onReschedule={onRescheduleSub} onShipNow={onShipNowSub} />
              </div>

              <div className='subscription-content-wrapper'>
                <div className='products-card-block'>
                  <h3 className='card-title'>
                    Your Treatments <span className='title-hug'>Every 3 months</span>
                  </h3>

                  <ProductsList
                    productsWithRenewal={subscription.products}
                    subscriptions={[subscription]}
                    showDosage
                    onEdit={canEditProduct ? onEditProduct : undefined}
                  />
                </div>
              </div>

              <div className='subscription-content-wrapper'>
                <OrderSummaryCardBlock subscription={subscription} />
              </div>
            </div>
          </div>
        </div>

        {!!selectedDeepProductIds.length && (
          <ManageDrawer
            selectedDeepProductIds={selectedDeepProductIds}
            selectedType={manageType}
            manageLevel={manageLevel}
            open={isManageOpen}
            setOpen={setIsManageOpen}
            onPause={onPause}
          />
        )}

        {!!selectedDeepProductIds.length && (
          <PauseModal
            selectedDeepProductIds={selectedDeepProductIds}
            manageLevel={manageLevel}
            open={isPauseOpen}
            setOpen={setIsPauseOpen}
          />
        )}
      </>
    );
  }

  return <></>;
}
