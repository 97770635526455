import { ReactNode, useEffect } from 'react';

import SEO from 'components/core/SEO';

import Footer from 'containers/Footer';
import DashboardNavBar, { CurrentPage } from 'containers/dashboard/DashboardNavBar';

interface Props {
  children: ReactNode;
  title: string;
  desc?: string;
  currentPage?: CurrentPage;
  showFooter?: boolean;
}

export default function DashboardContainer({
  children,
  title,
  desc = '',
  currentPage,
  showFooter = true,
}: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO title={title} description={desc} />

      <header className='dashboard-header-wrapper'>
        <DashboardNavBar currentPage={currentPage} />
      </header>

      <main className='bg-dashboard'>{children}</main>

      {showFooter && <Footer />}
    </>
  );
}
