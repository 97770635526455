import { GetStartedLink } from 'components/core/Buttons';

export type NavItemDropdown = {
  title: string;
  link: string;
  fancyLink?: JSX.Element;
};

export type NavItem = {
  title: string;
  fancyLink?: JSX.Element;
  dropdown?: NavItemDropdown[];
  link?: string;
};

export const navItems: NavItem[] = [
  {
    title: 'Skin',
    fancyLink: <GetStartedLink title='Get started' categories={['skin-health']} />,
    dropdown: [
      {
        title: 'Estriol Face Cream',
        link: `${process.env.REACT_APP_MARKETING_URL}/m4`,
      },
    ],
  },
  {
    title: 'Menopause',
    fancyLink: <GetStartedLink title='Get started' categories={['mht']} />,
    dropdown: [
      {
        title: 'Symptoms',
        link: `${process.env.REACT_APP_MARKETING_URL}/symptoms`,
      },
      {
        title: 'The science',
        link: `${process.env.REACT_APP_MARKETING_URL}/the-science`,
      },
    ],
  },
  {
    title: 'Sexual health',
    dropdown: [
      {
        title: 'Libido issues',
        link: `${process.env.REACT_APP_MARKETING_URL}/omazing`,
        fancyLink: (
          <GetStartedLink title='Get started' categories={['sexual-health']} isCheckoutExperience />
        ),
      },
      {
        title: 'Vaginal issues',
        link: '/solutions/estradiol-vaginal-cream',
        fancyLink: <GetStartedLink title='Get started' categories={['mht']} />,
      },
    ],
  },
  {
    title: 'All solutions',
    link: `${process.env.REACT_APP_MARKETING_URL}/solutions`,
  },
  {
    title: 'The Flash',
    link: `${process.env.REACT_APP_MARKETING_URL}/blog`,
  },
  {
    title: 'Reviews',
    link: `${process.env.REACT_APP_MARKETING_URL}/reviews`,
  },
];
