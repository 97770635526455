import { updateIsEditingShipping } from 'actions/checkout-experience/checkout_actions';
import { StripePaymentMethod } from 'client/dist/generated/alloy';
import { fadeAndScrollWrapper } from 'lib/shared/fadeAndScroll';
import { createRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import AccountWrapper from '../layout/AccountWrapper';
import BlurbWrapper from '../layout/BlurbWrapper';
import PaymentWrapper from '../layout/PaymentWrapper';
import ShippingWrapper from '../layout/ShippingWrapper';
import SummaryWrapper from '../layout/SummaryWrapper';
import TermsWrapper from '../layout/TermsWrapper';
import StatReviewWrapper from '../StatReviewWrapper';

interface Props {
  checkoutStep?: 'shipping' | 'payment';
  onPlaceOrder: () => void;
  onNextStep?: () => void;
  onSelectPaymentMethod: (pm: StripePaymentMethod | null, isAllInputsComplete: boolean) => void;
  isPaymentError: boolean;
  setIsTermsError: (isError: boolean) => void;
  isTermsError: boolean;
  disableContinue: boolean;
  paymentWrapperRef: React.RefObject<HTMLDivElement>;
}

export default function MultiStepWrapper({
  checkoutStep,
  onPlaceOrder,
  onNextStep,
  onSelectPaymentMethod,
  isPaymentError,
  setIsTermsError,
  isTermsError,
  disableContinue,
  paymentWrapperRef,
}: Props) {
  const dispatch = useDispatch();

  const shippingWrapperRef = createRef<HTMLDivElement>();

  const dispatchUpdateEditingShipping = bindActionCreators(updateIsEditingShipping, dispatch);

  useEffect(() => {
    if (!!checkoutStep) {
      switch (checkoutStep) {
        case 'shipping': {
          fadeAndScrollWrapper(paymentWrapperRef, 'fade-out-wrapper', false, true);
          fadeAndScrollWrapper(shippingWrapperRef, 'fade-in-wrapper', true, true);

          dispatchUpdateEditingShipping(true);
          window.scrollTo(0, 0);

          break;
        }
        case 'payment': {
          fadeAndScrollWrapper(paymentWrapperRef, 'fade-in-wrapper', true, true);
        }
      }
    }
  }, [checkoutStep]);

  return (
    <>
      <BlurbWrapper />

      {checkoutStep === 'shipping' && (
        <div className='fade-wrapper' ref={shippingWrapperRef}>
          <AccountWrapper />
          <ShippingWrapper onContinueClick={onNextStep} />
          <div className='d-md-none'>
            <StatReviewWrapper />
          </div>
        </div>
      )}

      {checkoutStep === 'payment' && (
        <div className='fade-wrapper' ref={paymentWrapperRef}>
          <PaymentWrapper onSelectPaymentMethod={onSelectPaymentMethod} isError={isPaymentError} />
          <SummaryWrapper onPlaceOrder={onPlaceOrder} shouldDisableContinue={disableContinue} />
          <TermsWrapper
            onPlaceOrder={onPlaceOrder}
            shouldDisableContinue={disableContinue}
            isError={isTermsError}
            setIsError={setIsTermsError}
          />
          <div className='d-md-none'>
            <StatReviewWrapper />
          </div>
        </div>
      )}
    </>
  );
}
