import { faFacebook, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

export const footerTitles = ['Site Map', 'Shop', 'Articles'];

export const footerItems = [
  [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'Symptoms',
      link: '/symptoms',
    },
    {
      title: 'About us',
      link: '/who-we-are',
    },
    {
      title: 'FAQ',
      link: 'https://myalloy.zendesk.com/hc/en-us',
      directLink: true,
    },
    {
      title: 'Reviews',
      link: '/Reviews',
    },
    {
      title: 'Contact Us',
      link: '/contact-us',
    },
  ],
  [
    {
      title: 'All solutions',
      link: '/solutions',
    },
  ],
  [
    {
      title: 'Blog',
      link: '/blog',
    },
  ],
];

export const socialItems = [
  {
    icon: faTiktok,
    link: 'https://tiktok.com/@myalloy',
  },
  {
    icon: faInstagram,
    link: 'https://instagram.com/myalloy',
  },
  {
    icon: faFacebook,
    link: 'https://www.facebook.com/Alloy-106248924869724',
  },
  {
    icon: faYoutube,
    link: 'https://www.youtube.com/channel/UCWqpCxO05TNzGQ3Me92jGLA',
  },
];
