import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ShippingMethod } from 'client/dist/generated/alloy';
import { useSubscriptionContext } from 'context/dashboard/subscriptions/manage';
import { format } from 'date-fns';

import { getFormattedDateRange } from 'lib/core/day';
import { getMaxDateForRescheduling } from 'lib/dashboard/prescription';
import { maxDaysFromShipping, minDaysFromShipping } from 'lib/shared/shipping';

interface Props {
  shippingMethod?: ShippingMethod;
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
}

export default function CalendarBlock({ shippingMethod, selectedDate, setSelectedDate }: Props) {
  const { subscription } = useSubscriptionContext();

  const maxDate = getMaxDateForRescheduling(subscription);

  return (
    <div className='manage-calendar-block'>
      <p className='content-title'>
        Next bill date: {selectedDate ? format(selectedDate, 'M/d') : '-'}
      </p>

      <p className='content-text'>
        Expected shipment delivery:{' '}
        {selectedDate
          ? getFormattedDateRange(
              selectedDate,
              minDaysFromShipping(shippingMethod),
              maxDaysFromShipping(shippingMethod)
            )
          : '-'}
      </p>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateCalendar
          showDaysOutsideCurrentMonth
          disablePast
          maxDate={maxDate}
          onChange={setSelectedDate}
          focusedView='day'
          views={['day']}
          defaultValue={new Date(subscription.nextRecurrenceOn)}
        />
      </LocalizationProvider>
    </div>
  );
}
