import { useLocation, useNavigate } from 'react-router-dom';

import { StandaloneVerificationProps } from 'models/alloy/experience';

import { handleNextPath } from 'lib/checkout-experience/handlePath';
import Verification from 'pages/checkout-experience/shared/auth/Verification';

export default function StandaloneVerification() {
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as StandaloneVerificationProps | undefined;

  return (
    <Verification
      onNext={() => handleNextPath(navigate, location)}
      onBack={() => navigate(-1)}
      standaloneVerificationParams={state}
    />
  );
}
