import { add, format, isValid } from 'date-fns';

export const getFormattedDateRange = (date: Date, minDays: number, maxDays: number) => {
  const minDate = add(date, { days: minDays });
  const maxDate = add(date, { days: maxDays });

  const formattedMin = format(minDate, 'M/d');
  const formattedMax = format(maxDate, 'M/d');

  if (formattedMin === formattedMax) {
    return formattedMax;
  }

  return `${formattedMin} - ${formattedMax}`;
};
