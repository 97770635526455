interface Props {
  cleanName: string;
  dosage: string | undefined;
}

export default function ProductNameDosage({ cleanName, dosage }: Props) {
  return (
    <div className='header-name-dosage'>
      <p className='header-title'>{cleanName}</p>
      {dosage && <div className='header-dosage'>{dosage}</div>}
    </div>
  );
}
