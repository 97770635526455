import { CUSTOMER, IS_AUTHENTICATED, PRESCRIBING_DOCTOR, SYMPTOMS } from 'actions/types';
import { signOut } from 'lib/core/awsAuth';
import { brazeLogout } from 'lib/tracking/braze';
import { posthogLogout } from 'lib/tracking/posthog';
import { sendExceptionToSentry } from 'lib/tracking/sentry';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';

const logout = (navigate: NavigateFunction) => {
  return async (dispatch: Dispatch) => {
    try {
      await signOut();

      brazeLogout();
      posthogLogout();

      dispatch({ type: IS_AUTHENTICATED, payload: false });
      dispatch({ type: CUSTOMER, payload: undefined });

      dispatch({ type: SYMPTOMS, payload: [] });
      dispatch({ type: PRESCRIBING_DOCTOR, payload: undefined });

      navigate('/login');
    } catch (error) {
      sendExceptionToSentry(error as Error);
      throw error;
    }
  };
};

export { logout };
