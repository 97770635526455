import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { ProductCleanNameDosage } from '.';
import { DOSAGE_NA } from 'common/dist/products/productRegistry';
import ProductRegistry from 'client/dist/product/productRegistry';

/**
 * get the name for all products combined from alloy product. this allows us to get dosage and then
 * formatting it to the user.
 *
 * @param groupedProduct GroupedContentfulProduct
 * @returns string
 */
export const getProductNameWithDosage = (
  groupedProduct: GroupedContentfulProduct
): ProductCleanNameDosage[] => {
  const { alloyProduct } = groupedProduct;
  const allProducts = [...alloyProduct.parent, ...(alloyProduct.child ?? [])].flat();

  return allProducts.map((p) => ({
    cleanName: ProductRegistry.get().getProductFullName(p),
    ...(p.dosageId !== DOSAGE_NA && { dosage: p.dose }),
  }));
};
