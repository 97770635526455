import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Drawer, Form, Loader } from 'rsuite';

import { ProfileUpdates, updateCustomer } from 'actions/core/customer_actions';

import closeIcon from 'assets/svg/btn-close.svg';

import { EmailUs } from 'components/core/Buttons';
import { DateField } from 'components/core/fields/DateField';
import { PhoneField } from 'components/core/fields/PhoneField';
import { TextField } from 'components/core/fields/TextField';
import PasswordDrawer from './PasswordDrawer';

import { showErrorNotification, showSuccessNotification } from 'components/core/Notification';
import { ValidDob, transformDateString, validDob } from 'lib/shared/date';

import { useAppSelector } from 'reducers/alloy_reducer';

import { sendExceptionToSentry } from 'lib/tracking/sentry';
import { isEmpty } from 'lodash';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ProfileDrawer({ open, setOpen }: Props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [isPasswordOpen, setIsPasswordOpen] = useState<boolean>(false);

  const customer = useAppSelector((state) => state.alloy.customer!!);

  const [profileForm, setProfileForm] = useState<ProfileUpdates>({
    firstName: customer.firstName,
    lastName: customer.lastName,
    phoneNumber: customer.phoneNumber,
    dateOfBirth: transformDateString(customer.dateOfBirth?.toISOString() ?? '', 'MM/DD/YYYY'),
  });

  const dispatchUpdateCustomer = bindActionCreators(updateCustomer, dispatch);

  const isFormIncomplete = Object.entries(profileForm)
    .filter(([key]) => key !== 'dateOfBirth')
    .some(([, field]) => !field?.trim() || field.includes('_'));

  const isDobIncomplete = isEmpty(profileForm.dateOfBirth?.trim()) && !!customer.dateOfBirth;

  const onSave = async () => {
    try {
      if (profileForm.dateOfBirth) {
        const response: ValidDob = validDob(profileForm.dateOfBirth);
        if (response.error) {
          showErrorNotification(response.error);
          throw new Error(response.error);
        }
      }

      setLoading(true);

      await dispatchUpdateCustomer(profileForm);

      showSuccessNotification('Successfully updated your profile information');

      setLoading(false);
      setOpen(false);
    } catch (error) {
      sendExceptionToSentry(error as Error);
      setLoading(false);
    }
  };

  const onClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  const onChangePassword = () => {
    setIsPasswordOpen(true);
  };

  return (
    <>
      <Drawer open={open} size='sm' className='alloy-drawer profile-drawer' onClose={onClose}>
        <Drawer.Body>
          <div className='drawer-header'>
            <p className='header-title'>Profile Information</p>

            <button onClick={onClose} className='header-btn-close'>
              <img src={closeIcon} alt='close icon' className='close-icon' />
            </button>
          </div>

          {loading ? (
            <Loader center size='lg' />
          ) : (
            <>
              <div className='drawer-body'>
                <Form>
                  <div className='profile-form-wrapper'>
                    <TextField
                      name='firstName'
                      label='First name'
                      value={profileForm.firstName}
                      onChange={(value) => setProfileForm({ ...profileForm, firstName: value })}
                    />

                    <TextField
                      name='lastName'
                      label='Last name'
                      value={profileForm.lastName}
                      onChange={(value) => setProfileForm({ ...profileForm, lastName: value })}
                    />

                    <PhoneField
                      name='phone'
                      label='Phone number'
                      value={profileForm.phoneNumber ?? customer.phoneNumber ?? ''}
                      onChange={(value) => setProfileForm({ ...profileForm, phoneNumber: value })}
                    />

                    <DateField
                      name='dateOfBirth'
                      label='Birthday'
                      value={profileForm.dateOfBirth}
                      onChange={(value) => setProfileForm({ ...profileForm, dateOfBirth: value })}
                    />
                  </div>
                </Form>

                <button className='primary-link-button' onClick={onChangePassword}>
                  Change Password
                </button>

                <div className='profile-help-block'>
                  <p className='help-title'>Need Help?</p>
                  <p className='help-text'>
                    To make changes to your email contact us.{' '}
                    <EmailUs btnClass='primary-link-button' />
                  </p>
                </div>
              </div>

              <div className='drawer-footer'>
                <button
                  onClick={onSave}
                  className='btn-save'
                  disabled={isFormIncomplete || isDobIncomplete}
                >
                  Save
                </button>
              </div>
            </>
          )}
        </Drawer.Body>
      </Drawer>

      <PasswordDrawer open={isPasswordOpen} setOpen={setIsPasswordOpen} />
    </>
  );
}
