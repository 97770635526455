import {
  Submission,
  postPreIdentifiedSubmission,
  postSubmission,
} from 'client/dist/generated/alloy';
import getIntakesByCategory from 'common/dist/intake/getIntakesByCategory';
import { Question } from 'components/checkout-experience/questionnaire/Question';
import Loader from 'components/core/Loader';
import { getAnswersForSubmission } from 'lib/checkout-experience/questionnaire/submission';
import { QuestionnaireAnswers } from 'models/components/questionnaire';
import { useState } from 'react';
import { useAppSelector } from 'reducers/alloy_reducer';
import { Question as AlloyQuestion } from 'common/dist/models/questionnaire';

export default function HeardAboutUs() {
  const dateStarted = new Date();

  const [isLoading, setIsLoading] = useState(false);
  const [answers, setAnswers] = useState<QuestionnaireAnswers>({});
  const [followUps, setFollowUps] = useState<QuestionnaireAnswers>({});
  const [isValid, setIsValid] = useState<boolean>(false);
  const [surveySubmitted, setSurveySubmitted] = useState<boolean>(false);
  const [questionSelected, setQuestionSelected] = useState<number>(0);

  const customer = useAppSelector((state) => state.alloy.customer);

  const questions = getIntakesByCategory(['heard-about-us-survey']);

  const answersSlugsSelected = (a: QuestionnaireAnswers) => Object.values(a).flat();

  const getFollowUpsSelected = (answers: QuestionnaireAnswers) => {
    const questionWithFollowUps = questions.flatMap((q: AlloyQuestion) =>
      q.options.filter((o) => o.followUp)
    );

    return questionWithFollowUps
      .filter((qwfu) => answersSlugsSelected(answers).includes(qwfu.slug))
      .map((qwfu) => qwfu.followUp);
  };

  const validateFollowUps = (
    answers: QuestionnaireAnswers,
    followUpsAnswers: QuestionnaireAnswers
  ) => {
    const followUpsSelected = getFollowUpsSelected(answers);

    const validFollowUps =
      followUpsSelected.every((f) => Object.keys(followUpsAnswers).includes(f?.slug as string)) &&
      Object.values(followUpsAnswers).every((v) => v !== '');

    return { followUpsSelected, validFollowUps };
  };

  const handleUpdateAnswers = (updatedAnswers: QuestionnaireAnswers) => {
    setAnswers(updatedAnswers);

    const hasMoreThanOneAnswer = answersSlugsSelected(updatedAnswers).length > 0;
    const isSecondQuestionAnswered = answersSlugsSelected(updatedAnswers).some((a) =>
      a.includes('first-heard')
    );

    const { followUpsSelected, validFollowUps } = validateFollowUps(updatedAnswers, followUps);

    if (
      !hasMoreThanOneAnswer ||
      (questionSelected === 1 && !isSecondQuestionAnswered) ||
      (followUpsSelected.length > 0 && !validFollowUps)
    ) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
  };

  const handleUpdateFollowUps = (updatedFollowUpAnswers: QuestionnaireAnswers) => {
    setFollowUps(updatedFollowUpAnswers);

    const { validFollowUps } = validateFollowUps(answers, updatedFollowUpAnswers);

    setIsValid(validFollowUps);
  };

  const onSubmitSurvey = async (nextIndex: number) => {
    try {
      setIsLoading(true);

      // remove any follow up that they might have selected and responded and then unselected after
      const followUpsSelected = getFollowUpsSelected(answers);

      const followUpsFiltered = Object.fromEntries(
        Object.entries(followUps).filter(([slug]) =>
          followUpsSelected.map((fus) => fus?.slug).includes(slug)
        )
      );

      const submission: Submission = {
        categories: ['heard-about-us-survey'],
        started: dateStarted,
        answers: getAnswersForSubmission(questions, { ...answers, ...followUpsFiltered }),
      };

      // if there's only one answer in the first question, skip the second one OR if they finished to fill the survey
      if ((nextIndex === 1 && answersSlugsSelected(answers).length === 1) || nextIndex === 2) {
        if (customer) {
          await postSubmission(submission);
        } else {
          await postPreIdentifiedSubmission(submission);
        }

        setSurveySubmitted(true);
      }

      // if they are landing in the second question and didn't answer anything to it, block the submit
      if (nextIndex === 1 && !answersSlugsSelected(answers).includes('first-heard')) {
        setIsValid(false);
      }

      if (!surveySubmitted) {
        setQuestionSelected(nextIndex);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className={surveySubmitted ? 'd-none' : 'heard-about-us-block'}>
      {questions.map((q, index) => (
        <div key={index} className={index === questionSelected ? '' : 'd-none'}>
          <Question
            key={index}
            question={q}
            answers={answers}
            setAnswers={handleUpdateAnswers}
            followUps={followUps}
            setFollowUps={handleUpdateFollowUps}
          />

          <button
            className='secondary-border-button'
            type='submit'
            disabled={!isValid}
            onClick={() => onSubmitSurvey(index + 1)}
          >
            Submit
          </button>
        </div>
      ))}
    </div>
  );
}
