import avExampleThumbnail from 'assets/images/av/av_sync_thumbnail.png';
import playIcon from 'assets/images/av/play_icon.png';
import rightArrow from 'assets/svg/core/right-arrow.svg';

import VideoModal from 'components/checkout-experience/upload-video/VideoModal';
import ExampleVideoModal from 'components/checkout-experience/upload-video/ExampleVideoModal';
import { useState } from 'react';

interface Props {
  onNext: () => void;
}

export default function VideoBlock({ onNext }: Props) {
  const [isExampleOpen, setIsExampleOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const handleNext = (isUploadedOrSkipped: boolean = true) => {
    if (isUploadedOrSkipped) {
      onNext();
    } else {
      setIsUploadOpen(false);
    }
  };

  return (
    <>
      <div className='video-block'>
        <div className='video-info-wrapper'>
          <p className='info-title'>Make a 10-15 second video stating</p>

          <ul className='info-list'>
            <li className='info-text'>Your name</li>
            <li className='info-text'>Date of birth</li>
            <li className='info-text'>Treatments requested -or- Symptoms experiencing</li>
          </ul>
        </div>

        <div className='video-example-wrapper' onClick={() => setIsExampleOpen(true)}>
          <span className='video-example-tag'>Example</span>

          <img src={avExampleThumbnail} alt='example av' className='video-example-img' />

          <img src={playIcon} alt='play icon' className='video-example-play-img' />
        </div>

        <div className='video-btn-wrapper'>
          <button className='btn-video btn-video-primary' onClick={() => setIsUploadOpen(true)}>
            Upload or take video
            <img src={rightArrow} alt='right arrow' className='ml-2' />
          </button>

          <button className='btn-video btn-video-link' onClick={() => handleNext(true)}>
            Add later
          </button>
        </div>
      </div>

      <VideoModal open={isUploadOpen} onClose={handleNext} />
      <ExampleVideoModal open={isExampleOpen} onClose={() => setIsExampleOpen(false)} />
    </>
  );
}
