import { useGetCustomerReferralCredits } from 'client/dist/generated/alloy';

export default function CreditsBlock() {
  const { data: referralCredits = [] } = useGetCustomerReferralCredits();

  return (
    <div className='referrals-credits-block'>
      <p className='credits-title'>Your credits</p>

      <div className='credits-wrapper'>
        <div className='wrapper-col'>
          <p className='wrapper-title'>{referralCredits.length}</p>
          <p className='wrapper-text'>Referrals</p>
        </div>

        <div className='wrapper-divider' />

        <div className='wrapper-col'>
          <p className='wrapper-title'>${referralCredits.length * 15}</p>
          <p className='wrapper-text'>Total earned</p>
        </div>
      </div>

      <p className='credits-text'>Credits are automatically applied on your next shipment.</p>
    </div>
  );
}
