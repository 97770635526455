import { DeepProduct } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { getDeepProductsFromGroupedProducts } from '.';
import ProductRegistry from 'client/dist/product/productRegistry';

/**
 * given a product, check and fetch other products with the same product id
 * but with a diff dosage
 *
 * todo: add tests
 *
 * @param product
 * @returns
 */
export const getProductsWithAltDoses = async (product: GroupedContentfulProduct) => {
  const deepProducts = getDeepProductsFromGroupedProducts([
    { alloyProduct: product.alloyProduct, contentfulProduct: product.contentfulProduct },
  ]);

  // either parents AND/OR children can have alternative doses
  const productsWithAltDoses = (
    await Promise.all(
      deepProducts.map(async (dp) => {
        const diffDoses = await ProductRegistry.get().hasAlternativeDoses(dp);
        return { dp, diffDoses };
      })
    )
  )
    .filter((dp) => !!dp.diffDoses)
    .map((i) => i.dp);

  return productsWithAltDoses;
};

/**
 * given a product, check and fetch other products with the same product id
 * but with a form factor
 *
 * todo: add tests
 *
 * @param product
 * @returns
 */
export const getProductsWithAltFormFactors = async (product: GroupedContentfulProduct) => {
  const deepProducts = getDeepProductsFromGroupedProducts([
    { alloyProduct: product.alloyProduct, contentfulProduct: product.contentfulProduct },
  ]);

  // either parents AND/OR children can have alternative form factors
  const productsWithAltFormFactors = (
    await Promise.all(
      deepProducts.map(async (dp) => {
        const diffForms = await ProductRegistry.get().hasAlternativeFormFactors(dp);
        return { dp, diffForms };
      })
    )
  )
    .filter((dp) => !!dp.diffForms)
    .map((i) => i.dp);

  return productsWithAltFormFactors;
};

/**
 * given a product, check if that grouped contentful product is a
 * switchable product or not (meaning, have multiple dosages AND/OR multiple form factors)
 *
 * todo: add tests
 *
 * @param gcp
 * @param switchableProducts
 * @returns
 */
export const isProductInSwitchable = (
  gcp: GroupedContentfulProduct,
  switchableProducts: DeepProduct[]
) =>
  getDeepProductsFromGroupedProducts([gcp]).some(
    (dp) => !!switchableProducts.find((sp) => ProductRegistry.get().areProductsEquivalent([sp, dp]))
  );
