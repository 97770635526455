import classNames from 'classnames';
import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import ViewableProduct from 'components/checkout-experience/products/ViewableProduct';
import { useEffect, useState } from 'react';
import { getBundledProductFrom } from 'lib/shared/product';
import BundleViewableProduct from 'components/checkout-experience/products/BundleViewableProduct';

export type ProductSwitchChangeType = 'CUSTOMER_FORM_FACTOR_CHANGE' | 'CUSTOMER_DOSAGE_CHANGE';

interface Props {
  product: GroupedContentfulProduct;
  subscription: SubscriptionWithRenewal;
  selectedType: ProductSwitchChangeType | undefined;
  setSelectedType: (type: ProductSwitchChangeType) => void;
  onlyDosageSwitch: boolean;
  onlyFormFactorSwitch: boolean;
}

export default function HeaderBlock({
  product,
  subscription,
  selectedType,
  setSelectedType,
  onlyDosageSwitch,
  onlyFormFactorSwitch,
}: Props) {
  const [bundledProduct, setBundledProduct] = useState<GroupedContentfulProduct | undefined>(
    undefined
  );

  const subProducts =
    subscription && subscription.status === 'ACTIVE'
      ? subscription.products.map((p) => p.product)
      : [];

  useEffect(() => {
    const fetchProducts = async () => {
      const bundled = await getBundledProductFrom(subProducts, product);

      setBundledProduct(bundled);
    };

    fetchProducts();
  }, []);

  return (
    <div className='request-content-wrapper'>
      <p className='content-title'>Your current treatment</p>

      <div className='request-products-wrapper'>
        {!!bundledProduct ? (
          <BundleViewableProduct
            groupedProduct={product}
            isParentSelected
            parent={bundledProduct}
            showDosage
            hideProductInformation
            hideDetails
          />
        ) : (
          <ViewableProduct groupedProduct={product} showDosage hideProductInformation hideDetails />
        )}
      </div>

      {!onlyDosageSwitch && !onlyFormFactorSwitch && (
        <div className='request-products-buttons'>
          <button
            className={classNames(
              'secondary-border-button',
              selectedType === 'CUSTOMER_FORM_FACTOR_CHANGE' && 'selected-button'
            )}
            onClick={() => setSelectedType('CUSTOMER_FORM_FACTOR_CHANGE')}
          >
            Change treatment
          </button>

          <button
            className={classNames(
              'secondary-border-button',
              selectedType === 'CUSTOMER_DOSAGE_CHANGE' && 'selected-button'
            )}
            onClick={() => setSelectedType('CUSTOMER_DOSAGE_CHANGE')}
          >
            Change dosage
          </button>
        </div>
      )}
    </div>
  );
}
