import { CheckoutType, IntakeCategory } from 'client/dist/generated/alloy';
import { AlloyCartWithTerms } from 'models/alloy/cart';

export const isConsultCart = (cart: AlloyCartWithTerms) => {
  return cart.products.every((gcp) => gcp.alloyProduct.parent.every((pf) => pf.type === 'SERVICE'));
};

export const getCheckoutType = (
  cart: AlloyCartWithTerms,
  intakeCategories: IntakeCategory[]
): CheckoutType => {
  if (isConsultCart(cart)) {
    return 'CONSULT';
  } else if (
    cart.products.every((gcp) => gcp.alloyProduct.parent.every((pf) => pf.type === 'OTC')) &&
    intakeCategories.length === 0
  ) {
    return 'OTC';
  } else if (intakeCategories.every((c) => c === 'renewal')) {
    return 'RENEWAL';
  } else {
    return 'MEDICAL_INTAKE';
  }
};
