import { Elements } from '@stripe/react-stripe-js';
import { useGenerateSetupIntent, useGetPaymentMethods } from 'client/dist/generated/alloy';
import CardIcon from 'components/core/CardIcon';
import PaymentDrawer from 'components/dashboard/account/drawers/PaymentDrawer';
import { stripePromise } from 'lib/shared/payment';
import { useState } from 'react';

export default function PaymentContentBlock() {
  const [isOpen, setIsOpen] = useState(false);

  const { data: paymentMethods = [] } = useGetPaymentMethods();
  const { data: setupIntent } = useGenerateSetupIntent();

  const defaultPaymentMethod = paymentMethods.find((pm) => pm.is_primary);
  const stripeClientSecret = setupIntent?.client_secret;

  return (
    <>
      <div className='content-block'>
        <div className='content-combine'>
          <p className='content-text'>
            <span className='text-bold'>Payment Method</span>
          </p>

          {!!defaultPaymentMethod ? (
            <p className='content-text'>
              <CardIcon brand={defaultPaymentMethod.card!.brand} />
              •••• •••• •••• {defaultPaymentMethod.card!.last4}
            </p>
          ) : (
            <p className='content-text'>No default payment method found, update below.</p>
          )}
        </div>

        <button className='content-link' onClick={() => setIsOpen(true)}>
          Update payment
        </button>
      </div>

      <Elements stripe={stripePromise} options={{ clientSecret: stripeClientSecret }}>
        <PaymentDrawer open={isOpen} setOpen={setIsOpen} />
      </Elements>
    </>
  );
}
