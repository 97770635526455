import { ExperienceCategory } from 'common/dist/models/experience';

import doctorAvatar from 'assets/images/consult/dr-menn-video-thumbnail.jpg';
import skincareBundle from 'assets/images/shared/skincare-bundle-no-background.png';
import synbiotic from 'assets/images/shared/synbiotic-no-background.png';
import hair from 'assets/images/shared/hair-no-background.png';
import vaginalBundle from 'assets/images/shared/vaginal-bundle.png';
import vaginalBundleSmall from 'assets/images/shared/vaginal-bundle-small.png';

import { ReliefImageProps } from 'components/shared/checkout/SelectionImage';

export interface ReliefTypeSelection {
  id: string;
  text: string;
  categories: ExperienceCategory[];
  title?: string;
  imageConfig?: ReliefImageProps;
}

export const SELECTIONS: ReliefTypeSelection[] = [
  {
    id: '1',
    title: 'Dr. Consult for MHT',
    text: 'Get medication for perimenopause or menopause relief, ask questions with an expert.',
    categories: ['mht'],
    imageConfig: {
      alt: 'Doctor avatar',
      src: doctorAvatar,
      backgroundClassNames: 'background',
      imageClassNames: 'avatar',
    },
  },
  {
    id: '2',
    title: 'M4 or Tretinoin',
    text: 'I am here for skincare only',
    categories: ['skin-health'],
    imageConfig: {
      alt: 'Skincare bundle',
      src: skincareBundle,
      backgroundClassNames: 'background blue-white-gradient',
      imageClassNames: 'bottom',
      includeBadge: true,
    },
  },
  {
    id: '3',
    title: 'Vaginal Cream or O-mazing',
    text: 'I am here for vaginal & sexual health only',
    categories: ['vaginal-health', 'sexual-health'],
    imageConfig: {
      alt: 'Vaginal care rx bundle',
      src: vaginalBundle,
      mobileSrc: vaginalBundleSmall,
      backgroundClassNames: 'background',
      imageClassNames: 'fullWidth',
      includeBadge: true,
    },
  },
  {
    id: '4',
    title: 'Oral Minoxidil',
    text: 'I am here for hair health only',
    categories: ['hair-health'],
    imageConfig: {
      alt: 'hair care bottle',
      src: hair,
      backgroundClassNames: 'background blue-white-gradient',
      imageClassNames: 'extra-tall',
      includeBadge: true,
    },
  },
  {
    id: '5',
    title: 'Alloy Synbiotic',
    text: 'I am here for gut health only',
    categories: ['gut-health'],
    imageConfig: {
      alt: 'Synbiotic bottle',
      src: synbiotic,
      backgroundClassNames: 'background blue-white-gradient',
      imageClassNames: 'bottom',
    },
  },
];
