import { useEffect, useRef, useState } from 'react';

import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';
import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';

import { ExperienceComponentModel } from 'models/alloy/experience';

import { useLocation } from 'react-router-dom';
import { ExperienceCategory } from 'common/dist/models/experience';

import MultiSelectImageField from 'components/shared/checkout/MultiSelectImageField';
import { treatmentInterestsSelections } from 'data/checkout-experience/content/treatment-interests';
import { useAppSelector } from 'reducers/alloy_reducer';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { updateLocalPreCustomer } from 'actions/core/app_actions';
import { SelectableCategory } from 'models/components/experience/preferences';
import { fadeQuestions } from 'lib/shared/fadeAndScroll';

export default function TreatmentInterests({ onNext, onBack }: ExperienceComponentModel) {
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    localPreCustomer: { treatmentInterests },
  } = useAppSelector((state) => state.experience);

  const dispatchUpdateLocalPreCustomer = bindActionCreators(updateLocalPreCustomer, dispatch);

  const [selectedCategories, setSelectedCategories] = useState(treatmentInterests);
  const selectionWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fadeQuestions('fade-in-wrapper', selectionWrapper);
  }, []);

  const removeCategory = (category: SelectableCategory, categories: SelectableCategory[]) =>
    setSelectedCategories(categories.filter((c) => c !== category));

  const addCategory = (
    category: SelectableCategory,
    categories: SelectableCategory[] | undefined
  ) =>
    categories?.length
      ? setSelectedCategories([category, ...categories.filter((c) => c !== 'none')])
      : setSelectedCategories([category]);

  const handleNone = () => {
    if (selectedCategories && selectedCategories.includes('none')) {
      setSelectedCategories([]);
    } else if (selectedCategories) {
      setSelectedCategories(['none']);
    } else {
      addCategory('none', selectedCategories);
    }
  };

  const onSelectCategory = (category: SelectableCategory) => {
    if (category === 'none') {
      handleNone();
    } else if (selectedCategories && selectedCategories.includes(category)) {
      removeCategory(category, selectedCategories);
    } else {
      addCategory(category, selectedCategories);
    }
  };

  const onSubmit = () => {
    const filteredCategories = selectedCategories?.filter(
      (c) => c !== 'none'
    ) as ExperienceCategory[];
    const collatedCategories: ExperienceCategory[] = ['mht', ...filteredCategories];

    dispatchUpdateLocalPreCustomer({
      treatmentInterests: selectedCategories,
    });

    fadeQuestions('fade-out-wrapper', selectionWrapper);
    setTimeout(() => onNext(collatedCategories), 400);
  };

  return (
    <Layout title='Treatment Interests - Alloy' desc='' noBars>
      <TopBannerWithProgress percentComplete={getPercentComplete(location)} onBack={onBack} />

      <div className='questionnaire-wrapper' ref={selectionWrapper}>
        <CheckoutExperienceSection>
          <div className='enhancers-wrapper'>
            <h1 className='content-title'>
              Your doctor will help you find the best menopause hormone treatment. Are you
              interested in any other categories?
            </h1>

            {treatmentInterestsSelections.map((s) => (
              <MultiSelectImageField
                option={s}
                key={s.id}
                selectedIds={selectedCategories ?? []}
                onSelect={() => onSelectCategory(s.id as SelectableCategory)}
              />
            ))}
          </div>
        </CheckoutExperienceSection>
      </div>

      <BottomBar>
        <button
          className='primary-button'
          type='submit'
          disabled={!selectedCategories?.length}
          onClick={onSubmit}
        >
          Next
        </button>
      </BottomBar>
    </Layout>
  );
}
