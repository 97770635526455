import { ExperienceValidationKey } from 'lib/checkout-experience/validation';
import { ExperienceFlowStep } from 'models/alloy/experience';

import Checkout from 'pages/checkout-experience/shared/Checkout';
import Name from 'pages/checkout-experience/shared/Name';
import ReliefType from 'pages/checkout-experience/shared/ReliefType';
import Register from 'pages/checkout-experience/shared/auth/Register';
import Verification from 'pages/checkout-experience/shared/auth/Verification';

export const otcSteps: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.name,
    component: Name,
    path: 'name',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.reliefType,
    component: ReliefType,
    path: 'relief-type',
    routeType: 'PUBLIC',
  },

  {
    validationKey: ExperienceValidationKey.register,
    component: Register,
    path: 'register',
    routeType: 'AUTH',
  },
  {
    validationKey: ExperienceValidationKey.verification,
    component: Verification,
    path: 'verification',
    routeType: 'AUTH',
  },

  {
    validationKey: ExperienceValidationKey.checkout,
    component: Checkout,
    path: 'checkout',
    routeType: 'PRIVATE',
  },
];
