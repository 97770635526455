import OrdersWrapper from 'components/dashboard/account/order-history/OrdersWrapper';
import AccountContainer from 'containers/dashboard/AccountContainer';
import DashboardContainer from 'containers/dashboard/DashboardContainer';
import useMarkNotificationsAsRead from 'hooks/shared/useMarkNotificationsAsRead';

export default function OrderHistory() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['ORDER_SHIPPED']);

  return (
    <DashboardContainer title='Order History | Alloy' desc='' currentPage='account'>
      <AccountContainer title='Your Account' currentPage='order history'>
        <OrdersWrapper />
      </AccountContainer>
    </DashboardContainer>
  );
}
