import checkmarkBlack from 'assets/images/checkmark-black.png';

import { getNumberOfDays } from 'lib/shared/shipping';

import AddressBlock from '../submit/summary/AddressBlock';
import ShippingMethodBlock from '../submit/summary/ShippingMethodBlock';
import { useAppSelector } from 'reducers/alloy_reducer';
import ShipOptionBlock from 'components/request-experience/shipping/ShipOptionBlock';
import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';

interface Props {
  isAddOnTreatmentPlan: boolean;
}

export default function Shipping({ isAddOnTreatmentPlan }: Props) {
  const cart = useAppSelector((state) => state.treatmentPlan.treatmentPlanCart);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  return (
    <div className='tp-checkout-ship-wrapper'>
      <p className='checkout-ship-title'>Shipment information</p>

      <p className='checkout-ship-text bottom-padding'>
        <img src={checkmarkBlack} alt='checkmark' className='checkout-ship-checkmark' />
        Your order will ship in {getNumberOfDays(cart.shipping)}
      </p>

      <AddressBlock />

      {isAddOnTreatmentPlan && !!activeSubs.length ? <ShipOptionBlock /> : <ShippingMethodBlock />}
    </div>
  );
}
