import { getGroupedCartTotals } from 'lib/shared/cart';
import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  onPlaceOrder: () => void;
}

export default function OrderButton({ onPlaceOrder }: Props) {
  const cart = useAppSelector((state) => state.treatmentPlan.treatmentPlanCart);
  const isLoadingTotals = useAppSelector((state) => state.treatmentPlan.isLoadingTotals);

  const { total } = getGroupedCartTotals(cart);

  return (
    <div className='bottom-order d-block d-md-none'>
      <button
        className='primary-button'
        onClick={onPlaceOrder}
        disabled={cart.products.length === 0 || isLoadingTotals}
      >
        {isLoadingTotals ? 'Place order' : `Place order - $${total.toFixed(2)}`}
      </button>
    </div>
  );
}
