import { MaskField } from './MaskField';

interface Props {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  classes?: string;
  isError?: boolean;
}

export const ZipCodeField = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  classes = 'field-input',
  isError,
}: Props) => (
  <MaskField
    name={name}
    label={label}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
    placeholder={placeholder}
    value={value}
    classes={classes}
    onChange={(v) => onChange(v?.slice(0, 5) ?? '')}
    isError={isError}
  />
);
