import { Customer, TaxesShipping, getCalculatedTax } from 'client/dist/generated/alloy';

export const buildDataForCalculation = (
  discount: number,
  shipping: number,
  productPricesInCents: number[],
  customer: Customer
): TaxesShipping => {
  const divisibleDiscount: string = (discount / productPricesInCents.length).toFixed(2) ?? '0.0';

  const { zip, stateAbbr, city, shippingAddressLineOne } = customer;

  return {
    from_country: 'US',
    from_zip: '10012',
    from_state: 'NY',
    from_city: 'New York',
    from_street: '31 Bond St',
    to_country: 'US',
    to_zip: zip!,
    to_state: stateAbbr!,
    to_city: city!,
    to_street: shippingAddressLineOne!,
    shipping: shipping,
    line_items: productPricesInCents.map((priceInCents) => ({
      quantity: 1,
      unit_price: priceInCents / 100,
      discount: Number(divisibleDiscount),
    })),
  };
};

export const calculateTaxes = async (data: TaxesShipping) => {
  const taxJarResponse = await getCalculatedTax(data);

  return parseFloat((taxJarResponse.tax.amount_to_collect as number).toFixed(2));
};
