import { PaymentElement } from '@stripe/react-stripe-js';
import { StripePaymentMethod } from 'client/dist/generated/alloy';

import Divider from 'components/checkout-experience/Divider';
import PaymentMethodCard from 'components/checkout-experience/PaymentMethodCard';

import { paymentElementTabOptions } from 'lib/dashboard/stripe';

interface Props {
  setAllInputsComplete: (isComplete: boolean) => void;
  paymentMethods: StripePaymentMethod[];
  selectedPaymentMethod: StripePaymentMethod | null;
  setSelectedPaymentMethod: (pm: StripePaymentMethod | null) => void;
}

export default function PaymentOptionsBlock({
  setAllInputsComplete,
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}: Props) {
  return (
    <div className='ce-payment-options-block'>
      <PaymentElement
        id='payment-element'
        options={paymentElementTabOptions}
        onChange={(e) => setAllInputsComplete(e.complete)}
      />

      {paymentMethods.length !== 0 && (
        <div className='payment-other-options-wrapper'>
          <Divider text='Or' />

          <div className='pm-card-list'>
            {paymentMethods.map((pm, index) => (
              <PaymentMethodCard
                paymentMethod={pm}
                setPaymentMethod={(pm: StripePaymentMethod | null) => setSelectedPaymentMethod(pm)}
                selectedPaymentMethod={selectedPaymentMethod}
                key={index}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
