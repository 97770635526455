import { ToWords } from 'to-words';

/**
 * Use the ToWords library to help convert numbers to words
 *
 * @param num number
 * @returns string
 */
export const convertNumberToWords = (num: number): string => {
  const toWords = new ToWords();

  return toWords.convert(num);
};
