import { Question } from 'common/dist/models/questionnaire';
import { SubmissionAnswer } from 'common/dist/models/submission-answer';
import { QuestionnaireAnswers } from 'models/components/questionnaire';
import { findOptionFollowUp, findQuestion } from './question';

const buildSubmissionAnswerFromQuestion = (
  question: Question,
  answerOrId: string,
  questionIndex: number,
  answerIndex?: number
): SubmissionAnswer => {
  const { mdiCaseQuestion } = question;

  let answer = answerOrId;
  let answerSlug = undefined;

  if (question.type === 'SingleChoice' || question.type === 'MultipleChoice') {
    const findAnswer = question.options.find((opt) => opt.slug === answerOrId);

    if (findAnswer) {
      answer = findAnswer.answer;
      answerSlug = findAnswer.slug;
    }
  }

  // For height question, the answer slug is "how-tall-are-you-{INCHES}" and we need the inches for submission
  // so we can just grab the last value in the answer slug
  if (question.slug === 'how-tall-are-you') {
    const splitAnswerSlug = answerOrId.split('-');
    answer = splitAnswerSlug[splitAnswerSlug.length - 1];
  }

  return {
    slug: answerSlug ?? question.slug,
    questionContent: question.title,
    answer,
    important: question.isImportant,
    mdiCaseQuestion,
    position: answerIndex ?? questionIndex,
  };
};

export const getAnswersForSubmission = (
  questions: Question[],
  answers: QuestionnaireAnswers
): SubmissionAnswer[] => {
  const submissionAnswers: SubmissionAnswer[] = [];

  Object.entries(answers).forEach(([slug, values], questionIndex) => {
    const question = findQuestion(questions, slug);
    const followUpQuestion = findOptionFollowUp(questions, slug);

    if (question) {
      // MultipleChoice
      if (Array.isArray(values)) {
        values.forEach((value, answerIndex) => {
          submissionAnswers.push(
            buildSubmissionAnswerFromQuestion(question, value, questionIndex, answerIndex)
          );
        });
      } else {
        submissionAnswers.push(buildSubmissionAnswerFromQuestion(question, values, questionIndex));
      }
    }

    if (!!followUpQuestion) {
      submissionAnswers.push(
        buildSubmissionAnswerFromQuestion(
          followUpQuestion as Question,
          values as string,
          questionIndex,
          submissionAnswers.length
        )
      );
    }
  });

  return submissionAnswers;
};
