import rxIcon from 'assets/svg/checkout-experience/review/rx-icon-no-border.svg';

import { FC } from 'react';

export interface ReliefImageProps {
  src: string;
  mobileSrc?: string;
  alt: string;
  backgroundClassNames: string;
  imageClassNames: string;
  includeBadge?: boolean;
}

/**
 * This is a reusable selection card image with background,
 * allowing for position and background style configuration
 */

export const SelectionImage: FC<ReliefImageProps> = ({
  src,
  alt,
  backgroundClassNames,
  imageClassNames,
  includeBadge,
}) => {
  return (
    <div className={backgroundClassNames}>
      <img src={src} alt={alt} className={imageClassNames} />
      {includeBadge && <img alt='Prescription Included' src={rxIcon} className='rx-icon' />}
    </div>
  );
};
