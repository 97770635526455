import { Question } from 'common/dist/models/questionnaire';
import { SubmissionAnswer } from 'common/dist/models/submission-answer';
import { findQuestion } from './question';

/**
 * This fillAnswer util is when we need to pass a value from the api to MDI.
 * The question that receives this filled answer is often hidden from the UI using the type: Hidden inside the json file
 *
 * @param questions: Question[] - The array of questions within the questionnaire
 * @param answers: SubmissionAnswer[] - The array of answers populated by going through the UI questionnaire
 * @param questionSlug: string - The question slug that is hidden from the UI but needs to have an answer to send to MDI
 * @param answer: string - The answer to fill the hidden question with
 * @returns SubmissionAnswer - The filled answer ready to be sent to MDI
 */

export const fillAnswer = (
  questions: Question[],
  answers: SubmissionAnswer[],
  questionSlug: string,
  answer: string
): SubmissionAnswer => {
  const question = findQuestion(questions, questionSlug) as Question;
  const position = answers.find((ia) => ia.slug === questionSlug)?.position;

  const { slug, title: questionContent, isImportant: important, mdiCaseQuestion } = question;

  return {
    slug,
    questionContent,
    answer,
    important,
    mdiCaseQuestion,
    position,
  };
};
