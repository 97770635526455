import { useAppSelector } from 'reducers/alloy_reducer';

import SectionWrapper from 'components/core/layout/SectionWrapper';
import HeaderBlock from '../core/HeaderBlock';
import ProgressBlock from '../core/ProgressBlock';
import InfoSection from '../../shared/sections/Info';
import TestimonialsSection from '../../shared/sections/Testimonials';

import DashboardContainer from 'containers/dashboard/DashboardContainer';

export default function DoctorReview() {
  const customer = useAppSelector((state) => state.alloy.customer!!);

  return (
    <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
      <HeaderBlock title='Treatment plan for' fancyTitle={customer.firstName ?? ''} />

      <SectionWrapper
        sectionClasses='dashboard-treatment-plan-section extra-bottom-padding'
        rowClasses='justify-content-center'
      >
        <div className='col-12 col-md-7'>
          <ProgressBlock
            title='Check back soon.'
            description='Your doctor will review your health history, messages, and requests to create a treatment plan for you. Check your inbox for updates on your treatment plan!'
            barTitles={['Intake', 'Doctor review', 'Treatment plan']}
            currentIndex={1}
          />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />
    </DashboardContainer>
  );
}
