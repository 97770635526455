import {
  applyPromoCode,
  removePromoCode,
  SubscriptionWithRenewal,
  useGetAllSubscriptionsForCustomer,
} from 'client/dist/generated/alloy';
import { useState } from 'react';

import DiscountBlock from '../drawers/content/Discount';

import { showSuccessNotification } from 'components/core/Notification';
import { convertCentsToDollars } from 'lib/shared/convert';
import { getTotalsByInvoice } from 'lib/shared/invoice/calculations';

interface Props {
  subscription: SubscriptionWithRenewal;
}

export default function OrderSummaryCardBlock({ subscription }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const { mutate } = useGetAllSubscriptionsForCustomer();

  const { upcomingInvoice } = subscription;

  if (!upcomingInvoice) return <></>;

  const onApplyDiscount = async (code: string) => {
    try {
      setIsLoading(true);

      await applyPromoCode(subscription.stripeSubscriptionId, code);
      await mutate();

      showSuccessNotification('Promo code successfully applied to the next shipment');

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onRemoveDiscount = async () => {
    try {
      setIsLoading(true);

      await removePromoCode(subscription.stripeSubscriptionId);
      await mutate();

      showSuccessNotification('Promo code successfully removed from the next shipment');

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const { subtotal, tax, shipping, discount, total } = getTotalsByInvoice(upcomingInvoice);

  return (
    <div className='manage-summary-card-block'>
      <h3 className='card-title'>Order Summary</h3>

      <DiscountBlock
        activeDiscountName={subscription.upcomingInvoice?.promotionCode?.code}
        isLoading={isLoading}
        onApply={onApplyDiscount}
        onRemove={onRemoveDiscount}
      />

      <div className='summary-content-wrapper'>
        {/* Subtotal */}
        <div className='content-row'>
          <p className='content-title'>
            <span className='text-bold'>Subtotal</span> (3-month supply)
          </p>

          <p className='content-text'>
            <span className='text-bold'>${convertCentsToDollars(subtotal)}</span>
          </p>
        </div>

        {/* Discount */}
        {discount !== 0 && (
          <div className='content-row'>
            <p className='content-title'>Discount</p>

            <p className='content-text'>-${convertCentsToDollars(discount)}</p>
          </div>
        )}

        {/* Tax */}
        <div className='content-row'>
          <p className='content-title'>Tax</p>

          <p className='content-text'>
            <span className='text-grey'>${convertCentsToDollars(tax)}</span>
          </p>
        </div>

        {/* Shipping */}
        <div className='content-row'>
          <p className='content-title'>Shipping</p>

          <p className='content-text'>
            {shipping === 0 ? 'FREE' : `$${convertCentsToDollars(shipping)}`}
          </p>
        </div>

        {/* Total */}
        <div className='content-row'>
          <p className='content-title'>
            <span className='text-bold'>Order total</span>
          </p>

          <p className='content-text'>
            <span className='text-bold'>${convertCentsToDollars(total)}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
