import { useEffect } from 'react';

import SEO from 'components/core/SEO';

import NavBar from 'containers/NavBar';
import Footer from 'containers/Footer';

interface Props {
  children: any;
  title: string;
  desc: string;
  noBars?: boolean;
}

export default function Layout({ children, title, desc = '', noBars = false }: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO title={title} description={desc} />

      {!noBars && <NavBar />}

      <main className='bg-content' role='main'>
        {children}
      </main>

      {!noBars && <Footer />}
    </>
  );
}
