/**
 *
 * Calculate the bmi for a user, the reason the height and weight are undefined is because of local storage atm
 * and not having types
 *
 * @param height : string | undefined
 * @param weight : string | undefined
 * @returns bmi : string
 */
export const getBmi = (height?: string, weight?: string): string => {
  if (!height || !weight) {
    return '0.0';
  }

  return ((parseInt(weight) / parseInt(height) / parseInt(height)) * 703).toFixed(2);
};
