import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import {
  ProductFrequencyRenewal,
  SubscriptionWithRenewal,
  useGetAllSubscriptionsForCustomer,
} from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { RecurrenceType } from 'common/dist/products/productFrequency';
import { useEffect, useState } from 'react';

import alloyProductFiller from 'assets/images/shared/alloy-product-filler.png';

import DomProductRegistry from 'client/dist/product/productRegistry';
import { convertCentsToDollars } from 'lib/shared/convert';
import { getDeepProductIdsFrom, getPriceForCombinedProducts } from 'lib/shared/product';
import { getProductNameWithDosage } from 'lib/shared/product/name';
import { flatten } from 'lodash';

interface Props {
  subscriptions?: SubscriptionWithRenewal[];
  productsWithRenewal: ProductFrequencyRenewal[];
  showDosage?: boolean;
  shouldGroup?: boolean;
  onEdit?: (productIds: number[]) => void;
}

export default function ProductsList({
  subscriptions = [],
  productsWithRenewal,
  showDosage = false,
  shouldGroup = true,
  onEdit,
}: Props) {
  const [products, setProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { isLoading: isLoadingSubs } = useGetAllSubscriptionsForCustomer();

  const disableList =
    !!subscriptions.length && subscriptions.some((sub) => sub.status !== 'ACTIVE');

  useEffect(() => {
    fetchProducts();
  }, [isLoadingSubs, JSON.stringify(subscriptions)]);

  const fetchProducts = async () => {
    if (shouldGroup) {
      const groupedContentfulProducts = await DomProductRegistry.get().getRecurringProductsForV2(
        productsWithRenewal.map((pfr) => pfr.product)
      );

      setProducts(groupedContentfulProducts);
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className='products-list-wrapper'>
        <div className='product-block'>
          <div className='product-img-wrapper'>
            <Skeleton variant='rectangular' width={100} height={100} />
          </div>

          <div className='product-content-wrapper'>
            <Skeleton variant='text' width={150} />

            <Skeleton variant='text' width={100} />
          </div>
        </div>
      </div>
    );
  }

  if (!shouldGroup) {
    return (
      <div className={classNames('products-list-wrapper', disableList && 'disable')}>
        {productsWithRenewal.map((pfr, index) => {
          const prescription = pfr.prescription;

          const foundSubscription = subscriptions.find((sub) =>
            sub.products.find((p) => pfr.product.id === p.product.id)
          );

          const prescriptionActiveSub = !!prescription && foundSubscription?.status === 'ACTIVE';

          return (
            <div className='product-block' key={index}>
              <div className='product-img-content-wrapper'>
                <div className='product-img-wrapper'>
                  <img src={alloyProductFiller} alt={pfr.product.name} className='product-img' />
                </div>

                <div className='product-content-wrapper'>
                  <p className='content-title'>{pfr.product.name}</p>

                  <p className='content-text'>${convertCentsToDollars(pfr.product.priceInCents)}</p>

                  {prescriptionActiveSub && (
                    <p className='content-text'>
                      {prescription.fillsRemaining} refill
                      {prescription.fillsRemaining !== 1 && 's'} left
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classNames('products-list-wrapper', disableList && 'disable')}>
      {products.map((gcpList, listIndex) =>
        gcpList.map((gcp, index) => {
          const { alloyProduct, contentfulProduct } = gcp;

          const recurrenceType = alloyProduct.parent[0].recurrenceType;

          const isRx = !contentfulProduct.fields.categories.every(
            (category) => category === 'gut-health'
          );

          const foundSubscription = subscriptions.find((sub) =>
            sub.products.find((p) => alloyProduct.parent.map((pf) => pf.id).includes(p.product.id))
          );

          const prescription = !!foundSubscription
            ? foundSubscription.products.find((p) =>
                alloyProduct.parent.map((pf) => pf.id).includes(p.product.id)
              )?.prescription
            : undefined;

          const namesAndDosage = showDosage
            ? getProductNameWithDosage(gcp)
            : [{ cleanName: contentfulProduct.fields.title }];

          const prescriptionActiveSub = !!prescription && foundSubscription?.status === 'ACTIVE';
          const prescriptionInactiveSub = !!prescription && foundSubscription?.status !== 'ACTIVE';

          const isBundledProduct = !!contentfulProduct.fields.bundledPrice && gcpList.length > 1;

          const bundledPrice = contentfulProduct.fields.bundledPrice;
          const unbundledPrice = contentfulProduct.fields.pricePer3Months;

          // grab the other product in the gcpList (only used if isBundledProduct=true above)
          const parent = gcpList[gcpList.length - index - 1];

          return (
            <div
              className={classNames('product-block', !!onEdit && 'add-border')}
              key={`${listIndex}-${index}`}
            >
              <div className='product-img-content-wrapper'>
                <div className='product-img-wrapper'>
                  {isRx && <span className='product-rx-tag'>rx</span>}

                  <img
                    src={contentfulProduct.fields.photo.fields.file.url}
                    alt={contentfulProduct.fields.title}
                    className='product-img'
                  />
                </div>

                <div className='product-content-wrapper'>
                  {namesAndDosage.map(({ cleanName, dosage }, pIndex) => (
                    <p className='content-title' key={pIndex}>
                      {cleanName}
                      {!!dosage && <span className='title-dosage-tag'>{dosage}</span>}
                    </p>
                  ))}

                  {isBundledProduct && !!unbundledPrice && !!bundledPrice && (
                    <p className='content-tag'>
                      ${(unbundledPrice - bundledPrice).toFixed(0)} off with purchase of{' '}
                      {getProductNameWithDosage(parent)[0].cleanName}
                    </p>
                  )}

                  <p className='content-text'>
                    {isBundledProduct ? (
                      <>
                        <span className='text-orange'>${bundledPrice?.toFixed(2)}</span>
                        <span className='text-strike-through'>${unbundledPrice?.toFixed(2)}</span>
                      </>
                    ) : (
                      <>${convertCentsToDollars(getPriceForCombinedProducts(gcp))}</>
                    )}{' '}
                    ({recurrenceType === 'RECURRING' ? '3-month supply' : 'One-time fill'})
                  </p>

                  {prescriptionActiveSub && (
                    <p className='content-text'>
                      {prescription.fillsRemaining} refill
                      {prescription.fillsRemaining !== 1 && 's'} left
                    </p>
                  )}

                  {prescriptionInactiveSub && (
                    <p className='content-text'>
                      Refills: {prescription.fillsRemaining}
                      <span className='text-tag'>Paused</span>
                    </p>
                  )}
                </div>
              </div>

              {!!onEdit && flatten(products).length > 1 && (
                <div className='product-action-wrapper'>
                  <button
                    className='action-link'
                    onClick={() => onEdit(getDeepProductIdsFrom(gcp))}
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
}
