import { enqueueSnackbar } from 'notistack';

export const showSuccessNotification = (message: string, persist: boolean = false) =>
  enqueueSnackbar(message, { variant: 'success', persist });

export const showErrorNotification = (message: string, persist: boolean = false) => {
  enqueueSnackbar(message, { variant: 'error', persist });
};

export default {
  showErrorNotification,
};
