import React from 'react';

import { Link } from 'react-router-dom';

import rightArrow from 'assets/svg/core/arrow-right-orange-short.svg';
import { ProductCategory } from 'client/dist/generated/alloy';

interface EmailUsModel {
  btnClass?: string;
}

interface GetStartedLinkModel {
  title: string;
  isCheckoutExperience?: boolean;
  hideArrow?: boolean;
  isOtc?: boolean;
  categories: ProductCategory[];
}

export const EmailUs: React.FC<EmailUsModel> = ({ btnClass = '' }) => {
  return (
    <a className={btnClass} href='mailto:support@myalloy.com'>
      support@myalloy.com
    </a>
  );
};

export const GetStartedLink: React.FC<GetStartedLinkModel> = ({
  title,
  hideArrow = false,
  categories = ['mht'],
}) => {
  const link: string = `/checkout-experience?categories[]=${categories.join(',')}`;

  return (
    <Link to={link} className='primary-link-button'>
      {title}
      {!hideArrow && <img src={rightArrow} alt='right arrow' className='btn-arrow' />}
    </Link>
  );
};
