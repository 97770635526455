import { Auth } from 'aws-amplify';

// TODO move usages to awsService

/**
 * If error is obviously an issue around email format, report it as email format issue?
 * FIXME just validate the field before sending to aws?
 *
 * @param error
 */
const handleAwsAmplifyError = (error: any) => {
  if (error?.name === 'InvalidParameterException' && error?.message?.includes('validation error')) {
    throw new Error('Incorrect email format.');
  }
  throw error;
};

export const signIn = async (email: string, password: string) => {
  try {
    await Auth.signIn(email, password);
  } catch (error) {
    handleAwsAmplifyError(error);
  }
};

export const signOut = async () => {
  await Auth.signOut();
};

export const signUp = async (email: string, password: string) => {
  try {
    return await Auth.signUp(email, password);
  } catch (error) {
    handleAwsAmplifyError(error);
  }
};

export const resendVerificationCode = async (email: string) => {
  try {
    await Auth.resendSignUp(email);
  } catch (error) {
    handleAwsAmplifyError(error);
  }
};

export const requestForgotPasswordCode = async (email: string) => {
  try {
    await Auth.forgotPassword(email);
  } catch (error) {
    handleAwsAmplifyError(error);
  }
};

export const confirmSignUpWithCode = async (email: string, code: string) => {
  try {
    await Auth.confirmSignUp(email, code);
  } catch (error) {
    handleAwsAmplifyError(error);
  }
};

export const updatePasswordWithCode = async (email: string, code: string, newPassword: string) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  } catch (error) {
    handleAwsAmplifyError(error);
  }
};

export const updatePassword = async (oldPassword: string, newPassword: string) => {
  const authUser = await Auth.currentAuthenticatedUser();

  return await Auth.changePassword(authUser, oldPassword, newPassword);
};

export const currentSession = async () => {
  try {
    return await Auth.currentSession();
  } catch (e) {
    // currentSession throws an error if the user is not authenticated. swallow
    return null;
  }
};
