import chevronDown from 'assets/svg/core/chevron-down.svg';

import classNames from 'classnames';
import {
  ProductCategory,
  useGetAllSubscriptionsForCustomer,
  useGetPendingSwitch,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { EmailUs } from 'components/core/Buttons';
import { getSingleSupplyProducts } from 'lib/core/customer/getSingleSupplyProducts';
import { isMht, isPrescription } from 'lib/shared/contentful';
import ProductRegistry from 'client/dist/product/productRegistry';
import { useEffect, useState } from 'react';
import EmptyStateBlock from '../core/EmptyPlanBlock';
import MessageBlock from '../core/MessageBlock';
import ProgressBlock from '../core/ProgressBlock';
import ActiveProduct from '../products/ActiveProduct';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';
import DomProductRegistry from 'client/dist/product/productRegistry';
import { uniq } from 'lodash';
import {
  getActiveProductStates,
  getSeparatedGroupedContentfulProducts,
  sortWithSwitchingFirst,
} from 'lib/shared/product';
import { DeepProduct } from 'common/dist/products/productFrequency';

interface Props {
  pendingApprovalProducts: GroupedContentfulProduct[];
}

export default function ActiveProductsBlock({ pendingApprovalProducts }: Props) {
  const [activeProducts, setActiveProducts] = useState<GroupedContentfulProduct[]>([]);
  const [requestedProduct, setRequestedProduct] = useState<GroupedContentfulProduct | undefined>(
    undefined
  );
  const [switchableProducts, setSwitchableProducts] = useState<DeepProduct[]>([]);

  const { data: treatmentPlan } = useGetTreatmentPlan();
  const { data: pendingSwitch } = useGetPendingSwitch();
  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const { activeSubs, pausedSubs } = getSubscriptionsWithStatus(subscriptions);
  const activeSubscriptions = [...activeSubs, ...pausedSubs];

  const singleSupplyProducts = getSingleSupplyProducts(subscriptions, treatmentPlan);

  const isAwaitingQueueOrCX =
    treatmentPlan!.status === 'PENDING_CX' || treatmentPlan!.status === 'PROCESSING';

  /**
   * Variables used from treatment plan to show various mini states inside each group (active prescriptions, add ons, etc)
   */
  const CATEGORIES: ProductCategory[] = ['mht', 'birth-control', 'non-hormonal-treatment'];

  const isAwaitingDoctor = treatmentPlan!.status === 'AWAITING_DOCTOR';
  const hasMhtOrCompletedConsult =
    activeSubscriptions
      .flatMap((sub) => sub.products.map((p) => p.product.category))
      .some((category) => CATEGORIES.includes(category)) || treatmentPlan!.checkedOutWithConsult;

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const purchased = [
      ...activeSubscriptions.flatMap((sub) => sub.products.map((pfr) => pfr.product)),
      ...singleSupplyProducts.map((pfr) => pfr.product),
    ];

    const switchable = await DomProductRegistry.get().getSwitchableProducts();

    setSwitchableProducts(switchable);

    const purchasedProducts = (
      await getSeparatedGroupedContentfulProducts(purchased, switchable)
    ).sort(
      (a, b) =>
        Number(isMht(b.contentfulProduct)) - Number(isMht(a.contentfulProduct)) ||
        Number(isPrescription(b.contentfulProduct)) - Number(isPrescription(a.contentfulProduct))
    ); // only for active products that the customer has in sub / one time, we need to sort how we do everywhere
    // else with the mht / prescriptions at the top and then everything else toward the bottom!

    if (pendingSwitch && pendingSwitch.requestedProducts.length > 0) {
      const requestedGroupedContentful = (
        await ProductRegistry.get().getRecurringProductsForV2(pendingSwitch.requestedProducts)
      ).flat();

      setRequestedProduct(requestedGroupedContentful[0]);
    }

    setActiveProducts(purchasedProducts);
  };

  const toggleList = (id: string) => {
    const listElement = document.getElementById(id);

    if (listElement) {
      if (listElement.classList.contains('active')) {
        listElement.classList.remove('active');
      } else {
        listElement.classList.add('active');
      }
    }
  };

  return (
    <div
      className={classNames(
        'tp-products-list-wrapper active',
        pendingApprovalProducts.length !== 0 && 'closable',
        isAwaitingDoctor && 'add-bottom-margin'
      )}
      id='active-products-list'
    >
      {pendingApprovalProducts.length !== 0 && (
        <div className='tp-header'>
          <p className='tp-subtitle'>Active treatments</p>

          <button
            className='tp-chevron-btn'
            onClick={() => {
              toggleList('active-products-list');
            }}
          >
            <img src={chevronDown} alt='chevron-down' />
          </button>
        </div>
      )}

      <div className='products-list'>
        {/* Purchased products that are either in sub or not on sub (one time) */}
        {activeProducts
          .sort((a, b) => sortWithSwitchingFirst(a, b, switchableProducts, requestedProduct))
          .map((gcp, i) => {
            const {
              foundSubscription,
              foundPrescriptionProduct,
              foundProductInSubscription,
              foundProductInSwitchableProducts,
              foundSwitch,
            } = getActiveProductStates(
              gcp,
              activeSubscriptions,
              switchableProducts,
              requestedProduct,
              treatmentPlan!,
              pendingSwitch
            );

            if (foundSwitch && requestedProduct) {
              return (
                <ActiveProduct
                  key={i}
                  product={requestedProduct}
                  isProcessing
                  showDosage={pendingSwitch?.isSwitchFormFactor}
                >
                  <span className='product-hug'>
                    {pendingSwitch?.isSwitchDose ? 'New dose requested' : 'Requested from doctor'}
                  </span>

                  <ProgressBlock
                    description='Your doctor will review your request and, once approved, your new dosage will be included with the rest of your normal shipment.'
                    barTitles={['Request', 'Doctor review', 'Approved']}
                    currentIndex={1}
                    hideButton
                  />

                  <p className='product-text'>
                    Any questions about your order at all, email us at <EmailUs />. We're here for
                    you!
                  </p>
                </ActiveProduct>
              );
            }
            // the product below is shown for any recurring product that would be in a subscription
            else if (foundSubscription) {
              return (
                <ActiveProduct
                  key={i}
                  product={{
                    alloyProduct: gcp.alloyProduct,
                    contentfulProduct: gcp.contentfulProduct,
                    renewal: foundProductInSubscription?.renewal,
                    prescription: foundProductInSubscription?.prescription,
                  }}
                  nextRecurrenceOn={foundSubscription.nextRecurrenceOn.toISOString()}
                  subscription={foundSubscription}
                  isSwitchable={foundProductInSwitchableProducts}
                  switchableProductIds={uniq(switchableProducts.map((sp) => sp.productId))}
                />
              );
            }
            // the product below is shown when we have a one time product that would not have a subscription
            else {
              return (
                <ActiveProduct
                  key={i}
                  product={{
                    alloyProduct: gcp.alloyProduct,
                    contentfulProduct: gcp.contentfulProduct,
                    prescription: foundPrescriptionProduct?.prescription,
                    renewal: foundPrescriptionProduct?.renewal,
                  }}
                />
              );
            }
          })}
      </div>

      {/* the encounter || pending charge is either in queue or in cx land for treatment plan approvals */}
      {isAwaitingQueueOrCX && (
        <MessageBlock
          title='Check back soon.'
          text={
            <>
              If your doctor has finalized your treatment plan, your recommended treatments should
              appear within 10 minutes. If it is taking longer, our support team is likely managing
              your order. Please reach out to <EmailUs /> if you have any questions.
            </>
          }
        />
      )}

      {/* waiting on doctor to complete the encounter for treatment plan approvals */}
      {isAwaitingDoctor && (
        <ProgressBlock
          title='Menopausal hormonal treatment plan'
          description='Your doctor will review your health history, messages, and requests, check back soon to confirm your treatment plan. Check your inbox for updates on your treatment plan!'
          barTitles={['Intake', 'Doctor review', 'Treatment plan']}
          currentIndex={1}
          hideButton
        />
      )}

      {/* encounter for treatment plan not in progress AND doesn't have a consult checkout */}
      {!isAwaitingDoctor && !hasMhtOrCompletedConsult && (
        <EmptyStateBlock
          title='Hormonal Menopause Treatment Plan'
          buttonTitle='Start your intake now'
        />
      )}
    </div>
  );
}
