import Forgot from 'pages/authentication/Forgot';
import Login from 'pages/authentication/Login';
import ResetRequired from 'pages/authentication/ResetRequired';
import StandaloneVerification from 'pages/authentication/StandaloneVerification';

import SymptomSurvey from 'pages/surveys/SymptomSurvey';

import Messages from 'pages/dashboard-v2/Messages';

import TreatmentPlan from 'pages/dashboard-v2/treatment-plan/TreatmentPlan';

import OrderConfirmation from 'pages/checkout-experience/shared/OrderConfirmation';
import Checkout from 'pages/dashboard-v2/treatment-plan/Checkout';
import OrderHistory from 'pages/dashboard-v3/account/OrderHistory';
import Profile from 'pages/dashboard-v3/account/Profile';
import Referrals from 'pages/dashboard-v3/account/Referrals';
import Home from 'pages/dashboard-v3/Home';
import ManageSubscription from 'pages/dashboard-v3/subscriptions/ManageSubscription';
import Subscriptions from 'pages/dashboard-v3/subscriptions/Subscriptions';
import GenericThankYou from 'pages/other/GenericThankYou';
import Maintenance from 'pages/other/Maintenance';
import RequestConfirmation from 'pages/request-experience/shared/RequestConfirmation';
import DoctorSurvey from 'pages/surveys/DoctorSurvey';
import DoctorSurveyConfirmation from 'pages/surveys/DoctorSurveyConfirmation';

interface Route {
  path: string;
  component: () => JSX.Element;
}

/**
 * private routes are required for customers to be authenticated/logged in
 * in order for them to be accessed
 */
export const PRIVATE_ROUTES: Route[] = [
  {
    path: '/',
    component: process.env.REACT_APP_SHOW_V3_HOME === 'true' ? Home : TreatmentPlan,
  },

  // conditionally allow setting up home page as a route based on flag
  ...(process.env.REACT_APP_SHOW_V3_HOME === 'true'
    ? [
        {
          path: '/home',
          component: Home,
        },
      ]
    : []),

  {
    path: '/treatment-plan',
    component: TreatmentPlan,
  },
  {
    path: '/treatment-plan/checkout',
    component: Checkout,
  },

  {
    path: '/messages',
    component: Messages,
  },

  {
    path: '/subscriptions',
    component: Subscriptions,
  },
  {
    path: '/subscriptions/:id',
    component: ManageSubscription,
  },

  // Account
  {
    path: '/account/profile',
    component: Profile,
  },
  {
    path: '/account/order-history',
    component: OrderHistory,
  },
  {
    path: '/account/referrals',
    component: Referrals,
  },

  // Confirmation stuffs
  {
    path: '/order-confirmation',
    component: OrderConfirmation,
  },
  {
    path: '/request-confirmation',
    component: RequestConfirmation,
  },
  {
    path: '/doctor-survey-confirmation',
    component: DoctorSurveyConfirmation,
  },
  // Doctor survey
  {
    path: '/doctor-survey/*',
    component: DoctorSurvey,
  },
];

/**
 * public routes can be accessed by either authenticated or unauthenticated users
 */
export const PUBLIC_ROUTES: Route[] = [
  // Symptom survey
  {
    path: '/symptomsurvey/*',
    component: SymptomSurvey,
  },

  // Waitlist
  {
    path: '/waitlist',
    component: GenericThankYou,
  },

  // Maintenace + auth/standalone verification
  {
    path: '/maintenance',
    component: Maintenance,
  },
  {
    path: '/standalone-verification',
    component: StandaloneVerification,
  },
];

/**
 * authentication routes can only be accessed when a customer is not authenticated / logged in,
 * these are usually those point of entry routes
 */
export const AUTHENTICATION_ROUTES: Route[] = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/forgot',
    component: Forgot,
  },
  {
    path: '/reset',
    component: ResetRequired,
  },
];
