"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHumanReadableShippingTitle = exports.getTrackingLink = void 0;
const getTrackingLink = (carrier, trackingNumber) => {
    switch (carrier.toUpperCase()) {
        case 'UPS':
            return `https://www.ups.com/track?tracknum=${trackingNumber}&loc=en_US&requester=ST/trackdetails`;
        case 'USPS':
        default:
            return `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${trackingNumber}%2C&tABt=false`;
    }
};
exports.getTrackingLink = getTrackingLink;
const getHumanReadableShippingTitle = (method) => {
    switch (method) {
        case 'TWO_DAY':
            return '1-2 business days.';
        case 'NEXT_DAY':
            return '1 business day.';
        default:
            return '3-5 business days.';
    }
};
exports.getHumanReadableShippingTitle = getHumanReadableShippingTitle;
