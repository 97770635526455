import { useLocation } from 'react-router-dom';
import Layout from '../../containers/Layout';
import { ContentfulBanner } from 'models/contentful/banner';

interface LocationState {
  maintenance: ContentfulBanner;
}

/**
 * Maintenance page - not included in component hierarchy anymore,
 * but left in SCM so its easier to whip it out if we need to take quick downtime
 * (mdi issue or render or what have you)
 */
export default function Maintenance() {
  const location = useLocation();
  const locationState = location.state as LocationState | null;

  return (
    <Layout title='Maintenance | Alloy' desc='Down for Maintenance'>
      <section className='intro-section bg-primary'>
        <div className='container align-items-center'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-8 text-center'>
              <h2>We're cleaning up</h2>
              <p>
                {locationState?.maintenance?.fields?.dashboardText ||
                  'Currently down for maintenance, check back soon!'}
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
