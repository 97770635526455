import chevronRightIcon from 'assets/svg/core/chevron-right.svg';
import { EventType, useGetAllUnreadNotifications } from 'client/dist/generated/alloy';
import { TransformedNotification } from 'lib/dashboard/notifications/transformNotifs';
import { Link } from 'react-router-dom';

export interface Props {
  notification: TransformedNotification;
  onMarkRead: (eventType: EventType) => void;
}

export default function NotificationRow({ notification, onMarkRead }: Props) {
  const { count, href, message, cta, timeFromNow } = notification;
  // for the sake of space, don't display the cta if there are multiples
  const hasMultipleOfSameNotifType = count > 1;

  return (
    <div className='notif-row'>
      <Link to={href} onClick={() => onMarkRead(notification.eventType)}>
        <div className='notif-top-line'>
          <div className='notif-info'>
            <p className='notif-message'>{message}</p>
            {hasMultipleOfSameNotifType ? (
              <span className='count-badge'>{count} New</span>
            ) : (
              <p className='notif-cta'>{cta}</p>
            )}
          </div>

          <img src={chevronRightIcon} alt='right arrow' />
        </div>
        <div className='time-from-now'>{timeFromNow}</div>
      </Link>
    </div>
  );
}

export const EmptyOrErrorNotificationRow = () => {
  const { error } = useGetAllUnreadNotifications();
  const isError = !!error;
  return (
    <div className='empty-notif'>
      {isError
        ? 'Oops! There was an error loading your notifications.'
        : `You're caught up! No new notifications at this time.`}
    </div>
  );
};
