import { SharedLocation } from 'common/dist/models/shared/location';
import { NavigateFunction } from 'react-router-dom';

export const handleNextPath = (navigate: NavigateFunction, location: SharedLocation) => {
  const searchParams = new URLSearchParams(location.search);

  const nextPath = searchParams.get('nextPath');

  /**
   * with recent changes, it is no longer needed to update the experience since we can just go to the url
   * and allow it to handle fetching there (which it did anyway!). It was also bugging out when we tried to
   * update and glitching for a sec so now it should be a smooth ride through
   */

  if (!!nextPath) {
    // if the customer is trying to get to another page like settings or shipments, we need to redirect properly there and maintain params
    searchParams.delete('nextPath');

    navigate({
      pathname: '/' + (nextPath || ''),
      search: searchParams.toString(),
    });
  } else {
    //for everything else, just send them to main page along with any search params!
    navigate({ pathname: '/', search: searchParams.toString() });
  }
};
