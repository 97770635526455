import {
  SetupIntent,
  Stripe,
  StripeElements,
  StripeError,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { StripeCard } from 'client/dist/generated/alloy';
import { Address } from 'pages/checkout-experience/shared/Checkout';

export const paymentElementTabOptions: StripePaymentElementOptions = {
  layout: {
    type: 'tabs',
    defaultCollapsed: true,
    radios: true,
    spacedAccordionItems: true,
  },
};

export const paymentElementAccordionOptions: StripePaymentElementOptions = {
  layout: {
    type: 'accordion',
    defaultCollapsed: true,
    radios: true,
    spacedAccordionItems: true,
  },
};

export const formatExpirationDate = (card: StripeCard) => {
  let month = card.exp_month.toString();
  let year = card.exp_year.toString();

  if (month.length === 1) month = `0${month}`;
  if (year.length === 4) year = year.slice(2, 4);

  return `${month}/${year}`;
};

/**
 * Handles creating a setupintent in stripe that can be used to get the payment method id to which we can attach to a specific customer
 * Potentially, we should add billing eventually instead of using shipping as we do in the normal checkout
 */
export const confirmSetupIntent = async (
  stripe: Stripe,
  elements: StripeElements,
  billing: Partial<Address>
): Promise<{ error: StripeError | undefined; setupIntent: SetupIntent | undefined }> => {
  const {
    firstName,
    lastName,
    shippingAddressLineOne,
    shippingAddressLineTwo,
    city,
    stateAbbr,
    zip,
  } = billing;

  const { error, setupIntent } = await stripe.confirmSetup({
    elements,
    redirect: 'if_required',
    confirmParams: {
      payment_method_data: {
        billing_details: {
          name: `${firstName} ${lastName}`,
          address: {
            line1: shippingAddressLineOne,
            line2: shippingAddressLineTwo,
            city,
            state: stateAbbr,
            postal_code: zip,
          },
        },
      },
    },
  });

  return { error, setupIntent };
};
