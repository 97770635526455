import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import VideoBlock from 'components/checkout-experience/upload-video/VideoBlock';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';

import { ExperienceComponentModel } from 'models/alloy/experience';
import { useLocation } from 'react-router-dom';

export default function UploadVideo({ onNext, onBack }: ExperienceComponentModel) {
  const location = useLocation();

  return (
    <Layout title='Upload Video - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={onBack} percentComplete={getPercentComplete(location)} />

      <CheckoutExperienceSection>
        <h1 className='content-title'>Upload a short video</h1>
        <p className='content-text'>
          Telehealth laws in your state require that for additional verification, you record a video
          of yourself. If you'd like, you can upload it now. Otherwise, you can do it later on in
          your dashboard. All of your information will be kept confidential.
        </p>

        <VideoBlock onNext={onNext} />
      </CheckoutExperienceSection>
    </Layout>
  );
}
