import { Question } from 'common/dist/models/questionnaire';
import { SubmissionAnswer } from 'common/dist/models/submission-answer';
import { findQuestionByUserKey } from './question';

export const getAnswerByUserKey = (
  questions: Question[],
  answers: SubmissionAnswer[],
  userKey: string,
  defaultAnswer: string
): string => {
  const question = findQuestionByUserKey(questions, userKey);

  if (question) {
    return answers.find((a) => a.slug === question.slug)?.answer ?? defaultAnswer;
  }

  return defaultAnswer;
};
