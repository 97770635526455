import {
  GetShippingMethods200Item,
  ShippingMethodType,
  useGetShippingMethods,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'reducers/alloy_reducer';
import { bindActionCreators } from '@reduxjs/toolkit';

import { updateTPCart } from 'actions/treatment-plan/cart_actions';

import chevronDownIcon from 'assets/svg/core/chevron-down.svg';

import { convertCentsToDollars } from 'lib/shared/convert';
import { cleanShippingTitle } from 'lib/shared/shipping';

/**
 * Component that handles showing different shipping methods, used within <OrderSummaryBlock />
 * Allows user to update their treatment plan cart with a specific shipping method
 */
export default function ShippingMethodBlock() {
  const dispatch = useDispatch();

  const [showShippingDetails, setShowShippingDetails] = useState<boolean>(false);

  const customer = useAppSelector((state) => state.alloy.customer!!);

  const { data: treatmentPlan = { pendingCharges: [] } } = useGetTreatmentPlan();
  const { data: shippingMethods = [], isLoading: isLoadingShippingMethods } = useGetShippingMethods(
    customer.stateAbbr!!.toUpperCase()
  );

  const [selectedShippingType, setSelectedShippingType] = useState<ShippingMethodType>('STANDARD');

  const selectedShippingMethod = shippingMethods.find((sm) => sm.method === selectedShippingType);

  // Update the treatment plan cart
  const dispatchUpdateCart = bindActionCreators(updateTPCart, dispatch);

  const handleSelectingShipping = (sm: GetShippingMethods200Item) => {
    dispatchUpdateCart({
      shipping: sm,
    });

    setSelectedShippingType(sm.method);
  };

  useEffect(() => {
    // Find the first shipping (user should only have 1 pending charge since consult atm) and attach that shipping method
    // to the cart if it exists!
    const foundChargeWithShipping = treatmentPlan.pendingCharges.find((pc) => pc.shippingMethodId);

    if (foundChargeWithShipping) {
      const foundShippingMethod = shippingMethods.find(
        (sm) => sm.id === foundChargeWithShipping.shippingMethodId
      );

      if (foundShippingMethod) {
        dispatchUpdateCart({
          shipping: foundShippingMethod,
        });

        setSelectedShippingType(foundShippingMethod.method);
      }
    }
  }, [shippingMethods.length, isLoadingShippingMethods]);

  return (
    <div className={`tp-shipping-method-block ${showShippingDetails ? ' tp-active-shipping' : ''}`}>
      <div
        className='tp-shipping-method-wrapper'
        onClick={() => setShowShippingDetails(!showShippingDetails)}
      >
        <div className='shipping-content-header'>
          <p className='shipping-title'>Shipping Speed</p>

          <img src={chevronDownIcon} alt='chevron right' className='shipping-chevron' />
        </div>

        <div className='shipping-content'>
          {showShippingDetails ? (
            <p className='shipping-text'>Processing time for orders can take 1-3 business days.</p>
          ) : (
            <>
              <p className='shipping-text'>
                {selectedShippingMethod
                  ? cleanShippingTitle(selectedShippingMethod)
                  : 'Standard Shipping (3-5 business days)'}
              </p>

              <p className='shipping-text-bold'>
                {selectedShippingMethod
                  ? selectedShippingMethod.method === 'STANDARD'
                    ? 'FREE'
                    : `$${convertCentsToDollars(selectedShippingMethod.priceInCents)}`
                  : 'FREE'}
              </p>
            </>
          )}
        </div>
      </div>

      <div className='tp-shipping-options-wrapper'>
        {shippingMethods
          .sort((a, b) => a.priceInCents - b.priceInCents)
          .map((sm, index) => (
            <div
              key={index}
              className={`shipping-option ${sm.method === selectedShippingType ? 'selected' : ''}`}
              onClick={() => handleSelectingShipping(sm)}
            >
              <div className='option-outer-circle'></div>

              <div className='option-content'>
                <p className='option-title'>{cleanShippingTitle(sm)}</p>
                <p className='option-price'>
                  {sm.method === 'STANDARD' ? 'FREE' : `$${convertCentsToDollars(sm.priceInCents)}`}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
