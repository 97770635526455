import {
  SubscriptionWithRenewal,
  reactivateSubscription,
  startRenewalAlert,
  unnpauseSubscription,
  useGetAllSubscriptionsForCustomer,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { updateToNewCheckoutExperienceFlow } from 'actions/checkout-experience/flow_actions';

import { Button } from 'component-library';

import { showSuccessNotification } from 'components/core/Notification';

import { getPrescriptionsStatusFrom } from 'lib/dashboard/prescription';
import { getExpiringPrescriptionsFromAll } from 'lib/dashboard/subscription';
import { useNavigate } from 'react-router-dom';

interface Props {
  subscription: SubscriptionWithRenewal;
  setLoading: (isLoading: boolean) => void;
}

export default function SubscriptionsAction({ subscription, setLoading }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: subscriptions = [], mutate: mutateSubscriptions } =
    useGetAllSubscriptionsForCustomer();
  const { mutate: mutateIsProcessing } = useIsProcessing(subscription.stripeSubscriptionId);

  const mutate = () => Promise.all([mutateSubscriptions, mutateIsProcessing]);

  const dispatchUpdateToNewCheckoutExperience = bindActionCreators(
    updateToNewCheckoutExperienceFlow,
    dispatch
  );

  const {
    allPrescriptionsExist,
    allPrescriptionsActive,
    allPrescriptionsExpired,
    prescriptionsNeedRenewal,
  } = getPrescriptionsStatusFrom(subscription);

  const isOtcOnlySubscription = subscription.products.every((pfr) => pfr.product.type === 'OTC');

  // Renewal Flag
  const needsRenewal = !allPrescriptionsActive || prescriptionsNeedRenewal;

  const onManageSubscription = () => {
    navigate(`/subscriptions/${subscription.stripeSubscriptionId}`);
  };

  const onResume = async () => {
    try {
      window.scrollTo(0, 0);

      setLoading(true);

      const { stripeSubscriptionId } = subscription;

      await unnpauseSubscription(stripeSubscriptionId);
      await mutate();

      if (needsRenewal) {
        onRenew();
      } else {
        showSuccessNotification('Your subscription has successfully been resumed');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onReactivate = async () => {
    try {
      window.scrollTo(0, 0);

      setLoading(true);

      const { stripeSubscriptionId } = subscription;

      await reactivateSubscription(stripeSubscriptionId);
      await mutate();

      if (allPrescriptionsExpired || prescriptionsNeedRenewal) {
        onRenew();
      } else {
        showSuccessNotification('Your subscription has successfully been reactivated');
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onRenew = () => {
    const expiredProductFrequencies = getExpiringPrescriptionsFromAll(subscriptions);

    const expiredProductNames = expiredProductFrequencies.map((pf) => pf.name);

    startRenewalAlert({ productNames: expiredProductNames });

    dispatchUpdateToNewCheckoutExperience(['renewal'], navigate);
  };

  switch (subscription.status) {
    case 'ACTIVE': {
      if (needsRenewal) {
        return (
          <Button theme='primary' onClick={onResume}>
            Renew
          </Button>
        );
      } else {
        return (
          <Button theme='secondary-border' onClick={onManageSubscription}>
            Manage subscription
          </Button>
        );
      }
    }

    case 'PAUSED': {
      return (
        <Button theme='primary' onClick={onResume}>
          {needsRenewal ? 'Renew' : 'Resume'}
        </Button>
      );
    }

    case 'INACTIVE': {
      if ((allPrescriptionsActive && allPrescriptionsExist) || isOtcOnlySubscription) {
        return (
          <Button theme='primary' onClick={onReactivate}>
            Reactivate
          </Button>
        );
      } else {
        return (
          <p className='m-0 p-0'>
            To reactivate, please reach out to{' '}
            <a href='mailto:support@myalloy.com' className='text-underline'>
              support@myalloy.com
            </a>
          </p>
        );
      }
    }
  }
}
