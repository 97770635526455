import { useState } from 'react';

import chevronDownIcon from 'assets/svg/core/chevron-down.svg';

import AddressDrawer from 'components/dashboard/account/drawers/AddressDrawer';

import { useAppSelector } from 'reducers/alloy_reducer';

/**
 * Component that handles showing the user their address details, used within <OrderSummaryBlock />
 * Also has a drawer that can be opened to edit the users shipping address
 */
export default function AddressBlock() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const customer = useAppSelector((state) => state.alloy.customer!!);

  const onOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div className='tp-shipping-address-block' onClick={onOpen}>
        <div className='shipping-content'>
          <p className='shipping-title'>Shipping Address</p>
          <p className='shipping-text'>
            {customer.shippingAddressLineOne} {customer.shippingAddressLineTwo}
          </p>
          <p className='shipping-text'>
            {customer.city}, {customer.stateAbbr} {customer.zip}
          </p>
        </div>

        <div className=''>
          <img src={chevronDownIcon} alt='chevron right' className='shipping-chevron' />
        </div>
      </div>

      <AddressDrawer open={isOpen} setOpen={setIsOpen} />
    </>
  );
}
