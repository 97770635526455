import isTooBig from 'common/dist/license-uploads/isTooBig';

export const isFileValid = (blob: Blob): boolean => {
  return !isTooBig(blob.size);
};

export const b64toBlob = (dataURI: any): Blob => {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: 'image/jpeg' });
};
