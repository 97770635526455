import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  title?: String;
  description?: ReactNode;
  className?: string;
}

export default function TitleTextWrapper({ title, description, className }: Props) {
  return (
    <div className='row'>
      <div className='col-12'>
        <div className={classNames('title-text-wrapper', className)}>
          <h1 className='content-title'>{title}</h1>

          {description && <p className='content-text'>{description}</p>}
        </div>
      </div>
    </div>
  );
}
