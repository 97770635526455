interface Props {
  text: string;
}

export default function Divider({ text }: Props) {
  return (
    <div className='content-divider-wrapper'>
      <div className='divider-line' />
      <p className='divider-text'>{text}</p>
      <div className='divider-line' />
    </div>
  );
}
