import { TitleText } from 'models/components/title-text';

export const verifyIdentityItems: TitleText[] = [
  {
    title: 'Why a photo ID?',
    text: 'Your menopause-specialist doctor, like all doctors, is required by law to verify your identity before prescribing any medication.',
  },
  {
    title: 'Who will see my information?',
    text: 'This is stored only on our secure platform. Your prescribing doctor and your patient support team are the only ones who will see it there.',
  },
  {
    title: 'What type of ID do I need?',
    text: "A driver's license, passport, state ID, and permanent resident card are all accepted forms of ID.",
  },
];
