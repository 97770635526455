import { ContentfulProduct } from 'common/dist/models/contentful/product';
import { createClient } from 'contentful';
import { cleanSymptomSubmissionText } from 'lib/dashboard/core/symptom';

import { ContentfulBanner } from 'models/contentful/banner';
import { ContentfulBlog } from 'models/contentful/blog';
import { ContentfulPrescribingDoctor } from 'models/contentful/doctor';
import { ContentfulReview } from 'models/contentful/reviews';
import { ContentfulSymptom } from 'models/contentful/symptom';
import { ContentfulTestimonial } from 'models/contentful/testimonial';

const makeClient = () => {
  return createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID as string,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID as string,
  });
};

export const client = makeClient();

export const isSynbiotic = (product: ContentfulProduct): boolean => {
  return product.fields.categories.every((c) => c === 'gut-health');
};

export const isPrescription = (product: ContentfulProduct): boolean => {
  return product.fields.isPrescription;
};

export const isMht = (product: ContentfulProduct): boolean => {
  return product.fields.isMht;
};

export const getAlertBanner = async (): Promise<ContentfulBanner> => {
  return await client.getEntry('41uz4zgAkRjPZztrjwb6FJ');
};

export const getMessagesBanner = async (): Promise<ContentfulBanner> => {
  return await client.getEntry('6Yhofs5LowCFOaqFQTWDE7');
};

export const getReviews = async (
  pageSlug: 'home' | 'skincare' | 'sexual-health'
): Promise<ContentfulReview[] | undefined> => {
  const { items } = await client.getEntries({
    content_type: 'trustpilotReview',
    'fields.pageSlug': pageSlug,
  });

  const transformedReviews = items.map(({ fields }) => fields);
  return transformedReviews.length > 0 ? (transformedReviews as ContentfulReview[]) : undefined;
};

export const getMaintenanceBanner = async (): Promise<Partial<ContentfulBanner>> => {
  try {
    return await client.getEntry('6ac5BPizPHPhZuoVXHacHQ');
  } catch (error) {
    return Promise.resolve({});
  }
};

export const getTopBlogPost = async (): Promise<ContentfulBlog | undefined> => {
  const { items } = await client.getEntries({
    content_type: 'blogPost',
    'fields.topPost': true,
  });

  return items.length > 0 ? (items[0] as ContentfulBlog) : undefined;
};

export const getTestimonialsByLimit = async (limit: number): Promise<ContentfulTestimonial[]> => {
  const { items } = await client.getEntries({
    content_type: 'testimonial',
    limit,
  });

  return items as ContentfulTestimonial[];
};

export const getSymptoms = async (symptoms: string[]): Promise<ContentfulSymptom[]> => {
  // cleaning the symptoms allows to remove any commas that way we can check objects more cleanily
  // within contentful
  const cleanedSymptoms = symptoms.map((s) => cleanSymptomSubmissionText(s));

  return (
    await client.getEntries({
      content_type: 'symptom',
      'fields.submissionAnswerText[in]': cleanedSymptoms.join(','), // this field in Contentful matches the submission answer
    })
  ).items as ContentfulSymptom[];
};

export const getPrescribingDoctorByNpiId = async (
  npiId: string
): Promise<ContentfulPrescribingDoctor | undefined> => {
  if (npiId === '') return undefined;

  const { items } = await client.getEntries({
    content_type: 'prescribingDoctor',
    'fields.doctorNpiId': npiId,
  });

  if (items.length > 0) {
    return items[0] as ContentfulPrescribingDoctor;
  }

  return undefined;
};
