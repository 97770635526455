import { combineReducers } from '@reduxjs/toolkit';
import ABTestingReducer, { ABTestingState } from './ab_testing_reducer';
import AlloyReducer, { AlloyState } from './alloy_reducer';
import ExperienceReducer, { ExperienceState } from './experience_reducer';
import TreatmentPlanReducer, { TreatmentPlanState } from './treatment_plan_reducer';

// TODO redux toolkit

export interface RootState {
  alloy: AlloyState;
  experience: ExperienceState;
  treatmentPlan: TreatmentPlanState;
  abTesting: ABTestingState;
}

const rootReducer = combineReducers({
  alloy: AlloyReducer,
  experience: ExperienceReducer,
  treatmentPlan: TreatmentPlanReducer,
  abTesting: ABTestingReducer,
});

export default rootReducer;
