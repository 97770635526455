import {
  EXPERIENCE_SHOW_CHECKOUT_REVIEW_TESTING,
  EXPERIENCE_SHOW_KNOWN_PREFERENCES_TESTING,
} from 'actions/types';
import { showCheckoutReview, showKnownPreferencesPage } from 'lib/core/abTests';
import { Dispatch } from '@reduxjs/toolkit';

const initABTesting = () => {
  return async (dispatch: Dispatch) => {
    // need this no matter what if user is logged in or not
    // MARK: test fetching from apps/dashboard/src/lib/core/abTests.ts
    // goes below along with dispatches for said tests

    const checkoutReviewTesting = showCheckoutReview();
    const knownPreferencesTesting = showKnownPreferencesPage();

    dispatch({
      type: EXPERIENCE_SHOW_CHECKOUT_REVIEW_TESTING,
      payload: checkoutReviewTesting,
    });
    dispatch({
      type: EXPERIENCE_SHOW_KNOWN_PREFERENCES_TESTING,
      payload: knownPreferencesTesting,
    });
  };
};

export { initABTesting };
