import { Skeleton } from '@mui/material';
import {
  useGetCustomerReferralCode,
  useGetCustomerReferralCredits,
} from 'client/dist/generated/alloy';

import CreditsBlock from './CreditsBlock';
import HeaderBlock from './HeaderBlock';

export default function ReferralsWrapper() {
  const { isLoading: isLoadingReferralCode } = useGetCustomerReferralCode();
  const { isLoading: isLoadingReferralCredits } = useGetCustomerReferralCredits();

  const isLoading = isLoadingReferralCode || isLoadingReferralCredits;

  if (isLoading) {
    return <Skeleton variant='rectangular' height={300} />;
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <HeaderBlock />
        <CreditsBlock />
      </div>
    </div>
  );
}
