import classNames from 'classnames';
import {
  useGetAllOrders,
  useGetAllSubscriptionsForCustomer,
  useGetAllUnreadNotifications,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import Loader from 'components/core/Loader';
import SectionWrapper from 'components/core/layout/SectionWrapper';
import EmptyCardBlock from 'components/dashboard/home/cards/EmptyCard';
import HeaderSection from 'components/dashboard/home/sections/Header';
import ReviewsSection from 'components/dashboard/home/sections/Reviews';
import RoundOutRoutineBlock from 'components/dashboard/shared/blocks/RoundOutRoutine';
import StatsBlock from 'components/dashboard/shared/blocks/StatsBlock';
import TopBlogBlock from 'components/dashboard/shared/blocks/TopBlogBlock';
import InfoSection from 'components/dashboard/shared/sections/Info';
import DashboardContainer from 'containers/dashboard/DashboardContainer';
import { getAccountStatus } from 'lib/core/customer/getAccountStatus';
import { sendTag } from 'lib/tracking/sentry';
import { useEffect } from 'react';
import { useAppSelector } from 'reducers/alloy_reducer';

/**
 * Two main states at the top level: INACTIVE AND ACTIVE
 * - inactive implies no purchase, waiting on doctor, or waiting on customer approval
 * - active implies has at least 1 active subscription
 *
 * these high level states are mainly for the content below the hero, the hero
 * will have its own various states to display
 */

export default function Home() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();
  const { data: orders = [], isLoading: isLoadingOrders } = useGetAllOrders();
  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGetTreatmentPlan();
  const { isLoading: isLoadingNotifications } = useGetAllUnreadNotifications();

  const accountStatus = getAccountStatus(customer.status, subscriptions, orders, treatmentPlan);
  const hasConsultPurchase = treatmentPlan?.checkedOutWithConsult ?? false;
  const notActiveSubscriber = ['NO_PURCHASE', 'AWAITING_DOCTOR', 'AWAITING_CUSTOMER'].includes(
    accountStatus
  );

  // See if anything is loading
  const isLoading =
    isLoadingTreatmentPlan || isLoadingSubscriptions || isLoadingOrders || isLoadingNotifications;

  useEffect(() => {
    if (!isLoading) {
      sendTag('account-status', accountStatus);
    }
  }, [isLoading]);

  /**
   * Stuff is loading
   */
  if (isLoading) {
    return (
      <DashboardContainer title='Home | Alloy' currentPage='home'>
        <Loader />
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer title='Home | Alloy' desc='' currentPage='home'>
      <HeaderSection />

      <SectionWrapper
        sectionClasses={classNames(
          'dashboard-treatment-plan-section',
          notActiveSubscriber && 'remove-md-top-padding'
        )}
      >
        {/* customers in these states, are not able yet to view the round out screen */}
        {notActiveSubscriber ? (
          <>
            <div className='col-12 col-md-6 stats-block-col'>
              <StatsBlock
                hugText='Did you know?'
                content={
                  <>
                    95% of women have symptom <span className='text-italic'>relief</span> within 4
                    weeks of working with Alloy
                  </>
                }
              />
            </div>

            <div className='col-12 col-md-6'>
              <TopBlogBlock smallerImg />
            </div>
          </>
        ) : (
          <>
            <div className={classNames('col-12 col-md-7', !hasConsultPurchase && 'mb-3 mb-md-0')}>
              {hasConsultPurchase ? <RoundOutRoutineBlock /> : <EmptyCardBlock />}
            </div>

            <div className='col-12 col-md-5'>
              <TopBlogBlock smallerImg />
            </div>
          </>
        )}
      </SectionWrapper>

      {notActiveSubscriber && <ReviewsSection />}

      <InfoSection />
    </DashboardContainer>
  );
}
