import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { useGetAllOrders } from 'client/dist/generated/alloy';
import { getTime } from 'date-fns';
import { useState } from 'react';

import ContentBlock from './ContentBlock';
import HeaderBlock from './HeaderBlock';

import EmptyState from 'components/dashboard/EmptyState';

import { useAppSelector } from 'reducers/alloy_reducer';

export default function OrdersWrapper() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const [activeOrderId, setActiveOrderId] = useState('');

  const { data: orders = [], isLoading } = useGetAllOrders();

  orders.sort((a, b) => getTime(new Date(b.createdAt!)) - getTime(new Date(a.createdAt!)));

  const toggleOrder = (id: string) => {
    setActiveOrderId(activeOrderId === id ? '' : id);
  };

  if (isLoading) {
    return (
      <>
        <Skeleton variant='rectangular' height={200} className='mb-3' />
        <Skeleton variant='rectangular' height={200} />
      </>
    );
  }

  if (orders.length === 0 && customer.status === 'NEW') {
    return <EmptyState type='NEW' />;
  }

  if (orders.length === 0 && customer.status === 'PENDING') {
    return <EmptyState type='PENDING_ORDER' />;
  }

  return (
    <div className='row'>
      <div className='col-12'>
        {orders.map((order, index) => (
          <div
            className={classNames(
              'order-wrapper',
              activeOrderId === order.fulfillmentCorrelationId && 'active'
            )}
            key={index}
          >
            <HeaderBlock order={order} setOpen={toggleOrder} />

            <ContentBlock order={order} />
          </div>
        ))}
      </div>
    </div>
  );
}
