import {
  useGetAllSubscriptionsForCustomer,
  useGetNonCustomerUpsellProducts,
  useGetPendingSwitch,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { updateTPCart } from 'actions/treatment-plan/cart_actions';

import chevronDown from 'assets/svg/core/chevron-down.svg';

import Loader from 'components/core/Loader';
import SectionWrapper from 'components/core/layout/SectionWrapper';
import TopBlogBlock from '../../shared/blocks/TopBlogBlock';
import DoctorBlock from '../core/DoctorBlock';
import HeaderBlock from '../core/HeaderBlock';
import ListSelectableProducts from '../core/ListSelectableProducts';
import ReferralBlock from '../core/ReferralBlock';

import InfoSection from 'components/dashboard/shared/sections/Info';
import TestimonialsSection from 'components/dashboard/shared/sections/Testimonials';

import DashboardContainer from 'containers/dashboard/DashboardContainer';

import {
  filterMhtProductsFrom,
  filterNonMhtProductsFrom,
  sortMhtProducts,
} from 'lib/dashboard/treatmentPlan';

import { first, flatten, xor } from 'lodash';
import { useAppSelector } from 'reducers/alloy_reducer';

import RoundOutRoutineBlock from 'components/dashboard/shared/blocks/RoundOutRoutine';
import ActiveProductsBlock from '../products-list/ActiveProductsBlock';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';
import ProductRegistry from 'client/dist/product/productRegistry';
import { useNavigate } from 'react-router-dom';

interface CategoryGroupedProducts {
  pendingApproval: GroupedContentfulProduct[][];
}

const INIT_CATEGORY_GROUPED_PRODUCTS: CategoryGroupedProducts = {
  pendingApproval: [],
};

// TODO: future mikhail, clean up blocks and product cards for the lists inside ActivePlan!
// have the chance to separate into blocks of active products, round out products + more!
export default function ActivePlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Grouped products used to display content
  const [isLoadingGrouped, setIsLoadingGrouped] = useState<boolean>(true);
  const [categoryGroupedProducts, setCategoryGroupedProducts] = useState<CategoryGroupedProducts>(
    INIT_CATEGORY_GROUPED_PRODUCTS
  );

  const [selectedProducts, setSelectedProducts] = useState<GroupedContentfulProduct[]>([]);

  const customer = useAppSelector((state) => state.alloy.customer!!);
  const cart = useAppSelector((state) => state.treatmentPlan.treatmentPlanCart);

  /**
   * Data loading for treatment plan, subscriptions, and upsell products
   */
  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGetTreatmentPlan();
  const { isLoading: isLoadingPendingSwitch } = useGetPendingSwitch();
  const { data: subscriptions = [], isLoading: isLoadingSubscriptions } =
    useGetAllSubscriptionsForCustomer();
  const { data: upsellProducts = [], isLoading: isLoadingUpsellProducts } =
    useGetNonCustomerUpsellProducts();

  const { activeSubs, pausedSubs } = getSubscriptionsWithStatus(subscriptions);
  const activeSubscriptions = [...activeSubs, ...pausedSubs];

  const isLoading =
    isLoadingGrouped ||
    isLoadingTreatmentPlan ||
    isLoadingPendingSwitch ||
    isLoadingSubscriptions ||
    isLoadingUpsellProducts;

  const dispatchUpdateCart = bindActionCreators(updateTPCart, dispatch);

  useEffect(() => {
    retrieveGroupedAndContentfulProducts();
  }, [JSON.stringify(treatmentPlan), isLoadingSubscriptions, isLoadingUpsellProducts]);

  /**
   * using the subscription products and upsell products, we need to fetch the contentful and grouped products.
   * this will allow us to use these products for display grouped products as well as handle passing pf
   * to BE when needed
   *
   * purchased products are split into active subscription and invoice only products (one time)
   * not purchased products are products prescribed but not purchased
   * upsell products are products that the customer can request
   */
  const retrieveGroupedAndContentfulProducts = async () => {
    if (
      treatmentPlan?.pendingCharges.length !== 0 ||
      activeSubscriptions.length !== 0 ||
      upsellProducts.length !== 0
    ) {
      setIsLoadingGrouped(true);

      // TODO: for future mikhail, would love to adjust the upsell/round out code a lot more dynamically by just using the two arrays
      // below since we have the data we don't need to do anything fancy for bundles and whatnot

      const pendingChargesProducts = treatmentPlan
        ? treatmentPlan.pendingCharges.flatMap((pc) => pc.products)
        : [];
      const products = await ProductRegistry.get().getRecurringProductsForV2(
        pendingChargesProducts
      );

      const mhtProducts = sortMhtProducts(filterMhtProductsFrom(products.flat()));
      const nonMhtProducts = filterNonMhtProductsFrom(products);
      const flattenedNonMhtProducts = nonMhtProducts.flat();

      const potentialCustomerApproval =
        mhtProducts.length !== 0
          ? [first(mhtProducts)!, ...flattenedNonMhtProducts]
          : flattenedNonMhtProducts;

      setCategoryGroupedProducts({
        pendingApproval: products,
      });

      setSelectedProducts(potentialCustomerApproval);

      setIsLoadingGrouped(false);
    }
  };

  const toggleList = (id: string) => {
    const listElement = document.getElementById(id);

    if (listElement) {
      if (listElement.classList.contains('active')) {
        listElement.classList.remove('active');
      } else {
        listElement.classList.add('active');
      }
    }
  };

  /**
   * Selecting a product and attaching to our cart or removing!
   */
  const onSelectProduct = (
    product: GroupedContentfulProduct,
    prevSelected: GroupedContentfulProduct[] = selectedProducts
  ) => {
    const selected = xor(prevSelected, [product]);

    dispatchUpdateCart({
      products: selected,
    });

    setSelectedProducts(selected);
  };

  const onSwitch = async (
    prevSelected: GroupedContentfulProduct,
    product: GroupedContentfulProduct
  ) => {
    const switchSelected = selectedProducts.filter(
      (gcp) => gcp.contentfulProduct.sys.id !== prevSelected.contentfulProduct.sys.id
    );

    onSelectProduct(product, switchSelected);
  };

  const onContinueCheckout = () => {
    dispatchUpdateCart({
      products: selectedProducts,
    });

    navigate('/treatment-plan/checkout');
  };

  if (isLoading) {
    return (
      <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
        <Loader />
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
      <HeaderBlock title='Treatment plan for' fancyTitle={customer.firstName ?? ''} />

      <SectionWrapper sectionClasses='dashboard-treatment-plan-section'>
        <div className='col-12 col-md-7'>
          <DoctorBlock />

          {/* Pending approval products */}
          {categoryGroupedProducts.pendingApproval.length !== 0 && (
            <div className='tp-products-list-wrapper active closable' id='approve-products-list'>
              <div className='tp-header'>
                <p className='tp-subtitle'>Approve your treatments</p>

                <button
                  className='tp-chevron-btn'
                  onClick={() => {
                    toggleList('approve-products-list');
                  }}
                >
                  <img src={chevronDown} alt='chevron-down' />
                </button>
              </div>

              <div className='products-list'>
                {/* Listing selectable products for pending approval */}
                <ListSelectableProducts
                  products={categoryGroupedProducts.pendingApproval}
                  selectedProducts={selectedProducts}
                  onSelect={onSelectProduct}
                  onSwitch={onSwitch}
                />

                <button
                  className='primary-button add-bottom-margin'
                  onClick={onContinueCheckout}
                  disabled={selectedProducts.length === 0}
                >
                  Continue to checkout
                </button>
              </div>
            </div>
          )}

          {/* Active products / purchased */}
          <ActiveProductsBlock
            pendingApprovalProducts={flatten(categoryGroupedProducts.pendingApproval)}
          />

          {/* products not purchased and upsell products - */}
          <RoundOutRoutineBlock />
        </div>

        <div className='col-12 col-md-5'>
          <ReferralBlock />
          <TopBlogBlock />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />
    </DashboardContainer>
  );
}
