import {
  entryQuoteIcon,
  goldenStarIcon,
  leftArrowDarkIcon,
  rightArrowDarkIcon,
} from 'assets/svg/checkout-experience/expectations';
import classNames from 'classnames';
import isNarrowScreen from 'lib/core/isNarrowScreen';
import { ContentfulReview } from 'models/contentful/reviews';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import TPWidget from './TPWidget';

interface Props {
  title?: string;
  reviewCards: ContentfulReview[];
  showTPWidget?: boolean;
}

interface ReviewProps {
  title?: string;
  isMobile: boolean;
  reviewCards: ContentfulReview[];
  showTPWidget?: boolean;
}

export const FiveStars: React.FC = () => {
  return (
    <div className='star-wrapper'>
      {[...Array(5)].map((_, starIndex) => (
        <img src={goldenStarIcon} alt='star' key={starIndex} />
      ))}
    </div>
  );
};

const ReviewCard: React.FC<ContentfulReview> = ({ content, customerName, link }) => {
  return (
    <div className={classNames('review-card')}>
      <div className='top'>
        <div className='quote-wrapper'>
          <img src={entryQuoteIcon} alt='entry quote' className='quote' />
        </div>
        <p>{content}</p>
      </div>
      <div className='bottom'>
        <FiveStars />
        <span className='source'>
          {customerName} on&nbsp;
          <a className='tp-link' href={link} target='_blank' rel='noreferrer'>
            Trustpilot
          </a>
        </span>
      </div>
    </div>
  );
};

const Reviews: React.FC<ReviewProps> = ({ title, isMobile, reviewCards = [], showTPWidget }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goLeft = () => handleSelect(currentIndex - 1);
  const goRight = () => handleSelect(currentIndex + 1);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: goRight,
    onSwipedRight: goLeft,
  });

  const handleSelect = (selectedIndex: number) => {
    const innerCards = document.querySelectorAll('.review-card');

    const leftmostLeft = (innerCards[0] as HTMLElement).getBoundingClientRect().left;

    const rightmostRight = (
      innerCards[innerCards.length - 1] as HTMLElement
    ).getBoundingClientRect().right;

    if (leftmostLeft > 0 && selectedIndex < currentIndex) {
      return;
    }

    if (rightmostRight < window.innerWidth && selectedIndex > currentIndex) {
      return;
    }

    setCurrentIndex(selectedIndex);
  };

  const mobileScrollAmt = 70; // 70 pct
  const mobileScrollFactor = '%';
  const desktopScrollAmt = 520; // 500 px wide + 20 px gap
  const desktopScrollFactor = 'px';
  const transformStyle = {
    transform: `translateX(${-currentIndex * (isMobile ? mobileScrollAmt : desktopScrollAmt)}${
      isMobile ? mobileScrollFactor : desktopScrollFactor
    })`,
    transition: 'transform 0.5s ease-in-out',
  };

  return (
    <div className='section-reviews'>
      {showTPWidget && <TPWidget />}

      <div className='container-fluid'>
        <div className='row reviews-row justify-content-center align-items-center'>
          {title && (
            <div className='col-12'>
              <h4 className='reviews-title'>{title}</h4>
            </div>
          )}

          <div
            {...swipeHandlers}
            className={classNames('inner', isMobile && 'on-mobile')}
            style={transformStyle}
          >
            {reviewCards.map((card, idx) => (
              <ReviewCard
                key={idx}
                customerName={card.customerName}
                link={card.link}
                content={card.content}
              />
            ))}
          </div>
        </div>

        <div className='row'>
          <div className='col-12 controls'>
            <div
              onClick={() => handleSelect(currentIndex - 1)}
              className='d-flex justify-content-center align-items-center'
            >
              <img src={leftArrowDarkIcon} alt='left arrow' height={19} width={21} />
            </div>
            <div
              onClick={() => handleSelect(currentIndex + 1)}
              className='d-flex justify-content-center align-items-center'
            >
              <img src={rightArrowDarkIcon} alt='right arrow' height={19} width={21} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function ReviewCarousel({ title, reviewCards, showTPWidget }: Props) {
  return (
    <Reviews
      title={title}
      reviewCards={reviewCards}
      isMobile={isNarrowScreen()}
      showTPWidget={showTPWidget}
    />
  );
}
