import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import FeaturesBlock from 'components/checkout-experience/expectations/FeaturesBlock';
import HowItWorksBlock from 'components/checkout-experience/expectations/HowItWorksBlock';
import ImprovementBlock from 'components/checkout-experience/expectations/ImprovementBlock';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import { features, howItWorks } from 'data/checkout-experience/content/expectations';
import { getPercentComplete } from 'lib/checkout-experience/progress';
import { ExperienceComponentModel } from 'models/alloy/experience';
import { useLocation } from 'react-router-dom';

export default function Expectations({ onNext, onBack }: ExperienceComponentModel) {
  const location = useLocation();

  const onSubmit = () => {
    onNext();
  };

  return (
    <Layout title='Expectations - Alloy' desc='' noBars>
      <TopBannerWithProgress percentComplete={getPercentComplete(location)} onBack={onBack} />

      <CheckoutExperienceSection colClass='col-md-7'>
        <h1 className='content-title'>Here's how it works</h1>

        <HowItWorksBlock data={howItWorks} />

        <ImprovementBlock
          title='95% of Alloy customers '
          text='taking MHT have symptom relief in 2 weeks or less'
        />

        <FeaturesBlock data={features} />
      </CheckoutExperienceSection>

      <BottomBar>
        <button className='primary-button' type='submit' onClick={onSubmit}>
          Continue with intake
        </button>
      </BottomBar>
    </Layout>
  );
}
