import { ExperienceCategory } from 'common/dist/models/experience';

export interface ResultStat {
  title: string;
  content: string;
  categories: ExperienceCategory[];
}

export const defaultStat = {
  title: '95% of Alloy customers',
  content: 'taking MHT have symptom relief in 2 weeks or less',
  categories: [
    'gut-health',
    'hair-health',
    'mht',
    'sexual-health',
    'vaginal-health',
    'skin-health',
  ] as ExperienceCategory[],
};

export const resultStats: ResultStat[] = [defaultStat];

export const defaultWidget = {
  title: '95% of women',
  content: 'will see improvement in symptoms within 2 weeks of starting treatment',
  categories: [
    'gut-health',
    'hair-health',
    'mht',
    'sexual-health',
    'vaginal-health',
    'skin-health',
  ] as ExperienceCategory[],
};

export const resultWidgets: ResultStat[] = [defaultWidget];
