import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { getTopBlogPost } from 'lib/shared/contentful';
import { ContentfulBlog } from 'models/contentful/blog';
import { useEffect, useState } from 'react';

interface Props {
  smallerImg?: boolean;
}

/**
 * This block is used within the treatment plan active page and is on the right hand side. if there is a blog post that is selected as top in contentful,
 * then we will show it otherwise this block will not show anything
 */
export default function TopBlogBlock({ smallerImg }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [blog, setBlog] = useState<ContentfulBlog | undefined>();

  useEffect(() => {
    getPost();
  }, []);

  const getPost = async () => {
    const retrieved = await getTopBlogPost();

    setBlog(retrieved);
    setIsLoading(false);
  };

  if (isLoading)
    return (
      <>
        <Skeleton variant='rectangular' height={250} className='mb-3' />
      </>
    );

  if (!blog) return <></>;

  return (
    <a
      className='top-blog-block'
      href={process.env.REACT_APP_MARKETING_URL + '/blog/' + blog.fields.slug}
      target='_blank'
    >
      <img
        src={blog.fields.imageThumbnail.fields.file.url}
        alt={blog.fields.title}
        className={classNames('blog-photo', smallerImg && 'sml-photo')}
      />
      <p className='blog-tag'>The flash</p>

      <p className='blog-title'>{blog.fields.title}</p>
      <p className='blog-author'>by {blog.fields.author.fields.name}</p>

      <div className='secondary-border-button'>Read more</div>
    </a>
  );
}
