import DashboardContainer from 'containers/dashboard/DashboardContainer';

import ReferralsWrapper from 'components/dashboard/account/referrals/ReferralsWrapper';
import AccountContainer from 'containers/dashboard/AccountContainer';
import useMarkNotificationsAsRead from 'hooks/shared/useMarkNotificationsAsRead';
import { useEffect } from 'react';

export default function Referrals() {
  // mark notifications as read once on everytime going to page OR reloading
  useMarkNotificationsAsRead(['REFERRAL_REDEMPTION']);

  return (
    <DashboardContainer title='Referrals | Alloy' desc='' currentPage='account'>
      <AccountContainer title='Your Account' currentPage='referrals'>
        <ReferralsWrapper />
      </AccountContainer>
    </DashboardContainer>
  );
}
