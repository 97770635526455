import { SubmissionAnswer } from 'client/dist/generated/alloy';

export const getRenewalHiddenResults = (
  renewalProducts: string,
  dqProducts: string
): SubmissionAnswer[] => {
  const results: SubmissionAnswer[] = [
    // should this  be in the renewal intake now?
    {
      slug: 'products-requesting-renewal',
      questionContent: 'Products Requesting Renewal',
      answer: renewalProducts,
      important: false,
      mdiCaseQuestion: true,
      position: 1,
    },
  ];

  if (dqProducts !== '') {
    results.push({
      slug: 'products-disqualified',
      questionContent: 'Products Disqualified',
      answer: dqProducts,
      important: false,
      mdiCaseQuestion: true,
      position: 2,
    });
  }

  return results;
};
