import { ExperienceValidationKey } from 'lib/checkout-experience/validation';
import { ExperienceFlowStep } from 'models/alloy/experience';

import AddOnPreferences from 'pages/checkout-experience/mht/AddOnPreferences';
import Expectations from 'pages/checkout-experience/mht/Expectations';
import KnownPreferences from 'pages/checkout-experience/mht/KnownPreferences';
import MhtPreferences from 'pages/checkout-experience/mht/MhtPreferences';
import TreatmentInterests from 'pages/checkout-experience/mht/TreatmentInterests';
import Checkout from 'pages/checkout-experience/shared/Checkout';
import Intake from 'pages/checkout-experience/shared/Intake';
import Name from 'pages/checkout-experience/shared/Name';
import ReliefType from 'pages/checkout-experience/shared/ReliefType';
import Review from 'pages/checkout-experience/shared/Review';
import UploadVideo from 'pages/checkout-experience/shared/UploadVideo';
import VerifyIdentity from 'pages/checkout-experience/shared/VerifyIdentity';
import Register from 'pages/checkout-experience/shared/auth/Register';
import Verification from 'pages/checkout-experience/shared/auth/Verification';

export const mhtSteps: ExperienceFlowStep[] = [
  {
    validationKey: ExperienceValidationKey.name,
    component: Name,
    path: 'name',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.reliefType,
    component: ReliefType,
    path: 'relief-type',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.expectations,
    component: Expectations,
    path: 'expectations',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.knownPreferences,
    component: KnownPreferences,
    path: 'known-preferences',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.treatmentInterests,
    component: TreatmentInterests,
    path: 'treatment-interests',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.mhtPreferences,
    component: MhtPreferences,
    path: 'mht-preferences',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.addOnPreferences,
    component: AddOnPreferences,
    path: 'add-on-preferences',
    routeType: 'PUBLIC',
  },
  {
    validationKey: ExperienceValidationKey.intake,
    component: Intake,
    path: 'intake',
    routeType: 'PUBLIC',
    hasNested: true,
  },

  {
    validationKey: ExperienceValidationKey.review,
    component: Review,
    path: 'review',
    routeType: 'PUBLIC',
  },

  {
    validationKey: ExperienceValidationKey.register,
    component: Register,
    path: 'register',
    routeType: 'AUTH',
  },
  {
    validationKey: ExperienceValidationKey.verification,
    component: Verification,
    path: 'verification',
    routeType: 'AUTH',
  },

  {
    validationKey: ExperienceValidationKey.uploadId,
    component: VerifyIdentity,
    path: 'verify-identity',
    routeType: 'PRIVATE',
  },
  {
    validationKey: ExperienceValidationKey.uploadVideo,
    component: UploadVideo,
    path: 'upload-video',
    routeType: 'PRIVATE',
  },

  {
    validationKey: ExperienceValidationKey.checkout,
    component: Checkout,
    path: 'checkout',
    routeType: 'PRIVATE',
  },
];
