import { ReactNode } from 'react';
import { Form } from 'rsuite';

import Loader from 'components/core/Loader';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';
import BottomBar from '../BottomBar';
import TopBannerWithProgress from '../TopBannerWithProgress';
import FeaturesBlock from '../expectations/FeaturesBlock';

import { features } from 'data/checkout-experience/content/expectations';

import { getPercentComplete } from 'lib/checkout-experience/progress';
import { useLocation } from 'react-router-dom';
import StatReviewWrapper from 'components/shared/checkout/StatReviewWrapper';
import { useAppSelector } from 'reducers/alloy_reducer';
import { resultStats } from 'data/checkout-experience/content/result-stats';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import ImprovementBlock from '../expectations/ImprovementBlock';

interface Props {
  isLoading: boolean;
  onBack?: () => void;
  isContinueDisabled: boolean;
  onContinue: () => void;
  children: ReactNode;
}

export default function ReviewSection({
  isLoading,
  onBack,
  isContinueDisabled,
  onContinue,
  children,
}: Props) {
  const location = useLocation();

  return (
    <>
      <TopBannerWithProgress
        onBack={isLoading ? undefined : onBack}
        percentComplete={getPercentComplete(location)}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Form>
          <CheckoutExperienceSection colClass='col-md-7'>
            {children}
            <StatReviewWrapper reviewPage />
            <FeaturesBlock data={features} />
          </CheckoutExperienceSection>

          <BottomBar>
            <button
              className='primary-button'
              type='submit'
              disabled={isContinueDisabled}
              onClick={onContinue}
            >
              Continue to checkout
            </button>
          </BottomBar>
        </Form>
      )}
    </>
  );
}
