import { useAppSelector } from 'reducers/alloy_reducer';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { useLocation } from 'react-router-dom';

import TPWidget from 'components/core/TPWidget';
import TPReview from 'components/checkout-experience/TPReview';
import ImprovementBlock from 'components/checkout-experience/expectations/ImprovementBlock';
import { getRelevantFact } from 'lib/checkout-experience/checkout/getRelevantFact';

interface Props {
  reviewPage?: boolean;
}

export default function StatReviewWrapper({ reviewPage }: Props) {
  const { showCheckoutReviewTesting } = useAppSelector((state) => state.abTesting);
  const location = useLocation();
  const categories = retrieveCategoriesFromUrl(location);

  //  gets the correct wording for the widget that will be shown
  const isStat = reviewPage || categories.includes('hair-health') || categories.includes('mht');
  const relevantFact = getRelevantFact({
    categories: categories,
    isStat,
  });

  //   controls which component(s) to return based on the test group, category, & page
  const showBoth = showCheckoutReviewTesting && categories.includes('mht');
  const showReview =
    showCheckoutReviewTesting && !categories.includes('hair-health') && !categories.includes('mht');
  const showStatOnly =
    !showCheckoutReviewTesting || (reviewPage && categories.includes('hair-health'));

  // Both stat & review -- for MHT consult on both review & checkout pages
  if (showBoth) {
    return (
      <>
        <ImprovementBlock title={relevantFact.title} text={relevantFact.content} />
        <TPReview />
      </>
    );
  }

  // Review -- displays for the "test" users for all products except MHT and hair-health, on both
  // review and checkout pages.
  if (showReview) {
    return <TPReview />;
  }

  // Stat only -- displays for the "control" users on the review page, and also for the hair-health
  // flow even if they're in the "test" group, because we don't have a review to show them yet.
  if (showStatOnly) {
    return <ImprovementBlock title={relevantFact.title} text={relevantFact.content} />;
  }

  //  TrustPilot widget that will display on the checkout page for the control group
  return (
    <TPWidget withBackground title={relevantFact.title} text={relevantFact.content} theme='dark' />
  );
}
