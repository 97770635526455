import { ApiOrder, useGetOrderCarrier } from 'client/dist/generated/alloy';

import chevronDown from 'assets/svg/core/chevron-down.svg';

import { getTrackingLink } from 'common/dist/shipping/tracking';
import { displayFriendlyOrderStatus } from 'lib/dashboard/account/displayFriendlyOrderStatus';

import { Skeleton } from '@mui/material';
import ProductsList from 'components/dashboard/shared/products/ProductsList';
import { differenceInDays } from 'date-fns';

interface Props {
  order: ApiOrder;
  setOpen: (id: string) => void;
}

export default function HeaderBlock({ order, setOpen }: Props) {
  const title = displayFriendlyOrderStatus(order);
  const orderId = order.fulfillmentCorrelationId;
  const orderNumber = orderId.substring(0, 11).toUpperCase();

  const skipTrackingFetch =
    order.internalStatus === 'DELIVERED' &&
    !!order.deliveredOn &&
    Math.abs(differenceInDays(new Date(), new Date(order.deliveredOn))) > 90;

  const { data: orderCarrier, isLoading: isLoadingCarrier } = useGetOrderCarrier(order.id, {
    swr: {
      enabled: !skipTrackingFetch,
    },
  });

  const carrier = orderCarrier ? orderCarrier : order.carrier;
  const trackingNumber = order.trackingNumber;

  return (
    <div className='order-header-block'>
      <div className='order-header-content'>
        <div className='header-content-wrapper'>
          <p className='content-title'>{title}</p>

          <button
            className='chevron-btn'
            onClick={() => {
              setOpen(orderId);
            }}
          >
            <img src={chevronDown} alt='chevron' />
          </button>
        </div>

        <div className='header-content-wrapper d-block d-md-flex'>
          <p className='content-text'>
            {order.isConsult ? 'Initial Doctor Consult' : `Order #${orderNumber}`}
          </p>

          {isLoadingCarrier ? (
            <Skeleton variant='text' width={125} />
          ) : (
            carrier &&
            trackingNumber && (
              <p className='content-text'>
                Tracking Number:{' '}
                <a
                  className='text-link'
                  href={getTrackingLink(carrier, trackingNumber)}
                  target='_blank'
                  rel='noreferrer'
                >
                  {trackingNumber}
                </a>
              </p>
            )
          )}
        </div>
      </div>

      <ProductsList
        productsWithRenewal={order.productFrequencies.map((pf) => ({ product: pf }))}
        shouldGroup={!order.isConsult}
      />
    </div>
  );
}
