import checkmarkIcon from 'assets/svg/dashboard/home/checkmark-icon.svg';

import { useGetTreatmentPlan } from 'client/dist/generated/alloy';
import { Link } from 'react-router-dom';
import NotificationCardBlock from '../shared/NotificationCard';
import { format } from 'date-fns';

export default function ApproveCardBlock() {
  const { data: treatmentPlan } = useGetTreatmentPlan();

  return (
    <NotificationCardBlock
      hugText='action required'
      isRequired
      title='Approve your treatment plan'
      description={
        treatmentPlan?.messagingWidgetExpiry
          ? `Your doctor has finished reviewing your intake. Approve your treatments by ${format(
              new Date(treatmentPlan?.messagingWidgetExpiry),
              'MMM d'
            )} in order to maintain access to your doctor.`
          : 'Your doctor has finished reviewing your intake. Approve your treatments in order to maintain access to your doctor.'
      }
      icon={checkmarkIcon}
    >
      <Link to='/treatment-plan' className='primary-button mt-3'>
        View your treatment plan
      </Link>
    </NotificationCardBlock>
  );
}
