type Redirects = {
  oldPath: string;
  newPath: string;
  params?: string[][];
};

/**
 * These are old routes that are now redirected to brand new routes!
 */
export const RedirectRoutes: Redirects[] = [
  {
    oldPath: '/register',
    newPath: '/checkout-experience',
    params: [['categories[]', 'gut-health']],
  },
  {
    oldPath: '/medical-intake',
    newPath: '/checkout-experience',
    params: [['categories[]', 'mht']],
  },
  {
    oldPath: '/start-consult',
    newPath: '/checkout-experience',
    params: [['categories[]', 'mht']],
  },
  {
    oldPath: '/get-started',
    newPath: '/checkout-experience',
    params: [['categories[]', 'mht']],
  },
  {
    oldPath: '/start-renewal',
    newPath: '/checkout-experience',
    params: [['categories[]', 'renewal']],
  },
  {
    oldPath: '/prescriptions',
    newPath: '/',
  },

  {
    oldPath: '/product-information',
    newPath: '/treatment-plan',
  },

  {
    oldPath: '/shipments',
    newPath: '/subscriptions',
  },
  {
    oldPath: '/shipments/upcoming',
    newPath: '/subscriptions',
  },
  {
    oldPath: '/shipments/upcoming/:id',
    newPath: '/subscriptions/:id',
  },

  {
    oldPath: '/settings',
    newPath: '/account/profile',
  },

  {
    oldPath: '/account',
    newPath: '/account/profile',
  },

  {
    oldPath: '/shipments/order-history',
    newPath: '/account/order-history',
  },
  {
    oldPath: '/referrals',
    newPath: '/account/referrals',
  },
];
