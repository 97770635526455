import classNames from 'classnames';
import { ExperienceCategory } from 'common/dist/models/experience';
import { ReliefImageProps, SelectionImage } from './SelectionImage';
import checkedBox from 'assets/svg/core/icons/checked-box-icon.svg';
import uncheckedBox from 'assets/svg/core/icons/unchecked-box-icon.svg';

export type MultiSelectOption = {
  id: string;
  text: string;
  categories: ExperienceCategory[];
  imageConfig?: ReliefImageProps;
};

interface Props {
  option: MultiSelectOption;
  selectedIds: string[];
  onSelect: () => void;
}

/**
 * This is a styled checkbox select field with text
 * and optional image
 */

export default function MultiSelectImageField({
  option: { text, id, imageConfig },
  selectedIds,
  onSelect,
}: Props) {
  const isSelected = selectedIds.includes(id);

  return (
    <div className='field-checkbox select-image-field' onClick={onSelect}>
      <div className={classNames('select-image-inner-wrapper', isSelected && 'selected')}>
        <div className={classNames('inner-content-wrapper', 'short')}>
          <img
            src={isSelected ? checkedBox : uncheckedBox}
            alt='selectable checkbox'
            className='checkbox-select-icon'
          />

          <div className={classNames('content-block', !imageConfig && 'short')}>
            <div className='content-title'>{text}</div>
          </div>
        </div>

        {imageConfig && <SelectionImage {...imageConfig} />}
      </div>
    </div>
  );
}
