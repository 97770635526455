import {
  EXPERIENCE_SHOW_CHECKOUT_REVIEW_TESTING,
  EXPERIENCE_SHOW_KNOWN_PREFERENCES_TESTING,
} from 'actions/types';

/*
 * test variables should be booleans here and should follow naming pattern
 * of <TEST_NAME>Testing then can use that for dispatching below!
 */
export interface ABTestingState {
  showCheckoutReviewTesting: boolean;
  showKnownPreferencesTesting: boolean;
}

const INIT_STATE: ABTestingState = {
  showCheckoutReviewTesting: false,
  showKnownPreferencesTesting: false,
};

/**
 * Reducer is used for all things AB Testing! pretty fun
 *
 * @param state ABTestingState
 * @param action { payload: Partial<ABTestingState>; type: string }
 * @returns state
 */
export default function ABTestingReducer(
  state = INIT_STATE,
  action: { payload: Partial<ABTestingState>; type: string }
) {
  switch (action.type) {
    case EXPERIENCE_SHOW_KNOWN_PREFERENCES_TESTING: {
      return {
        ...state,
        showKnownPreferencesTesting: action.payload,
      };
    }
    case EXPERIENCE_SHOW_CHECKOUT_REVIEW_TESTING: {
      return {
        ...state,
        showCheckoutReviewTesting: action.payload,
      };
    }
    default:
      return state;
  }
}
