import {
  ApiOrder,
  CustomerStatus,
  SubscriptionWithRenewal,
  TreatmentPlan,
} from 'client/dist/generated/alloy';
import { differenceInDays } from 'date-fns';
import { maxBy } from 'lodash';
import { getSingleSupplyProducts } from './getSingleSupplyProducts';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';

export type AccountStatus =
  | 'NO_PURCHASE'
  | 'AWAITING_DOCTOR'
  | 'AWAITING_CUSTOMER'
  | 'AWAITING_CX_OR_QUEUE'
  | 'INACTIVE'
  | 'PRESCRIPTIONS_EXPIRING'
  | 'SINGLE_SUPPLY'
  | 'RECENT_ORDER'
  | 'ACTIVE';

/**
 * this code initially lived in Treatment plan BUT this functionality is needed
 * in quite a few places in the dashboard. breaking this out into a function that
 * will us to create tests and have a little more sturdiness around business logic!
 *
 * @param customerStatus CustomerStatus
 * @param subscriptions SubscriptionWithRenewal[]
 * @param treatmentPlan Treatment | undefined
 * @returns AccountStatus
 */
export const getAccountStatus = (
  customerStatus: CustomerStatus,
  subscriptions: SubscriptionWithRenewal[],
  orders: ApiOrder[],
  treatmentPlan: TreatmentPlan | undefined
): AccountStatus => {
  if (!treatmentPlan) {
    return 'NO_PURCHASE';
  }

  const { pendingCharges, status: tpStatus } = treatmentPlan;

  const { activeSubs, pausedSubs, cancelledSubs } = getSubscriptionsWithStatus(subscriptions);
  const isAwaitingDoctorStatus = tpStatus === 'AWAITING_DOCTOR';

  /**
   * customer has not purchased anything recently, there are no pending charges,
   * or they are new (recently registered without checkout)/inactive
   */
  const hasNoPurchase =
    pendingCharges.length === 0 &&
    tpStatus !== 'PROCESSING' &&
    customerStatus !== 'ACTIVE' &&
    (!isAwaitingDoctorStatus || customerStatus === 'NEW' || customerStatus === 'INACTIVE');

  /**
   * The doctor is reviewing their recent encounter or it is an a window of being completed / waiting on jobs to create the pending charge as customer approval
   *
   * In the instance that we are waiting on a job (minute or two), the user will have the following true:
   * prescriptions in the database
   * customer.status === ACTIVE
   * pending charges from above (treatment plan) filtered by customer approval === 0
   *
   * knowing this, we can infer that also checking subscriptions length === 0 will tell us that they have not approved anything and are not waiting to approve (yet)
   *
   * if we checked for the values above instead of subscriptions.length, we would never be able to get to active plan because on active plan below, the customer
   * status is ACTIVE, they have prescriptions, and should have no pending charges filtered
   */
  const isAwaitingDoctorReview =
    isAwaitingDoctorStatus && (customerStatus === 'PENDING' || activeSubs.length === 0);

  // The customer has pending charges that need to be reviewed by them and they have no subscriptions at all that are active!
  const isAwaitingCustomerApproval = tpStatus === 'AWAITING_CUSTOMER' && activeSubs.length === 0;

  const isAwaitingQueueOrCX =
    (tpStatus === 'PROCESSING' || tpStatus === 'PENDING_CX') && customerStatus === 'PENDING';

  const noActiveSubs =
    activeSubs.length === 0 && (pausedSubs.length > 0 || cancelledSubs.length > 0);

  const somePrescriptionsExpiringSoon = activeSubs.some((sub) =>
    sub.products.some(({ renewal }) => renewal?.needsRenewed && !renewal.completedDate)
  );

  const hasSingleSupply = getSingleSupplyProducts(subscriptions, treatmentPlan).length > 0;

  const mostRecentOrder = maxBy(orders, (order) => !!order.createdAt && new Date(order.createdAt));

  const hasRecentOrder =
    !!mostRecentOrder &&
    !mostRecentOrder.isConsult &&
    (!mostRecentOrder.deliveredOn ||
      (!!mostRecentOrder.deliveredOn &&
        Math.abs(differenceInDays(new Date(), new Date(mostRecentOrder.deliveredOn))) <= 3));

  switch (true) {
    case hasNoPurchase:
      return 'NO_PURCHASE';

    case isAwaitingDoctorReview:
      return 'AWAITING_DOCTOR';

    case isAwaitingCustomerApproval:
      return 'AWAITING_CUSTOMER';

    case isAwaitingQueueOrCX:
      return 'AWAITING_CX_OR_QUEUE';

    case hasSingleSupply:
      return 'SINGLE_SUPPLY';

    case somePrescriptionsExpiringSoon:
      return 'PRESCRIPTIONS_EXPIRING';

    case hasRecentOrder:
      return 'RECENT_ORDER';

    case noActiveSubs:
      return 'INACTIVE';

    default:
      return 'ACTIVE';
  }
};
