import { ShippingMethodType, useGetShippingMethods } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import { useEffect, useState } from 'react';
import { useAppSelector } from 'reducers/alloy_reducer';

import CalendarBlock from '../content/Calendar';
import ProductListBlock from '../content/ProductList';
import ShippingBlock from '../content/Shipping';
import { useSubscriptionContext } from 'context/dashboard/subscriptions/manage';
import { ManageLevel } from 'models/components/shared/manage-type';
import { getProductNameWithDosage } from 'lib/shared/product/name';

interface Props {
  products: GroupedContentfulProduct[][];
  onPause: () => void;
  onConfirm: (date: Date, shippingMethodId?: number) => void;
  parentProduct: GroupedContentfulProduct | undefined;
  manageLevel: ManageLevel;
}

export default function RescheduleWrapper({
  products,
  onPause,
  onConfirm,
  parentProduct,
  manageLevel,
}: Props) {
  const { subscription } = useSubscriptionContext();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: shippingMethods = [], isLoading: isLoadingShippingMethods } = useGetShippingMethods(
    customer.stateAbbr!!.toUpperCase()
  );

  const [selectedShippingType, setSelectedShippingType] = useState<ShippingMethodType>('STANDARD');

  const foundSelectedShippingMethod = shippingMethods.find(
    (sm) => sm.method === selectedShippingType
  );

  useEffect(() => {
    const upcomingInvoice = subscription?.upcomingInvoice;

    if (!!upcomingInvoice) {
      const foundShippingMethod = shippingMethods.find(
        (sm) => sm.priceInCents === upcomingInvoice.shipping
      );

      if (foundShippingMethod) {
        setSelectedShippingType(foundShippingMethod.method);
      }
    }
  }, [shippingMethods.length, isLoadingShippingMethods]);

  return (
    <div className='reschedule-wrapper'>
      {!!parentProduct && (
        <p className='content-text'>
          This treatment is only eligible for a discount when shipped together with{' '}
          {getProductNameWithDosage(parentProduct)[0].cleanName}.
        </p>
      )}

      <ProductListBlock products={products} />

      <CalendarBlock
        shippingMethod={foundSelectedShippingMethod}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />

      <ShippingBlock
        previousType={foundSelectedShippingMethod?.method}
        shippingMethods={shippingMethods}
        onSelect={(sm) => setSelectedShippingType(sm.method)}
      />

      <button
        onClick={() =>
          onConfirm(
            selectedDate!,
            selectedShippingType !== 'STANDARD' ? foundSelectedShippingMethod?.id : undefined
          )
        }
        className='primary-button'
        disabled={!selectedDate}
      >
        Confirm
      </button>

      {manageLevel === 'SUBSCRIPTION' && (
        <p className='reschedule-text'>
          Need a longer break?{' '}
          <button className='text-link' onClick={onPause}>
            Pause subscription
          </button>
        </p>
      )}
    </div>
  );
}
