// FANBOYS
// Internationalization is a future-Alloy problem
export type EnglishConjuctions = 'for' | 'and' | 'nor' | 'but' | 'or' | 'yet' | 'so';

/**
 * Take a given string with questionnaire template syntax and insert the provided text.
 * Optionally provide a conjunction for lists.
 * Oxford commas of course because we are classy, resilient, and thoroughly grounded in the Chicago Style at Alloy.
 * Example: "Here is my questionnaire text and {here is where I want something interpolated} but then we continue"
 *
 * Surely there is a fancy JS way to do this as well, perhaps with String.raw()? If you find it, text Alex at 920-460-0268 to establish javascript dominance.
 */
export const interpolateQuestionText = (
  questionText: string,
  textToInsert?: string[],
  conjunction?: EnglishConjuctions
): string => {
  if (!textToInsert) {
    return questionText;
  }

  const insertLength = textToInsert.length;
  let textFormatted;
  switch (true) {
    case insertLength === 1:
    case !conjunction:
      textFormatted = textToInsert.join(', ');
      break;
    case insertLength === 2:
      textFormatted = textToInsert.join(` ${conjunction} `);
      break;
    case insertLength > 2:
      const initialItems = textToInsert.slice(0, insertLength - 1);
      textFormatted =
        initialItems.join(', ') + `, ${conjunction} ${textToInsert[insertLength - 1]}`;
      break;
    default:
      textFormatted = '';
      break;
  }
  // currently used primarily to replace {relevantProducts} on renewal intake question Q0-0
  const updatedQuestionText = questionText.replace(/\{.*?\}/, textFormatted);
  // deal with plural relevant products list w/r/t has/have in renewal question Q0-0
  const finalText =
    questionText === 'Have {relevantProducts} been helpful in relieving your symptoms?' &&
    insertLength === 1
      ? updatedQuestionText.replace('Have', 'Has')
      : updatedQuestionText;
  return finalText;
};
