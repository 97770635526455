import MoleculesIconBlueBg from 'assets/svg/checkout-experience/expectations/molecules-icon.svg';
import ShippingIconBlueBg from 'assets/svg/checkout-experience/expectations/truck-icon.svg';
import InfiniteIconBlueBg from 'assets/svg/checkout-experience/expectations/ongoing-icon.svg';

import { useState } from 'react';

export default function OrderSummaryProductPerksWrapper() {
  const defaultPerks = [
    {
      icon: ShippingIconBlueBg,
      text: 'Free shipping, Cancel anytime',
    },
    {
      icon: MoleculesIconBlueBg,
      text: 'Science-backed ingredients',
    },
    {
      icon: InfiniteIconBlueBg,
      text: 'Ongoing care and education',
    },
  ];
  const [perks, setPerks] = useState(defaultPerks);

  return (
    <div className='ce-single-page-product-perks-wrapper'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='perks-list'>
              {perks.map((perk, i) => (
                <div key={`perk-item-${i}`} className='product-perk'>
                  <img className='icon' src={perk.icon} alt={perk.text} />
                  <span className='perk-text'>{perk.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
