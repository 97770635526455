import { useState } from 'react';
import { Drawer } from 'rsuite';

import infoIcon from 'assets/svg/core/info-icon.svg';

interface Props {
  hideIcon?: boolean;
  title?: string;
  answer: any;
}

export default function WhyAsking({ hideIcon = false, title = 'Why we ask this?', answer }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button className='why-asking-wrapper' type='button' onClick={() => setIsOpen(true)}>
        {!hideIcon && <img src={infoIcon} alt='info icon' />}

        <span className='why-asking-title'>{title}</span>
      </button>

      <Drawer open={isOpen} onClose={() => setIsOpen(false)}>
        <Drawer.Body>
          <div className='why-asking-content'>{answer}</div>
        </Drawer.Body>
      </Drawer>
    </div>
  );
}
