import {
  addToTreatmentPlan,
  submitTreatmentPlan,
  useGenerateSetupIntent,
  useGetAllSubscriptionsForCustomer,
  useGetPaymentMethods,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { updateTPCart } from 'actions/treatment-plan/cart_actions';

import Loader from 'components/core/Loader';
import SectionWrapper from 'components/core/layout/SectionWrapper';
import OrderButton from 'components/dashboard/treatment-plan/buttons/OrderButton';
import PaymentWrapper from 'components/dashboard/treatment-plan/checkout/Payment';
import ShippingWrapper from 'components/dashboard/treatment-plan/checkout/Shipping';
import SummaryWrapper from 'components/dashboard/treatment-plan/checkout/Summary';

import Layout from 'containers/Layout';

import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import ListViewableProducts from 'components/checkout-experience/products/ListViewableProducts';
import { getDeepProductsFromGroupedProducts } from 'lib/shared/product';
import { useAppSelector } from 'reducers/alloy_reducer';
import BlurbWrapper from 'components/dashboard/treatment-plan/checkout/BlurbWrapper';
import { useNavigate } from 'react-router-dom';

export default function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [cartProducts, setCartProducts] = useState<GroupedContentfulProduct[][]>([]);

  const cart = useAppSelector((state) => state.treatmentPlan.treatmentPlanCart);

  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGetTreatmentPlan();
  const { isLoading: isLoadingPaymentMethods } = useGetPaymentMethods();
  const { data: setupIntent, isLoading: isLoadingSetupIntent } = useGenerateSetupIntent();
  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const isLoading =
    loading || isLoadingTreatmentPlan || isLoadingPaymentMethods || isLoadingSetupIntent;

  const cartDeepProductIds = cart.products
    .flatMap((p) => p.alloyProduct.parent.concat(p.alloyProduct.child ?? []))
    .map((pf) => pf.id);

  const pendingCharges = isLoadingTreatmentPlan ? [] : treatmentPlan!.pendingCharges;

  // Confirm whether any products in the cart are in the pending charge
  // this just gives us extra fluff instead of checking if any pending charges do exist.
  // we don't want to blindly check if pc.length != 0 since a customer could actually come from
  // an add on product instead of the approve plan
  const isPendingChargeCheckout = pendingCharges
    ? pendingCharges.flatMap((pc) => pc.products).some((pf) => cartDeepProductIds.includes(pf.id))
    : false;
  const isAddOnTreatmentPlan = !isPendingChargeCheckout;

  const dispatchUpdateCart = bindActionCreators(updateTPCart, dispatch);

  useEffect(() => {
    if (cart.products.length === 0 || cart.isPurchased) {
      navigate('/treatment-plan', { replace: true });
    } else {
      fetchProducts();
    }
  }, []);

  const fetchProducts = async () => {
    setLoading(true);

    const productIds = getDeepProductsFromGroupedProducts(cart.products);
    const cartProducts = await ProductRegistry.get().getRecurringProductsForV2(productIds);

    setCartProducts(cartProducts);
    setLoading(false);
  };

  const onPlaceOrder = async () => {
    try {
      setLoading(true);

      if (!isAddOnTreatmentPlan) {
        // same idea from the actual treatment plan page in terms of approving pending charges (with no previous purchases)
        // only reason for submitting is because a customer might have previous products purchased and later did consult to which
        // they need to accept the consult products and we need to mark pending charges as complete!

        // only send along the pending charges we've selected products from, the BE will handle the rest in the submit endpoint
        const selectedPendingChargeIds = treatmentPlan!
          .pendingCharges!.filter((pc) => {
            const pendingChargeDeepProductIds = pc.products.map((p) => p.id);
            return pendingChargeDeepProductIds.some((pcdp) => cartDeepProductIds.includes(pcdp));
          })
          .map((pc) => pc.id);

        await submitTreatmentPlan({
          pendingChargeIds: selectedPendingChargeIds,
          productFrequencyIds: cartDeepProductIds,
          promotionCodeId: cart.promotionCode.id,
          shippingMethodId: cart.shipping?.id,
          shipNow: true,
        });
      } else {
        await addToTreatmentPlan({
          productFrequencyIds: cartDeepProductIds,
          promotionCodeId: cart.promotionCode.id,
          shippingMethodId: cart.shipping?.id,
          shipNow: cart.shipNow,
        });
      }

      dispatchUpdateCart({
        isPurchased: true,
      });

      setLoading(false);

      navigate('/treatment-plan');
    } catch (error) {
      setLoading(false);
    }
  };

  if (isLoading || !setupIntent) {
    return (
      <Layout title='Checkout | Alloy' desc='' noBars>
        <TopBannerWithProgress />

        <Loader />
      </Layout>
    );
  }

  return (
    <Layout title='Checkout | Alloy' desc='' noBars>
      <TopBannerWithProgress />

      <SectionWrapper sectionClasses='dashboard-treatment-plan-section'>
        <div className='col-12'>
          <h1 className='tp-checkout-title'>Checkout</h1>
        </div>

        <div className='col-12 col-md-7 order-2 order-md-1 checkout-col-container'>
          {isAddOnTreatmentPlan && <BlurbWrapper />}
          <ShippingWrapper isAddOnTreatmentPlan={isAddOnTreatmentPlan} />
          <PaymentWrapper stripeClientSecret={setupIntent.client_secret} />
          <SummaryWrapper onPlaceOrder={onPlaceOrder} />
        </div>

        <div className='col-12 col-md-5 order-1 order-md-12'>
          {
            <ListViewableProducts
              products={cartProducts}
              subscriptions={subscriptions}
              showDosage
              hideDetails
            />
          }
        </div>
      </SectionWrapper>

      <OrderButton onPlaceOrder={onPlaceOrder} />
    </Layout>
  );
}
