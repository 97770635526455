import { ProfileRow } from 'models/components/profile';

interface Props {
  title: string;
  onUpdate: () => void;
  profileRows: ProfileRow[];
}

export default function ContentBlock({ title, onUpdate, profileRows }: Props) {
  return (
    <div className='profile-content-block'>
      <div className='content-header'>
        <p className='header-title'>{title}</p>

        <button className='secondary-border-button d-none d-md-flex' onClick={onUpdate}>
          Update
        </button>
      </div>

      {profileRows.map((setting, index) => (
        <div className='content-row' key={index}>
          {setting.title && <p className='content-title'>{setting.title}</p>}

          <p className='content-text'>{setting.text}</p>
        </div>
      ))}

      <button
        className='secondary-border-button full-width-button d-block d-md-none'
        onClick={onUpdate}
      >
        Update
      </button>
    </div>
  );
}
