import { ReactNode } from 'react';

import WhyAsking from 'components/core/WhyAsking';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

interface Props {
  wrapperClasses?: string;
  title: string;
  description?: string;
  whyAskingDescription?: string;
  introduction?: JSX.Element;
  children?: ReactNode;
}

/**
 * Simple layout for questions in the questionnaire that allows passing simple data that way it is isn't repetitive
 * and the dev can focus on the content inside ie fields, text, etc
 */
export default function QuestionLayout({
  wrapperClasses = '',
  title,
  description,
  whyAskingDescription,
  introduction,
  children,
}: Props) {
  return (
    <div className={classNames('content-question-wrapper', wrapperClasses)}>
      {introduction && (
        <>
          <p className='question-introduction'>{introduction}</p>
          <hr className='question-hr' />
        </>
      )}

      <h1 className='question-title'>{title}</h1>

      {description && <ReactMarkdown className='question-text'>{description}</ReactMarkdown>}

      {whyAskingDescription && <WhyAsking answer={<p>{whyAskingDescription}</p>} />}

      {children}
    </div>
  );
}
