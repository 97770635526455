import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  sectionClasses?: string;
  containerClasses?: string;
  rowClasses?: string;
  children: ReactNode;
}

/**
 * SectionWrapper allows for you to initiate this anywhere that you might need to utilize bootstrap.
 * This helps not having to constantly set up the section, container, and rows!
 * After calling, you can pass in your columns and add content in there more swiftly!
 * Code also looks cleaner : )
 */
export default function SectionWrapper({
  sectionClasses = 'section',
  containerClasses = 'container',
  rowClasses,
  children,
}: Props) {
  return (
    <section className={sectionClasses}>
      <div className={containerClasses}>
        <div className={classNames('row', rowClasses)}>{children}</div>
      </div>
    </section>
  );
}
