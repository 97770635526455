import { LOADING_TOTALS, PRESCRIBING_DOCTOR, SYMPTOMS, TREATMENT_PLAN_CART } from 'actions/types';
import { AlloyCart } from 'models/alloy/cart';
import { ContentfulPrescribingDoctor } from 'models/contentful/doctor';

import { ContentfulSymptom } from 'models/contentful/symptom';

export interface TreatmentPlanState {
  customerSymptoms: ContentfulSymptom[];
  prescribingDoctor?: ContentfulPrescribingDoctor;

  isLoadingTotals: boolean;

  treatmentPlanCart: AlloyCart;
}

const INIT_STATE: TreatmentPlanState = {
  customerSymptoms: [],

  isLoadingTotals: false,

  /**
   * TreatmentPlanCart is only used for the current state of the app, if there is a refresh it will automatically reset
   * There is no relying on local storage or anything, this will just help keep stuff between different components before
   * placing an order! This can also be utilized for the add ons that way everything stays here for multi components.
   */
  treatmentPlanCart: {
    products: [],
    taxAmountToCollect: 0,
    promotionCode: {
      id: '',
      name: '',
      discountAmount: 0,
    },
    shipping: undefined,
    referralCreditBalance: 0,
    isPurchased: false,
    shipNow: false,
  },
};

// Create reducer
export default function TreatmentPlanReducer(
  state = INIT_STATE,
  action: { payload: Partial<TreatmentPlanState>; type: string }
) {
  switch (action.type) {
    case SYMPTOMS: {
      return { ...state, customerSymptoms: action.payload };
    }

    case PRESCRIBING_DOCTOR: {
      return { ...state, prescribingDoctor: action.payload };
    }

    case LOADING_TOTALS: {
      return { ...state, isLoadingTotals: action.payload };
    }

    case TREATMENT_PLAN_CART: {
      return { ...state, treatmentPlanCart: action.payload };
    }

    default:
      return state;
  }
}
