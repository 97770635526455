import { ContentfulBanner } from 'models/contentful/banner';
import ReactMarkdown from 'react-markdown';
import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  overrideBanner?: ContentfulBanner | null;
}

export default function Banner({ overrideBanner }: Props) {
  const topAlertBanner = useAppSelector((state) => state.alloy.topAlertBanner);

  const banner = overrideBanner || topAlertBanner;

  if (!banner || !banner.fields.isDashboardOn) return <></>;

  return (
    <section className='banner-section'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 text-center'>
            <ReactMarkdown className='banner-text'>{banner.fields.dashboardText}</ReactMarkdown>
          </div>
        </div>
      </div>
    </section>
  );
}
