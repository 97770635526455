import shippingIcon from 'assets/svg/treatment-plan/shipping-icon.svg';
import infinityIcon from 'assets/svg/treatment-plan/infinity-icon.svg';
import mobileIcon from 'assets/svg/treatment-plan/mobile-icon.svg';

import SectionWrapper from 'components/core/layout/SectionWrapper';

const infoItems = [
  {
    icon: shippingIcon,
    text: 'Free shipping, cancel anytime',
    alt: 'shipping icon',
  },
  {
    icon: infinityIcon,
    text: 'Unlimited messaging with your care team; commited to helping you get the right dose.',
    alt: 'infinity icon',
  },
  {
    icon: mobileIcon,
    text: 'Community events and support groups ',
    alt: 'mobile icon',
  },
];

/**
 * Info section is used within the entirety of the treatment plan page in order to display quick info items!
 */
export default function InfoSection() {
  return (
    <SectionWrapper sectionClasses='dashboard-tp-info-section'>
      <div className='col-12'>
        <ul className='info-list'>
          {infoItems.map((info, index) => (
            <li className='list-item' key={index}>
              <img src={info.icon} alt={info.alt} /> <p>{info.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </SectionWrapper>
  );
}
