import { SubmissionAnswer } from 'client/dist/generated/alloy';
import { Question } from 'common/dist/models/questionnaire';
import { useEffect, useState } from 'react';
import { Route, useNavigate, useLocation, Routes, Navigate } from 'react-router-dom';

import Questionnaire from './Questionnaire';

import {
  getFilteredPages,
  getPages,
  getQuestionSlugs,
} from 'lib/checkout-experience/questionnaire/question';
import { getAnswersForSubmission } from 'lib/checkout-experience/questionnaire/submission';
import { formatExperienceURL, formatURL } from 'lib/core/url';

import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { PreviousAnswersLocalStorage } from 'models/alloy/questionnaire';
import { RelevantProduct } from 'models/alloy/experience';
import { QuestionnaireAnswers } from 'models/components/questionnaire';
import { setItem } from 'client/dist/localstorage';

interface Props {
  questions: Question[];
  previousResults: QuestionnaireAnswers;
  onSubmit: (results: SubmissionAnswer[]) => void;
  onBack?: () => void;
  hideProgressBar?: boolean;
  parentUrlPath: string;
  metaTitle?: string;
  relevantProducts?: RelevantProduct[];
  introduction?: JSX.Element;
}

export default function QuestionnaireRoutes({
  questions,
  previousResults,
  onSubmit,
  onBack,
  hideProgressBar = false,
  parentUrlPath,
  metaTitle = 'Intake - Alloy',
  relevantProducts = [],
  introduction,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [answers, setAnswers] = useState<QuestionnaireAnswers>(previousResults);

  const categories = retrieveCategoriesFromUrl(location);

  const pages = getPages(questions, relevantProducts);

  const slugs = getQuestionSlugs(questions, relevantProducts);

  useEffect(() => {
    if (categories.length === 0) {
      navigate(`${parentUrlPath}/${slugs[0]}`, { replace: true });
    } else {
      navigate(formatExperienceURL(`${parentUrlPath}/${slugs[0]}`, location, categories), {
        replace: true,
      });
    }
  }, []);

  /**
   * For filtering through the slugs, we need to be able to understand what part of
   * the pageSections the user is on
   *
   * @returns number - page number or index from the array slugs
   */
  const getPageFromUrl = (): number => {
    const urlSplit = location.pathname.split('/');

    return slugs.findIndex((s) => urlSplit.includes(s));
  };

  /**
   * Go forward within the Questionnaire and make sure we go to the correct page based on answer selections
   *
   * @param newAnswers QuestionnaireAnswers - updated / selected answers passed from the child component of QuestionnaireSection
   */
  const handleNext = (newAnswers: QuestionnaireAnswers) => {
    window.scrollTo(0, 0);

    const page = getPageFromUrl();

    const updatedAnswers: QuestionnaireAnswers = {
      ...answers,
      ...newAnswers,
    };

    setAnswers(updatedAnswers);

    const previousAnswersUpdated: PreviousAnswersLocalStorage = {
      categories,
      answers: updatedAnswers,
    };

    setItem('recentIntakeAnswers', previousAnswersUpdated, true);

    const filteredPages: Question[][] = getFilteredPages(pages, updatedAnswers);

    let nextPage = page + 1;

    while (nextPage < pages.length && filteredPages[nextPage].length < 1) {
      nextPage++;
    }

    const nextSlug = slugs[nextPage];

    if (nextPage < pages.length) {
      if (categories.length === 0) {
        navigate(formatURL(`${parentUrlPath}/${nextSlug}`, location));
      } else {
        navigate(formatExperienceURL(`${parentUrlPath}/${nextSlug}`, location, categories));
      }
    } else {
      const results = getAnswersForSubmission(questions, updatedAnswers);

      onSubmit(results);
    }
  };

  /**
   * Go backwards within the ui in the questionnaire, we need to take them back
   * either one pageSection (that is filtered) or take them back through checkout experience
   */
  const handleBack = () => {
    window.scrollTo(0, 0);

    const page = getPageFromUrl();

    const filteredPages: Question[][] = getFilteredPages(pages, answers);

    let prevPage = page - 1;

    while (prevPage >= 0 && filteredPages[prevPage].length < 1) {
      prevPage--;
    }

    if (prevPage >= 0) {
      navigate(-1); // react-router-dom v6 putting the -1 is the same as goBack()
    } else if (onBack) {
      onBack();
    }
  };

  return (
    <Routes>
      {pages.map((pageQuestions, index) => (
        <Route
          key={index}
          path={slugs[index]}
          element={
            <Questionnaire
              questions={pageQuestions}
              allAnswers={answers}
              handleNext={handleNext}
              // there doesn't need to be a back button for the request experience since it is the first page
              // TODO: go over a better approach around universal usage - for mikhail
              handleBack={
                (index === 0 && parentUrlPath.includes('request-experience')) ||
                (!onBack && index === 0)
                  ? undefined
                  : handleBack
              }
              hideProgressBar={hideProgressBar}
              metaTitle={metaTitle}
              relevantProducts={relevantProducts}
              introduction={introduction}
              type={categories.length === 0 ? 'survey' : 'intake'}
              isLastQuestion={pages.length - 1 === index}
            />
          }
        />
      ))}
    </Routes>
  );
}
