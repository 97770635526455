import { useAppSelector } from 'reducers/alloy_reducer';

import SectionWrapper from 'components/core/layout/SectionWrapper';
import HeaderBlock from '../core/HeaderBlock';
import InfoSection from '../../shared/sections/Info';
import TestimonialsSection from '../../shared/sections/Testimonials';

import { EmailUs } from 'components/core/Buttons';
import DashboardContainer from 'containers/dashboard/DashboardContainer';
import MessageBlock from '../core/MessageBlock';

export default function AwaitingQueueOrCX() {
  const customer = useAppSelector((state) => state.alloy.customer!!);

  return (
    <DashboardContainer title='Treatment Plan | Alloy' desc='' currentPage='treatment plan'>
      <HeaderBlock title='Treatment plan for' fancyTitle={customer.firstName ?? ''} />

      <SectionWrapper
        sectionClasses='dashboard-treatment-plan-section extra-bottom-padding'
        rowClasses='justify-content-center'
      >
        <div className='col-12 col-md-7'>
          <MessageBlock
            title='Check back soon.'
            text={
              <>
                If your doctor has finalized your treatment plan, your recommended treatments should
                appear within 10 minutes. If it is taking longer, our support team is likely
                managing your order. Please reach out to <EmailUs /> if you have any questions.
              </>
            }
          />
        </div>
      </SectionWrapper>

      <InfoSection />
      <TestimonialsSection />
    </DashboardContainer>
  );
}
