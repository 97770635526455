import { updateToNewCheckoutExperienceFlow } from 'actions/checkout-experience/flow_actions';
import emptyPlanBg from 'assets/images/treatment-plan/empty-plan.png';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from '@reduxjs/toolkit';

interface Props {
  title?: string;
  buttonTitle?: string;
}

export default function EmptyStateBlock({
  title = 'Menopause relief',
  buttonTitle = 'Start your assessment',
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dispatchUpdateToNewCheckoutExperience = bindActionCreators(
    updateToNewCheckoutExperienceFlow,
    dispatch
  );

  const onStart = () => {
    dispatchUpdateToNewCheckoutExperience(['mht'], navigate);
  };

  return (
    <div className='empty-state-block'>
      <img src={emptyPlanBg} alt='alloy' className='empty-state-img' />

      <div className='empty-state-content'>
        <p className='content-title'>{title}</p>
        <p className='content-subtitle'>$49</p>

        <ul className='content-list'>
          <li className='list-item'>Take a quick assessment & select products</li>
          <li className='list-item'>
            A menopause-trained doctor reviews your history and answers your questions
          </li>
          <li className='list-item'>Get your prescription products delivered</li>
        </ul>

        <div className='text-center'>
          <button className='primary-button' onClick={onStart}>
            {buttonTitle}
          </button>
        </div>
      </div>
    </div>
  );
}
