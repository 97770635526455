import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'reducers/alloy_reducer';
import DiscountBlock from '../summary/DiscountBlock';
import TotalsBlock from '../summary/TotalsBlock';

interface Props {
  onPlaceOrder?: () => void;
  shouldDisableContinue?: boolean;
}

export default function SummaryWrapper({ onPlaceOrder, shouldDisableContinue }: Props) {
  const location = useLocation();

  const isEditingShipping = useAppSelector((state) => state.experience.isEditingShipping);

  const isRequestExperience = location.pathname.includes('request-experience');

  return (
    <>
      <div className='ce-summary-wrapper'>
        <div className='ce-block-header'>
          <p className='header-title'>
            <span className='header-number'>3</span>
            Order summary
          </p>
        </div>

        <DiscountBlock />
        <TotalsBlock />

        {isRequestExperience && (
          <>
            <div className='d-none d-sm-block'>
              <button
                className='primary-button full-width-button'
                onClick={onPlaceOrder}
                disabled={shouldDisableContinue}
              >
                Place order
              </button>
            </div>

            {/* Sticky CTA for any mobile device */}
            {!isEditingShipping && (
              <div className='ce-sticky-button-block'>
                <button
                  className='primary-button full-width-button'
                  onClick={onPlaceOrder}
                  disabled={shouldDisableContinue}
                >
                  Place order
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
