import { DeepProduct } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import ProductRegistry from 'client/dist/product/productRegistry';

/**
 * given product frequencies that already fetched eligible products (those that a customer has not
 * purchased or are active), we use our product registry to get all contentful upsell products then filter
 * those contentful upsell products against the product frequencies provided and use our other registry
 * methods to fetch the grouped contentful products (at the moment, upsell will not have child but this
 * is setting us up for the future if there ever will be a case of it!)
 *
 * @param products DeepProduct[]
 * @returns GroupedContentfulProduct[]
 */
export const getUpsellGroupedContentfulProducts = async (
  products: DeepProduct[]
): Promise<GroupedContentfulProduct[]> => {
  const upsellContentfulProducts = await ProductRegistry.get().getUpsellContentfulProducts();

  const filteredProducts = products.filter((pf) =>
    pf.contentfulProductId
      ? upsellContentfulProducts
          .sort((a, b) => b.fields.order - a.fields.order)
          .map((ucp) => ucp.sys.id)
          .includes(pf.contentfulProductId)
      : false
  );

  return (await ProductRegistry.get().getRecurringProductsForV2(filteredProducts)).flat();
};
