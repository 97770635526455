import { capitalize, isEmpty } from 'lodash';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'reducers/alloy_reducer';
import { Form } from 'rsuite';

import BottomBar from 'components/checkout-experience/BottomBar';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import { SELECTIONS } from 'data/checkout-experience/content/relief-type';

import { getPercentComplete } from 'lib/checkout-experience/progress';

import posthog from 'posthog-js';

import FeaturesBlock from 'components/checkout-experience/expectations/FeaturesBlock';
import ImprovementBlock from 'components/checkout-experience/expectations/ImprovementBlock';
import { FieldsGroup } from 'components/checkout-experience/relief-type/FieldsGroup';
import { SelectFieldsGroup } from 'components/core/fields/SelectFieldsGroup';
import { featuresWithLightIcons } from 'data/checkout-experience/content/expectations';
import { ExperienceComponentModel } from 'models/alloy/experience';

export default function ReliefType({ onNext, onBack }: ExperienceComponentModel) {
  const location = useLocation();

  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

  let activeSelections = SELECTIONS;

  const customer = useAppSelector((state) => state.alloy.customer);
  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);
  const firstName = capitalize(customer ? customer.firstName : localPreCustomer.firstName) ?? '';

  const onSubmit = async () => {
    if (selectedId) {
      const foundSelection = activeSelections.find((s) => s.id === selectedId);

      if (foundSelection) {
        posthog.capture('reliefTypesSelected', {
          $set: {
            intake_categories: foundSelection.categories,
          },
        });

        onNext(foundSelection.categories);
      }
    }
  };

  const selections = activeSelections.map((s) => ({
    text: s.text,
    id: s.id,
    title: s.title,
    categories: s.categories,
    imageConfig: s.imageConfig,
  }));

  return (
    <Layout title='Select relief - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={onBack} percentComplete={getPercentComplete(location)} />

      <Form>
        <CheckoutExperienceSection colClass='col-12 col-md-10'>
          <h1 className='content-title'>
            {isEmpty(firstName)
              ? 'What brings you to Alloy today?'
              : `Hi ${firstName}, what brings you to Alloy today?`}
          </h1>
          <p className='content-text'>Select the answer that best describes your concerns today.</p>

          <FieldsGroup selectedId={selectedId} onSelect={setSelectedId} options={selections} />

          <div className='enhancers-wrapper'>
            <FeaturesBlock data={featuresWithLightIcons} customBg='dark-bg' />
            <ImprovementBlock
              title='95% of women '
              text='will see improvement in symptoms within 4-12 weeks of starting treatment'
            />
          </div>
        </CheckoutExperienceSection>

        <BottomBar>
          <button
            className='primary-button'
            type='submit'
            disabled={!selectedId}
            onClick={onSubmit}
          >
            Next
          </button>
        </BottomBar>
      </Form>
    </Layout>
  );
}
