import { IntakeCategory } from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import getIntakesByCategory from 'common/dist/intake/getIntakesByCategory';
import { ExperienceCategory } from 'common/dist/models/experience';
import { Question } from 'common/dist/models/questionnaire';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { DeepProduct } from 'common/dist/products/productFrequency';
import { requestFlows } from 'data/request-experience/flow';

import { SharedLocation } from 'common/dist/models/shared/location';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { isGroupedProductInProductsList } from 'lib/shared/product';
import { uniq } from 'lodash';
import { ExperienceFlow } from 'models/alloy/experience';

export const retrieveProductIdsFromUrl = (location: SharedLocation): number[] => {
  const searchParams = new URLSearchParams(location.search);

  const productIds: string | null = searchParams.get('productIds[]');

  const splitProductIds = productIds ? productIds.split(',') : [];

  const parsedProductIds = splitProductIds.map((pid) => parseInt(pid));

  return parsedProductIds;
};

/**
 *
 */
export const retrieveFlowFromUrl = (location: SharedLocation): ExperienceFlow => {
  const retrievedCategories: ExperienceCategory[] = retrieveCategoriesFromUrl(location);

  return getBuiltFlowFromCategories(retrievedCategories);
};

// TODO: do some cleaning around this, mht won't exist yet so don't need a default flow
export const getBuiltFlowFromCategories = (categories: ExperienceCategory[]): ExperienceFlow => {
  let questions: Question[] | undefined;
  // Filter categories (keys) to get all of the ones from the param 'categories'
  const filteredFlowCategories = Object.keys(requestFlows).filter((key) =>
    categories.includes(key as ExperienceCategory)
  );

  // If no categories found by categories passed, quick return the standard flow
  if (filteredFlowCategories.length === 0) return requestFlows['mht'];

  // Find the best category in terms of the one having the most steps compared to our filtered categories
  // If a customer wants mht and skin-health categories then we need to use mht steps since it would be
  // our default as it has the most steps out of the two categories
  const bestCategory = filteredFlowCategories.reduce((categoryA, categoryB) => {
    return requestFlows[categoryA].steps.length > requestFlows[categoryB].steps.length
      ? categoryA
      : categoryB;
  });

  if (!categories.every((c) => c === 'gut-health')) {
    questions = getIntakesByCategory(categories as IntakeCategory[]);
  }

  // The steps the customer has to go through based on selection
  const steps = requestFlows[bestCategory].steps;

  // All the product ids to be shown in cart at checkout
  // If mht (consult) is included in the flows then we just need the dr consult product in cart otherwise get all the products in there!
  const productIds = filteredFlowCategories.includes('mht')
    ? requestFlows['mht'].productIds
    : filteredFlowCategories.flatMap((category) => requestFlows[category].productIds);

  return {
    intakeQuestions: questions,
    steps,
    productIds,
  };
};

/**
 * Return 2D array of requested products using products from active subs to get
 * their correct bundled product config.
 *
 * For v1 of request experience, we might run into an instance where a customer (for bundle)
 * has purchased m4 before BUT never had tret before as a prescription. for this, we need to get
 * them the bundled pf in order for them to see the cheaper price since they have m4 active
 * With work around cleaning split stuff, TODO: mikhail to target this for a bit of a better functional
 * approach but this is so we can get it rolling for v1
 *
 * @param requestedProducts
 * @param activeSubsProducts
 * @returns Promise<GroupedContentfulProduct[][]>
 */
export const cleanGroupedPurchasableProducts = async (
  requestedProducts: DeepProduct[],
  activeSubsProducts: DeepProduct[]
): Promise<GroupedContentfulProduct[][]> => {
  // just all product ids from cart (requested products) and active subs
  const collated = uniq([...requestedProducts, ...activeSubsProducts]);

  const products = await ProductRegistry.get().getRecurringProductsForV2(collated);

  // filter out against active subs that way no current purchased products get in and return them
  return products
    .map((gcpList) =>
      gcpList.filter((gcp) => !isGroupedProductInProductsList(activeSubsProducts, gcp))
    )
    .filter((gcpList) => gcpList.length);
};

/**
 * Same as cleanGroupedPurchasableProducts but in this case
 * we return a 1D array for when having an 2D array
 * is not necessary.
 *
 * @param requestedProducts
 * @param activeSubsProducts
 * @returns Promise<GroupedContentfulProduct[]>
 */
export const cleanPurchasableProducts = async (
  requestedProducts: DeepProduct[],
  activeSubsProducts: DeepProduct[]
): Promise<GroupedContentfulProduct[]> => {
  return (await cleanGroupedPurchasableProducts(requestedProducts, activeSubsProducts)).flat();
};
