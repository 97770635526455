import dayjs from 'dayjs';
import { isArray, transform } from 'lodash';

const ISO_DATE_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;
const MAX_DEPTH = 10;

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && ISO_DATE_FORMAT.test(value);
}

/**
 * This functions reads the body of an axios response and update it so every
 * date string field is converted to Date object type.
 *
 * @param body body to convert ISO date string fields to Date
 * @param depth depth to start - don't pass this, only used on the recursive step
 * @returns body with string date fields converted to Date object
 */
function datesHandler(body: any, depth: number = 0) {
  if (body === null || body === undefined || typeof body !== 'object') {
    return body;
  }

  const initialValue = isArray(body) ? [] : {};

  return transform(
    body,
    (updatedBody: any, value, key) => {
      let updatedValue = value;
      if (isIsoDateString(value)) {
        updatedValue = dayjs(value).toDate();
      } else if (typeof value === 'object' && depth <= MAX_DEPTH) {
        updatedValue = datesHandler(value, depth + 1);
      }
      updatedBody[key] = updatedValue;
    },
    initialValue
  );
}

export default {
  datesHandler,
};
