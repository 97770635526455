import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import { getUnixTime } from 'date-fns';

/**
 *
 * @param subscriptions SubscriptionWithRenewal[]
 * @returns SubscriptionWithRenewal
 */
export const sortSubscriptions = (
  subscriptions: SubscriptionWithRenewal[]
): SubscriptionWithRenewal[] =>
  subscriptions.sort(
    (a, b) => getUnixTime(new Date(a.nextRecurrenceOn)) - getUnixTime(new Date(b.nextRecurrenceOn))
  );
