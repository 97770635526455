import classNames from 'classnames';
import {
  EventType,
  markNotificationsAsReadByEventType,
  useGetAllUnreadNotifications,
} from 'client/dist/generated/alloy';
import Loader from 'components/core/Loader';
import { processNotifications } from 'lib/dashboard/notifications/transformNotifs';
import { useCallback, useEffect, useRef, useState } from 'react';
import NotificationRow, { EmptyOrErrorNotificationRow } from './NotificationRow';

interface Props {
  trigger: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  onOpenDrawer: () => void;
}

export default function Popover({ trigger, className, onOpenDrawer }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isPositioned, setIsPositioned] = useState(false);

  const {
    data: notifications = [],
    isLoading: isLoadingNotifications,
    error: hasNotificationsError,
    mutate,
  } = useGetAllUnreadNotifications();

  const popoverRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const transformedNotifications = processNotifications(notifications, true);

  const visibleNotifications = transformedNotifications.slice(0, 4);

  const showSeeMore = transformedNotifications.length > 4;

  const handleSeeMore = () => {
    setIsOpen(false); // close the popover
    onOpenDrawer(); // open the notifications drawer
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const togglePopover = (): void => {
    setIsOpen(!isOpen);
  };

  const positionPopover = useCallback(() => {
    if (popoverRef.current && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const rightPosition = window.innerWidth - (triggerRect.left + triggerRect.width);

      popoverRef.current.style.top = `${triggerRect.bottom}px`;
      popoverRef.current.style.right = `${rightPosition}px`;

      // Mark as positioned after setting the position to avoid "jumpy" entrance
      setIsPositioned(true);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsPositioned(false);
      positionPopover();
    } else {
      setIsPositioned(false);
    }
  }, [isOpen]);

  const onMarkRead = async (eventType: EventType) => {
    await markNotificationsAsReadByEventType({ eventTypes: [eventType] });
    await mutate();
    setIsOpen(false);
  };

  const renderNotifications = () => {
    if (hasNotificationsError) {
      return <EmptyOrErrorNotificationRow />;
    }

    if (isLoadingNotifications) {
      return <Loader />;
    }

    if (!!hasNotificationsError || notifications.length === 0) {
      return <EmptyOrErrorNotificationRow />;
    }

    return visibleNotifications.map((notification, idx) => (
      <NotificationRow notification={notification} onMarkRead={onMarkRead} key={idx} />
    ));
  };

  return (
    <div className={classNames('popover-container', className)}>
      <div ref={triggerRef} onClick={togglePopover}>
        {trigger}
      </div>

      {isOpen && (
        <div
          ref={popoverRef}
          className='popover-content'
          style={{
            position: 'fixed',
            opacity: isPositioned ? 1 : 0,
            transition: 'opacity 0.1s',
          }}
        >
          {renderNotifications()}

          {showSeeMore && (
            <button className='text-link' onClick={handleSeeMore}>
              See more notifications
            </button>
          )}
        </div>
      )}
    </div>
  );
}
