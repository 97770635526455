import * as braze from '@braze/web-sdk';
import { Customer } from 'client/dist/generated/alloy';
import { DeepProduct } from 'common/dist/products/productFrequency';
import { ExperienceCategory } from 'common/dist/models/experience';
import dayjs from 'dayjs';
import { getShippingFrom } from 'lib/shared/customer/shipping';
import { CartAbandonEventType } from 'models/alloy/cart-abandon/cartAbandonEvent';
import { LocalPreCustomer } from 'reducers/experience_reducer';
import { formatProductNameForBraze } from 'common/dist/braze/braze';

export const brazeInit = () => {
  braze.initialize(process.env.REACT_APP_BRAZE_API_KEY!!, {
    baseUrl: process.env.REACT_APP_BRAZE_SDK_ENDPOINT!!,
  });
};

export const identifyBraze = (customer: Customer) => {
  braze.changeUser(customer.patientId);
  braze.openSession();
};

export const brazeLogout = () => {
  braze.wipeData();
  brazeInit();
};

export type Address = Pick<
  Customer,
  'shippingAddressLineOne' | 'shippingAddressLineTwo' | 'city' | 'stateAbbr' | 'zip'
>;

export type BrazeCustomer = Partial<
  Pick<Customer, 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'stateAbbr'> &
    Pick<LocalPreCustomer, 'state'> &
    Address
>;

type BrazeCustomerStringOnly = Omit<BrazeCustomer, keyof Address>;
type BrazeCustomerObjectOnly = { shippingAddress: Pick<BrazeCustomer, keyof Address> };

/**
 * Clean up string value by trimming and lowercasing (optionally)
 * and return it cleaned, ready to send to braze
 * @param s
 * @param shouldLower - should we lowercase too
 *
 * @returns the string trimmed
 */
export const cleanStringForBraze = (s: string | undefined, shouldLower: boolean = false) => {
  if (!s) {
    return undefined;
  }

  const trimmed = s.trim();

  if (trimmed === '') {
    return undefined;
  }

  if (shouldLower) {
    return trimmed.toLocaleLowerCase();
  } else {
    return trimmed;
  }
};

/**
 * it cleans up (trim) the fields on an object
 * and return it cleaned, ready to send to braze.
 *
 * @param obj
 * @returns obj with each value trimmed
 */
export const cleanObjectForBraze = (obj: object | undefined) => {
  const cleaned: Record<string, string> = { ...obj };

  Object.keys(cleaned).forEach((key) => {
    const value = cleaned[key];

    cleaned[key] = value?.trim();
  });

  return cleaned;
};

/**
 * given one of the string values that we've in the BrazeCustomer,
 * 1. find it in the customer
 * 2. trim/clean it before sending to braze
 *
 * return it cleaned ready for sending
 *
 * @param customer {BrazeCustomer}
 * @param k one of the keys that we've in the BrazeCustomer (string only props)
 * @param brazeStringSetter
 * @param lower optional - in case we want it lowercase
 * @returns the string cleaned
 */
const setStringForBraze = (
  customer: BrazeCustomerStringOnly,
  k: keyof BrazeCustomerStringOnly,
  brazeStringSetter: (cleaned: string) => void,
  lower: boolean = false
) => {
  const cleaned = cleanStringForBraze(customer[k], lower);

  return cleaned && brazeStringSetter(cleaned);
};

/**
 * given one of the object values that we've in the BrazeCustomer,
 * 1. find it in the customer
 * 2. trim/clean each one of the key/value pairs in it before sending to braze
 *
 * return it cleaned ready for sending
 *
 * @param customer {BrazeCustomer}
 * @param k one of the keys that we've in the BrazeCustomer (object only props)
 * @param brazeStringSetter
 * @returns the object cleaned
 */
const setObjectForBraze = (
  customer: BrazeCustomerObjectOnly,
  k: keyof BrazeCustomerObjectOnly,
  brazeObjectSetter: (cleaned: object) => void
) => {
  const cleaned = cleanObjectForBraze(customer[k]);

  return cleaned && brazeObjectSetter(cleaned);
};

// TODO we may want to ask braze to merge
// a preidentified customer here

/**
 * This gets called frequently in the front end, meaning braze will often get the latest and greatest
 * values from our API.
 *
 * Note that this can be invoked with either `LocalPreCustomer` or `Customer` (we should consolidate these),
 * and there are probably a few more fields to consider.
 *
 * Their web SDK seems to be their more standardized path for web applications, so we let this do the
 * heavy lifting (if roundabout).
 *
 * @param customer
 */
export const brazeSetPartial = async (customer: BrazeCustomer) => {
  const user = braze.getUser();

  if (!user) {
    console.error('Braze user was not defined');
    return;
  }

  setStringForBraze(
    customer,
    'email',
    (s) => {
      user.setEmail(s);
      user.addAlias(s, 'email');
    },
    true
  );
  setStringForBraze(customer, 'firstName', (s) => user.setFirstName(s));
  setStringForBraze(customer, 'lastName', (s) => user.setLastName(s));
  setStringForBraze(customer, 'phoneNumber', (s) => user.setPhoneNumber(s));
  // also sets the shipping address as a custom object user attribute
  // so we have the same attributes that we used to send at the BE crmService
  const address = getShippingFrom(customer);

  if (address.shippingAddressLineOne) {
    setObjectForBraze({ shippingAddress: address }, 'shippingAddress', (o) =>
      user.setCustomUserAttribute('shippingAddress', o)
    );
  }
};

/**
 * get the braze user and check if the abandon event is one of "REGISTRATION_COMPLETE",
 * "CHECKOUT_SHOWN" and "CHECKOUT_COMPLETED".
 * if so, set the `intakeCategories` attribute on braze user to an object containing
 * the categories (and the product ids if it's from a request experience flow)
 *
 * @param abandonEvent
 * @param categories
 * @param avRequiredState
 * @param products
 * @param url
 * @returns
 */
export const brazeSendAbandonEvent = async (
  abandonEvent: CartAbandonEventType,
  categories: ExperienceCategory[],
  avRequiredState: string | undefined,
  products: DeepProduct[] = [],
  url: string
) => {
  const user = braze.getUser();

  if (!user) {
    console.error('Braze user was not defined');
    return;
  }

  // used in Braze to direct the customer back to the point they left

  // TODO silly to have a switch that only ever does one thing
  // change invocations to only fire on reg/checkout shown/checkout completed

  switch (abandonEvent) {
    case 'REGISTRATION_COMPLETE':
    case 'CHECKOUT_SHOWN':
    case 'CHECKOUT_COMPLETED':
      const intakeEvent = await getIntakeEvent(categories, abandonEvent, products);
      user.setCustomUserAttribute('intakeCategoriesV2', intakeEvent, true);
      break;
    default:
      break;
  }

  return braze.logCustomEvent(abandonEvent, {
    avRequiredState,
    returnUrl: url,
  });
};

/**
 * through an array of categories, an abandon event type and a array of product ids
 * which returns an intakeEvent dictionary with the current categories as the key and all
 * the params in an object as the value.
 * @param categories
 * @param abandonEvent
 * @param products
 * @returns
 */
const getIntakeEvent = async (
  categories: ExperienceCategory[],
  abandonEvent: CartAbandonEventType,
  products: DeepProduct[]
) => {
  const intakeEvent: { [key: string]: object } = {};

  for (const category of categories) {
    const filteredProducts = products
      .filter((p) => p?.category === category)
      .map((p) => formatProductNameForBraze(p, false));

    intakeEvent[category] = {
      createdAt: dayjs().toString(),
      event: abandonEvent,
      products: filteredProducts,
    };
  }

  return intakeEvent;
};
