import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import Divider from '../Divider';
import SelectableProduct from '../products/SelectableProduct';
import TextSelectableProduct from './TextSelectableProduct';

import { RequestingDeepProductIds } from 'reducers/experience_reducer';
import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  products: GroupedContentfulProduct[];
  requestedDeepProductIds: RequestingDeepProductIds;
  onSelect: (groupedProduct?: GroupedContentfulProduct) => void;
}

export default function SelectableProductsGroup({
  products,
  requestedDeepProductIds,
  onSelect,
}: Props) {
  const { showKnownPreferencesTesting } = useAppSelector((state) => state.abTesting);

  return (
    <>
      {!showKnownPreferencesTesting && (
        <>
          <TextSelectableProduct
            isSelected={requestedDeepProductIds.length === 0}
            onSelect={onSelect}
          />
          <Divider text='Or' />
        </>
      )}

      {products.map((gcp, index) => {
        const isSelected = requestedDeepProductIds.some((pid) =>
          gcp.alloyProduct.parent.every((pf) => pf.id === pid)
        );

        return (
          <SelectableProduct
            key={index}
            groupedProduct={gcp}
            isSelected={isSelected}
            onSelect={onSelect}
            multiSelect
            hideDetails
            showProgDetails
          />
        );
      })}
    </>
  );
}
