import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { initializeApp } from 'actions/core/app_actions';

import Loader from 'components/core/Loader';
import { AuthRoute, PrivateRoute } from 'components/routes/Routes';

import { RedirectRoutes } from 'data/core/redirects';
import { AUTHENTICATION_ROUTES, PRIVATE_ROUTES, PUBLIC_ROUTES } from 'data/core/routes';

import { formatURL } from 'lib/core/url';
import PosthogShim from 'lib/tracking/PosthogShim';

import CheckoutExperience from 'pages/checkout-experience/CheckoutExperience';
import NotFound from 'pages/other/NotFound';
import RequestExperience from 'pages/request-experience/RequestExperience';

import { getTinuitiId, getUtms } from 'client/dist/events/tinuiti';
import { sendSessionStart } from 'client/dist/generated/alloy';
import { sendExceptionToSentry } from 'lib/tracking/sentry';
import { useAppSelector } from 'reducers/alloy_reducer';

export default function Main() {
  const dispatch = useDispatch();

  const dispatchInitializeApp = bindActionCreators(initializeApp, dispatch);

  const { isAppLoaded } = useAppSelector((state) => state.alloy);

  useEffect(() => {
    dispatchInitializeApp();
  }, []);

  useEffect(() => {
    try {
      const { tinuitiId, isNewId } = getTinuitiId();
      // we only want to send the SESSION_START event if a customer's session begins at dash
      // otherwise we send the SESSION_START event from marketing
      if (isNewId) {
        const utms = getUtms(window.location);
        const referrer = document.referrer;
        sendSessionStart({
          lead: {
            id: tinuitiId,
            ...(utms.utm_campaign ? { utmCampaign: utms.utm_campaign } : {}),
            ...(utms.utm_source ? { utmSource: utms.utm_source } : {}),
            ...(utms.utm_medium ? { utmMedium: utms.utm_medium } : {}),
            ...(referrer ? { referrer } : {}),
          },
        }).catch((e) => {
          sendExceptionToSentry(e as Error);
        });
      }
    } catch (e) {
      sendExceptionToSentry(e as Error);
    }
  }, []);

  if (!isAppLoaded) return <Loader />;

  return (
    <BrowserRouter>
      <PosthogShim>
        <Routes>
          {PUBLIC_ROUTES.map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />} />
          ))}

          {AUTHENTICATION_ROUTES.map((route, index) => (
            <Route key={index} path={route.path} element={<AuthRoute comp={route.component} />} />
          ))}

          {PRIVATE_ROUTES.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<PrivateRoute path={route.path} comp={route.component} />}
            />
          ))}

          {/* Experience routes */}
          <Route path='checkout-experience/*' element={<CheckoutExperience />} />
          <Route path='request-experience/*' element={<RequestExperience />} />

          {/* Redirect routes */}
          {RedirectRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.oldPath}
              element={
                <Navigate to={formatURL(route.newPath, window.location, route.params)} replace />
              }
            />
          ))}

          {/* 404 route */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </PosthogShim>
    </BrowserRouter>
  );
}
