import { useGetPaymentMethods } from 'client/dist/generated/alloy';
import PaymentBlock from '../submit/summary/PaymentBlock';

interface Props {
  stripeClientSecret: string;
}

export default function Payment({ stripeClientSecret }: Props) {
  const { data: paymentMethods = [] } = useGetPaymentMethods();

  return (
    <div className='tp-checkout-payment-wrapper'>
      <p className='checkout-payment-title'>Payment method</p>

      <PaymentBlock paymentMethods={paymentMethods} stripeClientSecret={stripeClientSecret} />
    </div>
  );
}
