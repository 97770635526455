import { Modal } from 'rsuite';

interface Props {
  open: boolean;
  onClose: () => void;
}

// TODO: Sometime later clean this up to follow modal pattern
export default function ExampleVideoModal({ open, onClose }: Props) {
  return (
    <Modal
      open={open}
      overflow={true}
      size='lg'
      className='modal-light video-modal'
      onClose={onClose}
    >
      <Modal.Header />

      <Modal.Body>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-10 col-md-4'>
              <div className='video-modal-block text-center'>
                <h4 className='video-title'>Video example</h4>
              </div>

              <div className='video-example-wrapper'>
                <video controls autoPlay>
                  <source
                    src='https://videos.ctfassets.net/md0kv0ejg0xf/2XsEpaCaYVW6p4ExlY7h23/861fc2dd3967272c3c05000deb52205c/av_sync_example.mp4'
                    type='video/mp4'
                  />
                  Your browser does not support videos
                </video>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
