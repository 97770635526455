import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ContentfulProduct } from 'common/dist/models/contentful/product';

import { useState } from 'react';
import { Drawer } from 'rsuite';

import closeIcon from 'assets/svg/btn-close.svg';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  product: ContentfulProduct;
}

export default function ProductInfoDrawer({ open, setOpen, product }: Props) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const learnTitles = ['Details', 'Safety information', 'Suggested use'];

  const productInfo: any[] = [
    product.fields.learnDetails,
    product.fields.learnSafety,
    product.fields.learnSuggestedUse,
  ];

  const onSelectTitle = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    e.stopPropagation();
    setSelectedIndex(index);
  };

  const onClose = () => {
    setSelectedIndex(0);
    setOpen(false);
  };

  return (
    <Drawer open={open} size='sm' className='product-info-drawer' onClose={onClose}>
      <Drawer.Body>
        <div className='drawer-header'>
          <p className='header-title'>Product information</p>

          <button onClick={onClose} className='header-btn-close'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>
        </div>

        <div className='drawer-body'>
          <p className='drawer-title'>{product.fields.title}</p>

          <ul className='drawer-list'>
            {learnTitles.map((title, index) => (
              <li
                key={index}
                className={`drawer-list-text ${selectedIndex === index ? 'selected' : ''}`}
                onClick={(e) => onSelectTitle(e, index)}
              >
                {title}
              </li>
            ))}
          </ul>

          <p className='drawer-content'>{documentToReactComponents(productInfo[selectedIndex])}</p>
        </div>
      </Drawer.Body>
    </Drawer>
  );
}
