import { PhoneField } from 'components/core/fields/PhoneField';
import { SelectField } from 'components/core/fields/SelectField';
import { TextField } from 'components/core/fields/TextField';
import { ZipCodeField } from 'components/core/fields/ZipCodeField';

import states from 'data/basics/states.json';
import { Address } from 'pages/checkout-experience/shared/Checkout';

interface Props {
  addressForm: Address;
  includePhone?: boolean;
  onChange: (updated: Address) => void;
  incompleteKeys?: string[];
}

export default function AddressBlock({
  addressForm,
  includePhone = false,
  onChange,
  incompleteKeys = [],
}: Props) {
  return (
    <div className='ce-shipping-address-block'>
      <TextField
        name='firstName'
        label='First name'
        value={addressForm.firstName}
        placeholder='First name'
        onChange={(value) => onChange({ ...addressForm, firstName: value })}
        isError={incompleteKeys.includes('firstName')}
      />

      <TextField
        name='lastName'
        label='Last name'
        value={addressForm.lastName}
        placeholder='Last name'
        onChange={(value) => onChange({ ...addressForm, lastName: value })}
        isError={incompleteKeys.includes('lastName')}
      />

      <TextField
        name='shippingAddressLineOne'
        label='Address Line 1'
        value={addressForm.shippingAddressLineOne}
        placeholder='Street address'
        onChange={(value) => onChange({ ...addressForm, shippingAddressLineOne: value })}
        isError={incompleteKeys.includes('shippingAddressLineOne')}
      />

      <TextField
        name='shippingAddressLineTwo'
        label='Address Line 2 (optional)'
        value={addressForm.shippingAddressLineTwo}
        placeholder='Apt #, Floor, etc. (Optional)'
        onChange={(value) => onChange({ ...addressForm, shippingAddressLineTwo: value })}
      />

      <TextField
        name='city'
        label='City'
        value={addressForm.city}
        placeholder='City'
        onChange={(value) => onChange({ ...addressForm, city: value })}
        isError={incompleteKeys.includes('city')}
      />

      <div className='form-flex'>
        <SelectField
          name='stateAbbr'
          label='State'
          value={addressForm.stateAbbr?.toUpperCase()}
          placeholder='State'
          data={states.States}
          onChange={(value) => onChange({ ...addressForm, stateAbbr: value?.toUpperCase() ?? '' })}
          isError={incompleteKeys.includes('stateAbbr')}
        />

        <ZipCodeField
          name='zip'
          label='Zip code'
          value={addressForm.zip}
          placeholder='Zip code'
          onChange={(value) => onChange({ ...addressForm, zip: value })}
          isError={incompleteKeys.includes('zip')}
        />
      </div>

      {includePhone && (
        <PhoneField
          name='phoneNumber'
          label='Phone number'
          value={addressForm.phoneNumber}
          placeholder='Phone number'
          onChange={(value) => onChange({ ...addressForm, phoneNumber: value })}
          isError={incompleteKeys.includes('phoneNumber')}
        />
      )}
    </div>
  );
}
