import { Skeleton } from '@mui/material';
import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import { NINETY_DAY_RECURRING_ID } from 'common/dist/products/productRegistry';
import { useState } from 'react';

import EmptyState from 'components/dashboard/EmptyState';
import HeaderProductsCard from '../cards/HeaderProductsCard';
import BundleDrawer from '../drawers/BundleDrawer';

import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';

import { useAppSelector } from 'reducers/alloy_reducer';

export default function SubscriptionsWrapper() {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const [isLoadingUpdates, setIsLoadingUpdates] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [didBundle, setDidBundle] = useState(false);

  const {
    data: subscriptions = [],
    isLoading: isLoadingSubs,
    mutate,
  } = useGetAllSubscriptionsForCustomer();

  const { activeSubs, pausedSubs, cancelledSubs } = getSubscriptionsWithStatus(subscriptions);

  const filteredSubs = activeSubs.filter((sub) =>
    sub.products.some((pfr) => pfr.product.frequencyId === NINETY_DAY_RECURRING_ID)
  );

  // only 90 day subs can be bundled at this moment
  const canBundleSubs =
    process.env.REACT_APP_SHOW_V3_SUBSCRIPTIONS_BUNDLING === 'true' && filteredSubs.length > 1;

  const onBundled = async (bundled: boolean) => {
    setIsLoadingUpdates(true);

    await mutate();

    setDidBundle(bundled);
    setIsLoadingUpdates(false);
  };

  const isLoading = isLoadingUpdates || isLoadingSubs;

  if (isLoading) {
    return (
      <>
        <Skeleton variant='rectangular' height={200} className='mb-3' />
        <Skeleton variant='rectangular' height={200} />
      </>
    );
  }

  if (customer.status === 'NEW') {
    return <EmptyState type='NEW' />;
  } else if (customer.status === 'PENDING') {
    return <EmptyState type='PENDING' />;
  } else if (subscriptions.length === 0) {
    return (
      <EmptyState
        type='CONSULT'
        title="Ready for relief? Let's make it happen."
        desc="Oops! You don't have any upcoming shipments. Once you have active Alloy shipments, they will appear here."
        btnTitle='Start consult'
      />
    );
  }

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          {canBundleSubs && (
            <div className='subscriptions-bundle-container'>
              <div className='bundle-content-wrapper'>
                <p className='content-title'>Get fewer shipments</p>
                <p className='content-text'>
                  Bundle your orders together, and get all of your treatments in the same shipment.
                </p>
              </div>

              <button className='primary-button' onClick={() => setIsDrawerOpen(true)}>
                Bundle your shipments
              </button>
            </div>
          )}

          {didBundle && (
            <div className='subscriptions-bundle-container'>
              <div className='bundle-content-wrapper'>
                <p className='content-title'>Thanks for bundling your orders!</p>
              </div>
            </div>
          )}

          {!!activeSubs.length && (
            <div className='subscriptions-container'>
              {activeSubs.map((subscription, index) => (
                <div className='subscription-wrapper' key={index}>
                  <HeaderProductsCard
                    subscription={subscription}
                    setIsLoadingUpdates={setIsLoadingUpdates}
                  />
                </div>
              ))}
            </div>
          )}

          {(!!pausedSubs.length || !!cancelledSubs.length) && (
            <div className='subscriptions-container'>
              {!!activeSubs.length && <h3 className='container-title'>Inactive</h3>}

              {[...pausedSubs, ...cancelledSubs].map((subscription, index) => (
                <div className='subscription-wrapper' key={index}>
                  <HeaderProductsCard
                    subscription={subscription}
                    setIsLoadingUpdates={setIsLoadingUpdates}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {activeSubs.length > 1 && (
        <BundleDrawer
          open={isDrawerOpen}
          setOpen={setIsDrawerOpen}
          bundableSubscriptions={filteredSubs}
          setDidBundle={onBundled}
        />
      )}
    </>
  );
}
